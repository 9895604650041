import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Colaborador } from '../../../model/colaborador';
import { NgForm } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-detalhes-colaboradores',
  templateUrl: 'colaboradores-detalhes.component.html'
})
export class ColaboradoresDetalhesComponent implements OnInit{

  @ViewChild('modalcolaborador', {static: false}) public modalcolaborador: ModalDirective;

  sub: boolean = false;
  _colaborador: Colaborador = new Colaborador();
  @Output() loadcolaboradores = new EventEmitter();
  titulo: string = "Novo colaborador";
  perfil: any[];
  gestores: any[];
  colaboradores: any[];
  movSub: Subscription;
  movSubC: Subscription;
  movSubEmailSalvar: Subscription;
  movSubEmailAlterar: Subscription;

  constructor(
    private CrudService: CrudService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CrudService) {
  }

  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesColaborador.subscribe(
        (p: any) => {

          if(p.colaborador == null){
            this.titulo = "Novo Colaborador"
            this._colaborador = new Colaborador();
          }else{
            this.titulo = "Editar Colaborador"
            this._colaborador = p.colaborador;
          }

          this.setGestores(p.colaboradores);
          this.limpaModal();

          this.modalcolaborador.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

    this.getperfil();
    this.getcolaboradores();
  }

  setGestores(colaboradores: any[]){

    let _gestores: any[] = [];

    for(var i = 0; i < colaboradores.length; i++){

      if(colaboradores[i].gestor || colaboradores[i].mediador){
        _gestores.push(colaboradores[i]);
      }
    }

    this.gestores = _gestores;
  }

  async getperfil(){

    this.perfil = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.movSub = this.cadastroService.listar("perfil", null, null).subscribe(
        (result: any) =>{

          this.perfil =  result;
          console.log(result);

       },error=>{
            console.log(error);
       });

    }

  }

  async getcolaboradores(){
    this.colaboradores = [];
    if (this.movSubC && this.movSubC.closed == false){
      this.movSubC.unsubscribe();
    }
    if (!this.movSubC || this.movSubC.closed){
      this.movSubC = this.CrudService.listar("colaborador", null, null).subscribe(
        (result: any) =>{
          this.colaboradores =  result;
          console.log(result);
          this.setGestores(this.colaboradores);
       },error=>{
            console.log(error);
       });
    }
  }

  hideModal(){
    this.modalcolaborador.hide();
    this.loadcolaboradores.emit();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this._colaborador.id){
      this.atualizar();
    }else{
      this.salvar();
    }

    form.reset();
  }

  salvar(){

    let objColaborador = JSON.parse(JSON.stringify(this._colaborador));

    if (this.movSubEmailSalvar && this.movSubEmailSalvar.closed == false){
      this.movSubEmailSalvar.unsubscribe();
    }

    if (!this.movSubEmailSalvar || this.movSubEmailSalvar.closed){

      this.movSubEmailSalvar = this.cadastroService.findByGeneric("colaborador", "email", this._colaborador.email).subscribe(
        (result: any) =>{

          if(result.length > 0){
            this.toastr.error('Este email já esta sendo utilizado por outro colaborador!', 'Atenção!');
            this.hideModal();
          }else{
            objColaborador.cor = this.geraCor();
            this.cadastroService.salvar("colaborador", objColaborador);

            this.toastr.success('Colaborador cadastrado com sucesso!', 'Sucesso!');

            this.hideModal();

            this.movSubEmailSalvar.unsubscribe();
          }

       },error=>{
            console.log(error);
            this.hideModal();
       });

    }

  }

  atualizar(){

    let objColaborador = JSON.parse(JSON.stringify(this._colaborador));

    if (this.movSubEmailAlterar && this.movSubEmailAlterar.closed == false){
      this.movSubEmailAlterar.unsubscribe();
    }

    if (!this.movSubEmailAlterar || this.movSubEmailAlterar.closed){

      this.movSubEmailAlterar = this.cadastroService.findByGeneric("colaborador", "email", this._colaborador.email).subscribe(
        (result: any) =>{

          if(result.length > 0){
            if(result[0].email == objColaborador.email){
              this.cadastroService.atualizar("colaborador", objColaborador);

              this.toastr.success('Colaborador atualizado com sucesso!', 'Sucesso!');

              this.hideModal();

              this.movSubEmailAlterar.unsubscribe();
            }
          }

       },error=>{
            console.log(error);
            this.hideModal();
       });

    }

  }

  geraCor(){
    var hexadecimais = '0123456789ABCDEF';
    var cor = '#';

    // Pega um número aleatório no array acima
    for (var i = 0; i < 6; i++ ) {
    //E concatena à variável cor
        cor += hexadecimais[Math.floor(Math.random() * 16)];
    }
    return cor;
  }

  changeGestorMediador(op: string){

    if(op == "gestor"){
      this._colaborador.gestor = true;

      if(this._colaborador.mediador){
        this._colaborador.mediador = false;
      }
    }

    if(op == "mediador"){
      this._colaborador.mediador = true;

      if(this._colaborador.gestor){
        this._colaborador.gestor = false;
      }
    }

  }

  validaSigla(){
    if( this._colaborador.sigla.length == 2){
      this._colaborador.sigla = this._colaborador.sigla.toUpperCase()
      for(var c=0; c<this.colaboradores.length; c++){
        if( this._colaborador.sigla == this.colaboradores[c].sigla){
          this.toastr.error("A sigla " +  this._colaborador.sigla +" já existe. Defina outra!");
          this._colaborador.sigla = '';
        }
      }
    }
  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (this.movSubEmailAlterar && this.movSubEmailAlterar.closed == false){
      this.movSubEmailAlterar.unsubscribe();
    }

    if (this.movSubEmailSalvar && this.movSubEmailSalvar.closed == false){
      this.movSubEmailSalvar.unsubscribe();
    }
  }
}
