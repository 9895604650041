import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CrudService } from "../../services/crudService";
import { ModalService } from '../../services/modal.service';
import { Cliente } from '../../model/cliente';
import { ConfirmationDialogService } from '../../components/confirmation-dialog/confirmation-dialog.service';
import { CsvDataService } from '../../services/csvdata.service';
import { ClienteCSV } from '../../model/clienteCSV';
 
@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})  
export class ClientesComponent implements OnInit {
    
  movSub: Subscription;
  clientes: any[];
  fileReader: FileReader;
  fileToRead: File;
  
  constructor(
      private spinner: NgxSpinnerService,
      private CrudService: CrudService,
      private modalService: ModalService,
      private cadastroService: CrudService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService,
      private csvDataService: CsvDataService
  ){
    
  }

  ngOnInit() {
    this.getclientes();
  }

  downloadTemplate(){

    let clienteCsv: any = [];

    if(this.clientes.length > 0){

      for(var i= 0; i < this.clientes.length; i++){

        let clienteCSV = new ClienteCSV();
  
        clienteCSV._1_codigo = this.clientes[i].codigo;
        clienteCSV._2_nome = this.clientes[i].nome;
  
        if(this.clientes[i].ativo){
          clienteCSV._3_ativo = "S";
        }else{
          clienteCSV._3_ativo = "N";
        }
       
        clienteCsv.push(clienteCSV);
      }

    }else{

      let clienteCSV = new ClienteCSV();
  
      clienteCSV._1_codigo = "";
      clienteCSV._2_nome = "";
      clienteCSV._3_ativo = "";
               
      clienteCsv.push(clienteCSV);

    }
    
    this.csvDataService.exportToCsv("cliente_capacity.csv", clienteCsv);
  }

  async getclientes(){
 
    this.clientes = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();
     
      this.movSub = this.CrudService.listar("cliente", null, null).subscribe(
        (result: any) =>{

          this.clientes =  result;
          console.log(result);

          this.spinner.hide();
  
       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }
    

  }


  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

  novocliente(){
    this.modalService.modalDetalhesCliente.emit(null);
  }

  editar(clientes: Cliente){
    this.modalService.modalDetalhesCliente.emit(clientes);
  }
 
  excluir(clientes: Cliente){
    
    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          this.cadastroService.excluir("cliente", clientes.id);
            
          this.toastr.success('Cliente excluído com sucesso!', 'Sucesso!');
          this.getclientes();               
           
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    
  }

  importar(){
    
    if(this.fileToRead){
      this.fileReader.readAsText(this.fileToRead, "UTF-8");
    }else{
      this.toastr.warning('Nenhum arquivo selecionado!', 'Atenção!');
    }
  
  }

  onFileSelect(input: HTMLInputElement) {

    const files = input.files;

    this.fileToRead = null;

    if (files && files.length) {
       
          if(files[0].name.indexOf("cliente") == -1 ||
              files[0].name.indexOf("capacity") == -1 ||
                files[0].name.indexOf("csv") == -1){
           
            this.toastr.warning('Arquivo incorreto!', 'Atenção!');

            input.value = "";

          }else{
            this.fileToRead = files[0];

            this.fileReader = new FileReader();

             this.fileReader.onload = (function(crudService, toastr, clientes){
              
              return function(e){
                 

                  const textFromFileLoaded = e.target.result;              
                  let csvContent = textFromFileLoaded.split('\n');  
                   

                  var arquivoInvalido = false;
                  let lista : Cliente[] = [];

                  for(var i= 1; i < csvContent.length; i++){
              
                    var linha = csvContent[i].split(';');

                    if(linha.length != 3){
                      arquivoInvalido = true;
                    }else{
                      let obj : Cliente = new Cliente();
                      obj.codigo = linha[0];
                      obj.nome = linha[1];
                      
                      let ativo: string = linha[2];
                      if(ativo.toUpperCase().trim() == "S"){
                        obj.ativo = true;
                      }else{
                        obj.ativo = false;
                      }

                      lista.push(obj);
                    }

                  }

                  if(arquivoInvalido){
                    toastr.warning('Arquivo invalido! Verifique a quantidades de colunas.', 'Atenção!');
                    input.value = "";
                  }else{
                    if(lista.length == 0){
                      toastr.warning('Arquivo vazio!', 'Atenção!');
                      input.value = "";
                    }else{
                      
                      for(var l = 0; l < lista.length; l++){
                        
                        var existe = false;
                        for(var c = 0; c < clientes.length; c++){
                          if(clientes[c].codigo == lista[l].codigo){
                            existe = true;
                          }
                        }

                        if(existe == false){
                          crudService.salvar("cliente", lista[l]);
                        }

                      }

                      input.value = "";
                      toastr.success('Importação executada!', 'Sucesso!');

                    }
                  }

              };
              
            })(this.CrudService, this.toastr, this.clientes);  

          }

         

    }

  }

}
