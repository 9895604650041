
export class Colaborador {

    public id: string;
    public sigla: string;
    public perfil: string;
    public nome: string;
    public email: string;
    public valorHora: Number;
    public gestor: boolean;
    public mediador: boolean;
    public rh: boolean;
    public tecnico: boolean;
    public comercial: boolean;
    public financeiro: boolean;
    public saldoHora: Number;
    public gestorDireto: string;
    public cadastrador: boolean;
    public ativo: boolean;
    public cor: string;

    constructor(){
        
    }
}
