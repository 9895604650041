import { EventEmitter, Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modalDetalhesCst = new EventEmitter<any>();
  modalDetalhesFeriado = new EventEmitter<any>();
  modalDetalhesColaborador = new EventEmitter<any>();
  modalDetalhesCliente = new EventEmitter<any>();
  modalDetalhesVertical = new EventEmitter<any>();
  modalDetalhesPerfil = new EventEmitter<any>();
  modalDetalhesCentroDeCusto = new EventEmitter<any>();
  modalPerfilCcusto = new EventEmitter<any>();
  modalAlterarSenha = new EventEmitter<any>();
  modalSolicitarAusencia = new EventEmitter<any>();
  modalAusencia = new EventEmitter<any>();
  modalAprovarAusencia = new EventEmitter<any>();
  modalFiltroColaborador = new EventEmitter<any>();
  modalDetalhesEmpresas = new EventEmitter<any>();
  modalDetalhesRiscos = new EventEmitter<any>();
  modalDetalhesMCDigital = new EventEmitter<any>();
  modalAnexosMCDigital = new EventEmitter<any>();
  modalDesmembramento = new EventEmitter<any>();

  constructor() {}


}
