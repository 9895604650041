import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Feriado } from '../../../model/feriado';
import { NgForm } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-detalhes-feriados',
  templateUrl: 'feriados-detalhes.component.html'
})  
export class FeriadosDetalhesComponent implements OnInit{
     
  @ViewChild('modalferiado', {static: false}) public modalferiado: ModalDirective;

  sub: boolean = false;
  _feriado: Feriado = new Feriado();
  @Output() loadferiados = new EventEmitter();
  titulo: string = "Novo Feriado";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CrudService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesFeriado.subscribe(
        (p: Feriado) => {
         
          if(p == null){
            this.titulo = "Novo Feriado"
            this._feriado = new Feriado();
          }else{
            this.titulo = "Editar Feriado"
            this._feriado = p;
          }

          this.limpaModal();

          this.modalferiado.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  hideModal(){
    this.modalferiado.hide();
    this.loadferiados.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this._feriado.id){
      this.atualizar();
    }else{
      this.salvar();
    }
      
    form.reset();
  }

  salvar(){

    let objFeriado = JSON.parse(JSON.stringify(this._feriado));

    if (this.movSubSalvar && this.movSubSalvar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed){
     
      this.movSubSalvar = this.cadastroService.findByGeneric("feriado", "dataFeriado", this._feriado.dataFeriado).subscribe(
        (result: any) =>{

          if(result.length > 0){
            this.toastr.error('Esta data já esta sendo utilizada por outro feriado!', 'Atenção!');
            this.hideModal();
          }else{

            let splitDate = objFeriado.dataFeriado.split("-");
            let dateFeriado : Date = new Date(splitDate[0], splitDate[1]-1, splitDate[2], 0, 0, 0);
            objFeriado.dataFeriado = dateFeriado;
            this.cadastroService.salvar("feriado", objFeriado);

            this.toastr.success('Feriado cadastrado com sucesso!', 'Sucesso!');
        
            this.hideModal();

            this.movSubSalvar.unsubscribe();
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }
   
  } 

  atualizar(){

    let objFeriado = JSON.parse(JSON.stringify(this._feriado));

    if (this.movSubAlterar && this.movSubAlterar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubAlterar || this.movSubAlterar.closed){
     
      this.movSubAlterar = this.cadastroService.findByGeneric("feriado", "dataFeriado", this._feriado.dataFeriado).subscribe(
        (result: any) =>{

          if(result.length > 0){
            if(result[0].dataFeriado.seconds*1000 == objFeriado.dataFeriado.seconds*1000){
              this.cadastroService.atualizar("feriado", objFeriado);

              this.toastr.success('Feriado atualizado com sucesso!', 'Sucesso!');
           
              this.hideModal();
            
              this.movSubAlterar.unsubscribe();
            }
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }


    
  }
}
