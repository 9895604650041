import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { PerfilCCusto } from '../../../model/PerfilCCusto';
import { NgForm } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../../../components/confirmation-dialog/confirmation-dialog.service';
import { CentroDeCusto } from '../../../model/centrodecusto';
import { Perfil } from '../../../model/perfil';
import { Colaborador } from '../../../model/colaborador';

@Component({
  selector: 'app-perfilccusto',
  templateUrl: 'perfil-ccusto.component.html'
})
export class PerfilCCustoComponent implements OnInit{

  @ViewChild('modalperfilccusto', {static: false}) public modalperfilccusto: ModalDirective;

  sub: boolean = false;
  _perfilccusto: PerfilCCusto = new PerfilCCusto();
  _centrodecusto: CentroDeCusto;
  perfilsccusto: any = [];
  @Output() loadperfilccustos = new EventEmitter();
  titulo: string = "Perfil - Centro de Custo";

  movSub: Subscription;
  movSubCol: Subscription;
  movSubPerfil: Subscription;
  perfils: Perfil[];
  colaboradores: Colaborador[];

  totalHoras: Number;
  totalCusto: Number;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private crudService: CrudService,
    private confirmationDialogService: ConfirmationDialogService) {
  }

  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalPerfilCcusto.subscribe(
        (p: any) => {

          this._centrodecusto = p;

          this.getperfil();
          this.getColaboradores();
          this.getPerfilCCusto();

          this.limpaModal();

          this.modalperfilccusto.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  async getColaboradores(){

    this.colaboradores = [];

    if (this.movSubCol && this.movSubCol.closed == false){
      this.movSubCol.unsubscribe();
    }

    if (!this.movSubCol || this.movSubCol.closed){

      this.movSubCol = this.crudService.listar("colaborador", null, null).subscribe(
        (result: any) =>{

          this.colaboradores =  result;
       },error=>{
            console.log(error);
       });

    }


  }

  async getPerfilCCusto(){

    this.perfilsccusto = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.movSub = this.crudService.findByGeneric("perfilccusto","centrodecusto",this._centrodecusto.id).subscribe(
        (result: any) =>{

          this.perfilsccusto =  result;
          this.calculaTotais();

       },error=>{
            console.log(error);
       });

    }


  }

  async getperfil(){

    this.perfils = [];

    if (this.movSubPerfil && this.movSubPerfil.closed == false){
      this.movSubPerfil.unsubscribe();
    }

    if (!this.movSubPerfil || this.movSubPerfil.closed){

      this.movSubPerfil = this.crudService.listar("perfil", null, null).subscribe(
        (result: any) =>{

          this.perfils =  result;

       },error=>{
            console.log(error);
       });

    }


  }

  calculaTotais(){

    this.totalCusto = 0;
    this.totalHoras = 0;

    for(var i=0; i< this.perfilsccusto.length; i++){
      this.totalHoras = this.perfilsccusto[i].horas + this.totalHoras;
      this.totalCusto = this.perfilsccusto[i].custo + this.totalCusto;
    }

  }

  changePerfil(){
    this._perfilccusto.horas = 0;
    this.calculaCusto();
  }

  calculaCusto(){

    for(var i=0; i< this.perfils.length; i++){
        if(this.perfils[i].id == this._perfilccusto.perfil){
          this._perfilccusto.custo = Number(this._perfilccusto.horas) * Number(this.perfils[i].custoHora);
        }
    }

  }

  hideModal(){
    this.modalperfilccusto.hide();
    this.loadperfilccustos.emit();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    this.salvar();
    form.reset();
  }

  salvar(){

    this._perfilccusto.centrodecusto = this._centrodecusto.id;
    this.crudService.salvar("perfilccusto", this._perfilccusto);

    this.getPerfilCCusto();
    this.toastr.success('Perfil incluído com sucesso!', 'Sucesso!');

  }

  excluir(perfil: PerfilCCusto){

    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{

        if(confirmed){
          this.crudService.excluir("perfilccusto", perfil.id);
          this.getPerfilCCusto();
          this.toastr.success('Perfil excluído com sucesso!', 'Sucesso!');

        }

      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });

  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (this.movSubPerfil && this.movSubPerfil.closed == false){
      this.movSubPerfil.unsubscribe();
    }

  }

}
