
export class Ausencia {

    public id: string;
    public motivo: string;
    public colaborador: string;
    public periodoDe: Date;
    public periodoAte: Date;
    public status: Number;
    public gestorDireto: string;
    public obs: string;
    public horas: number;
    public subaprovacoes: any[];

    constructor(){
        
    }
}
