export class EventoCapacity {

    public id: string;
    public cliente: string;
    public centrodecusto: string;
    public comercial: string;
    public area: string;
    public comissao: string;
    public detalhes: any[];
    public data: Date;

    constructor(){ 
        
    }
}
