import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Empresa } from '../../../model/empresa';
import { NgForm } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';
 
@Component({
  selector: 'app-detalhes-empresas',
  templateUrl: 'empresas-detalhes.component.html'
})   
export class EmpresasDetalhesComponent implements OnInit{
      
  @ViewChild('modalempresas', {static: false}) public modalempresas: ModalDirective;
  
  sub: boolean = false;
  _empresa: Empresa = new Empresa();
  @Output() loadempresas = new EventEmitter();
  titulo: string = "Nova Empresa";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
 
  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CrudService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesEmpresas.subscribe(
        (p: Empresa) => {
         
          if(p == null){
            this.titulo = "Nova Empresa"
            this._empresa = new Empresa();
          }else{
            this.titulo = "Editar Empresa"
            this._empresa = p;
          }

          this.limpaModal();

          this.modalempresas.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  hideModal(){
    this.modalempresas.hide();
    this.loadempresas.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this._empresa.id){
      this.atualizar();
    }else{
      this.salvar();
    }
      
    form.reset();
  }

  salvar(){

    let objempresas = JSON.parse(JSON.stringify(this._empresa));

    if (this.movSubSalvar && this.movSubSalvar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed){
     
      this.movSubSalvar = this.cadastroService.findByGeneric("empresas", "cnpj", this._empresa.cnpj).subscribe(
        (result: any) =>{

          if(result.length > 0){
            this.toastr.error('Este CNPJ já esta sendo utilizado por outra empresa!', 'Atenção!');
            this.hideModal();
          }else{
            this.cadastroService.salvar("empresas", objempresas);

            this.toastr.success('Empresa cadastrada com sucesso!', 'Sucesso!');
         
            this.hideModal();

            this.movSubSalvar.unsubscribe();
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }
 
 
   
  } 

  atualizar(){

    let objempresas = JSON.parse(JSON.stringify(this._empresa));
    
    if (this.movSubAlterar && this.movSubAlterar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubAlterar || this.movSubAlterar.closed){
     
      this.movSubAlterar = this.cadastroService.findByGeneric("empresas", "cnpj", this._empresa.cnpj).subscribe(
        (result: any) =>{

          if(result.length > 0){
            if(result[0].cnpj == objempresas.cnpj){
              this.cadastroService.atualizar("empresas", objempresas);

              this.toastr.success('Empresa atualizada com sucesso!', 'Sucesso!');
           
              this.hideModal();
            
              this.movSubAlterar.unsubscribe();
            }
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }
    
  }
}
