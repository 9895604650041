
export class CentroDeCustoCSV {

    public _1_siglaCCusto: string;
    public _2_descricao: string;
    public _3_cliente: string;
    public _4_responsavel: string;
    public _5_vertical: string;
    public _6_horasVendidas: Number;
    public _7_horasConsumidas: Number;
    public _8_valorVenda: Number;
    public _9_valorCustoPrevisto: Number;
    public _10_valorCustoReal: Number;
    public _11_ativo: string;

    constructor(){
        
    }
}
