
export class VerticalCSV {

    public _1_codigo: string;
    public _2_descricao: string;
 
    constructor(){
        
    }
}
