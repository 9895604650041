import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CrudService } from "../../services/crudService";
import { ModalService } from '../../services/modal.service';
import { Feriado } from '../../model/feriado';
import { ConfirmationDialogService } from '../../components/confirmation-dialog/confirmation-dialog.service';
import { CsvDataService } from '../../services/csvdata.service';
import { FeriadoCSV } from '../../model/feriadoCSV';

@Component({
  selector: 'app-feriados',
  templateUrl: './feriados.component.html',
  styleUrls: ['./feriados.component.scss']
})  
export class FeriadosComponent implements OnInit {
   
  movSub: Subscription;
  feriados: any[];
  fileReader: FileReader;
  fileToRead: File;

  constructor(
      private spinner: NgxSpinnerService,
      private CrudService: CrudService,
      private modalService: ModalService,
      private cadastroService: CrudService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService,
      private csvDataService: CsvDataService
  ){
    
  }

  ngOnInit() {
    this.getferiados();
  }

  
  downloadTemplate(){

    let _feriadoCSV: any = [];

    if(this.feriados.length > 0){
      for(var i= 0; i < this.feriados.length; i++){

        let feriadoCSV = new FeriadoCSV();
  
        let dataFeriado = new Date( this.feriados[i].dataFeriado.seconds*1000);
        let formatted_date = dataFeriado.getDate() + "/" + (dataFeriado.getMonth() + 1) + "/" + dataFeriado.getFullYear();

        feriadoCSV._1_dataFeriado = formatted_date;
        feriadoCSV._2_descricao = this.feriados[i].descricao;
  
        _feriadoCSV.push(feriadoCSV);
      }
    }else{
      let feriadoCSV = new FeriadoCSV();
  
      feriadoCSV._1_dataFeriado = "00/00/0000";
      feriadoCSV._2_descricao = "";

      _feriadoCSV.push(feriadoCSV);
    }    
    
    this.csvDataService.exportToCsv("feriado_capacity.csv", _feriadoCSV);
  }

  async getferiados(){
 
    this.feriados = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();
     
      this.movSub = this.CrudService.listar("feriado", null, null).subscribe(
        (result: any) =>{

          this.feriados =  result;
          console.log(result);

          this.spinner.hide();
  
       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }
    

  }


  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

  novoferiado(){
    this.modalService.modalDetalhesFeriado.emit(null);
  }

  editar(feriados: Feriado){
    this.modalService.modalDetalhesFeriado.emit(feriados);
  }

  excluir(feriados: Feriado){
    
    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          this.cadastroService.excluir("feriado", feriados.id);
            
          this.toastr.success('Feriado excluído com sucesso!', 'Sucesso!');
          this.getferiados();               
           
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    
  }

  importar(){
    
    if(this.fileToRead){
      this.fileReader.readAsText(this.fileToRead, "UTF-8");
    }else{
      this.toastr.warning('Nenhum arquivo selecionado!', 'Atenção!');
    }
  
  }

  onFileSelect(input: HTMLInputElement) {

    const files = input.files;

    this.fileToRead = null;

    if (files && files.length) {
       
          if(files[0].name.indexOf("feriado") == -1 ||
              files[0].name.indexOf("capacity") == -1 ||
                files[0].name.indexOf("csv") == -1){
           
            this.toastr.warning('Arquivo incorreto!', 'Atenção!');

            input.value = "";

          }else{
            this.fileToRead = files[0];

            this.fileReader = new FileReader();

             this.fileReader.onload = (function(crudService, toastr, feriados){
              
              return function(e){
                 
                  const textFromFileLoaded = e.target.result;              
                  let csvContent = textFromFileLoaded.split('\n');  
                   
                  var arquivoInvalido = false;

                  let lista : Feriado[] = [];

                  for(var i= 1; i < csvContent.length; i++){
              
                    var linha = csvContent[i].split(';');

                    if(linha.length != 2){
                      arquivoInvalido = true;
                    }else{
                      let obj : Feriado = new Feriado();

                      var parts = linha[0].split('/');

                      obj.dataFeriado = new Date(Number(parts[2]), Number(parts[1])-1, Number(parts[0]),0,0,0);
                      obj.descricao = linha[1];
                      
                      lista.push(obj);
                    }

                  }

                  if(arquivoInvalido){
                    toastr.warning('Arquivo invalido! Verifique a quantidades de colunas.', 'Atenção!');
                    input.value = "";
                  }else{
                    if(lista.length == 0){
                      toastr.warning('Arquivo vazio!', 'Atenção!');
                      input.value = "";
                    }else{
                      
                      for(var l = 0; l < lista.length; l++){
                        
                        var existe = false;
                        for(var c = 0; c < feriados.length; c++){
                          if(feriados[c].dataFeriado == lista[l].dataFeriado){
                            existe = true;
                          }
                        }

                        if(existe == false){
                          crudService.salvar("feriado", lista[l]);
                        }

                      }

                      input.value = "";
                      toastr.success('Importação executada!', 'Sucesso!');

                    }
                  }
                  
              };
              
            })(this.CrudService, this.toastr, this.feriados);  

          }

         

    }

  }

}
