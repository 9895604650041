import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[appMcDigital]'
})

export class McDigitalDirective implements OnInit{
  @Input() siglamc: string;
  @Input() mcDigital: any[];
  @Input() retorno: string;



  constructor(private elRef: ElementRef, private render: Renderer2){
  }
  ngOnInit(){

    let achouMcDigital = false;

    var versao = 0;
    for(var i = 0; i < this.mcDigital.length; i++){
      if(this.siglamc && this.mcDigital[i].siglamc.trim() == this.siglamc.trim()){
        if(this.mcDigital[i].versao > versao){
          versao = this.mcDigital[i].versao;
        }
      }
    }

    for(var i = 0; i < this.mcDigital.length; i++){
      if(this.siglamc && this.mcDigital[i].siglamc.trim() == this.siglamc.trim() && this.mcDigital[i].versao == versao){

        if(this.retorno == "periodoDe"){
          var text = this.render.createText(this.mcDigital[i].periodoDe);
        }

        if(this.retorno == "periodoAte"){
          var text = this.render.createText(this.mcDigital[i].periodoAte);
        }

        if(this.retorno == "valorvenda"){
          var text = this.render.createText(this.mcDigital[i].valorvenda);
        }

        if(this.retorno == "custoprojeto"){
          var text = this.render.createText(this.mcDigital[i].custoprojeto);
        }

        if(this.retorno == "despesa"){
          var text = this.render.createText(this.mcDigital[i].planejadas.toString());
        }

        if(this.retorno == "custo"){
          var text = this.render.createText(this.mcDigital[i].custoprojeto.toString());
        }

        if(this.retorno == "margem"){
          var text = this.render.createText(this.mcDigital[i].margem.toString());
        }

        if(this.retorno == "valorVenda"){
          var text = this.render.createText(this.mcDigital[i].valorvenda.toString());
        }
        if(this.retorno == "saldoprojeto"){
          var saldo = this.mcDigital[i].valorvenda - this.mcDigital[i].custoprojeto
          var text = this.render.createText(saldo .toFixed(2).toString());

        }

        if(this.retorno == "comissao"){
          var comissao = this.mcDigital[i].valorvenda * (1 - this.mcDigital[i].impostoEmpresa/100 ) * this.mcDigital[i].comissao /100;
          var text = this.render.createText(comissao .toFixed(2).toString());
        }


        this.render.appendChild(this.elRef.nativeElement, text);
        achouMcDigital  =true;
      }
    }
  }
}

