import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CrudService } from "../../services/crudService";
import { ModalService } from '../../services/modal.service';
import { CentroDeCusto } from '../../model/centrodecusto';
import { ConfirmationDialogService } from '../../components/confirmation-dialog/confirmation-dialog.service';
import { CsvDataService } from '../../services/csvdata.service';
import { CentroDeCustoCSV } from '../../model/centrodecustoCSV';
 
@Component({
  selector: 'app-centrodecusto',
  templateUrl: './centrodecusto.component.html',
  styleUrls: ['./centrodecusto.component.scss']
})   
export class CentroDeCustoComponent implements OnInit {
    
  movSub: Subscription;
  centrodecustos: any[];
  clientes: any[];
  vertical: any[];
  movSubCli:  Subscription;
  movSubVertical: Subscription;
  fileReader: FileReader;
  fileToRead: File;
  colaboradores: any[];
  gestores: any[];

  constructor(
      private spinner: NgxSpinnerService,
      private CrudService: CrudService,
      private modalService: ModalService,
      private cadastroService: CrudService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService,
      private csvDataService: CsvDataService
  ){
    
  }

  ngOnInit() {
    this.getclientes();
    this.getVertical();
  }

  downloadTemplate(){

    let centrosdecustoCsv: any = [];

    if(this.centrodecustos.length > 0){

      for(var i= 0; i < this.centrodecustos.length; i++){

        let centroCSV = new CentroDeCustoCSV();
  
        centroCSV._1_siglaCCusto = this.centrodecustos[i].descricaoMin;
        centroCSV._2_descricao = this.centrodecustos[i].descricao;
  
        for(var c = 0; c < this.clientes.length; c++){
          if(this.centrodecustos[i].cliente === this.clientes[c].id){
            centroCSV._3_cliente = this.clientes[c].codigo;
          }
        }

        for(var c = 0; c < this.gestores.length; c++){
          if(this.centrodecustos[i].responsavel === this.gestores[c].id){
            centroCSV._4_responsavel = this.gestores[c].email;
          }
        }

        if(!centroCSV._4_responsavel) centroCSV._4_responsavel = "";
  
        for(var v = 0; v < this.vertical.length; v++){
          if(this.centrodecustos[i].vertical === this.vertical[v].id){
            centroCSV._5_vertical = this.vertical[v].codigo;
          }
        }
  
        centroCSV._6_horasVendidas = this.centrodecustos[i].horasVendidas;
        centroCSV._7_horasConsumidas =  this.centrodecustos[i].horasConsumidas;
        centroCSV._8_valorVenda = this.centrodecustos[i].valorVenda;
        centroCSV._9_valorCustoPrevisto =  this.centrodecustos[i].valorCustoPrevisto;
        centroCSV._10_valorCustoReal = this.centrodecustos[i].valorCustoReal;
       
        if(this.centrodecustos[i].ativo){
          centroCSV._11_ativo = "S";
        }else{
          centroCSV._11_ativo = "N";
        }
       
        centrosdecustoCsv.push(centroCSV);
      }

    }else{

      let centroCSV = new CentroDeCustoCSV();
  
        centroCSV._1_siglaCCusto = "";
        centroCSV._2_descricao = "";
        centroCSV._3_cliente = "";
        centroCSV._4_responsavel = "";
        centroCSV._5_vertical = "";
        centroCSV._6_horasVendidas = 0;
        centroCSV._7_horasConsumidas = 0;
        centroCSV._8_valorVenda = 0;
        centroCSV._9_valorCustoPrevisto =  0;
        centroCSV._10_valorCustoReal = 0;
        centroCSV._11_ativo = "";
               
        centrosdecustoCsv.push(centroCSV);

    }
    
    this.csvDataService.exportToCsv("centrodecusto_capacity.csv", centrosdecustoCsv);
  }

  async getColaboradores(){
 
    this.colaboradores = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    } 

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();
     
      this.movSub = this.CrudService.listar("colaborador", null, null).subscribe(
        (result: any) =>{

          this.colaboradores =  result;
          console.log(result);
          this.setGestores(this.colaboradores);
          this.getcentrodecustos();
          this.spinner.hide();
  
       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }
    

  }

  setGestores(colaboradores: any[]){

    let _gestores: any[] = [];
    
    for(var i = 0; i < colaboradores.length; i++){

      if(colaboradores[i].gestor || colaboradores[i].mediador){
        _gestores.push(colaboradores[i]);
      }
    }

    this.gestores = _gestores;
  }
  
  async getcentrodecustos(){
 
    this.centrodecustos = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();
     
      this.movSub = this.CrudService.listar("centrodecusto", null, null).subscribe(
        (result: any) =>{

          this.centrodecustos =  result;
          console.log(result);

          this.spinner.hide();
  
       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }
    

  }

  async getclientes(){
 
    this.clientes = [];

    if (this.movSubCli && this.movSubCli.closed == false){
      this.movSubCli.unsubscribe();
    }

    if (!this.movSubCli || this.movSubCli.closed){
     
      this.movSubCli = this.cadastroService.listar("cliente", "ativo", true).subscribe(
        (result: any) =>{

          this.clientes =  result;
          this.getColaboradores();
          
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
  }

  async getVertical(){
 
    this.vertical = [];

    if (this.movSubVertical && this.movSubVertical.closed == false){
      this.movSubVertical.unsubscribe();
    }

    if (!this.movSubVertical || this.movSubVertical.closed){
     
      this.movSubVertical = this.cadastroService.listar("vertical", null, null).subscribe(
        (result: any) =>{

          this.vertical =  result;
  
       },error=>{
            console.log(error);
       });

    }
  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (this.movSubCli && this.movSubCli.closed == false){
      this.movSubCli.unsubscribe();
    }

    if (this.movSubVertical && this.movSubVertical.closed == false){
      this.movSubVertical.unsubscribe();
    }
  }

  perfilccusto(centrodecusto: CentroDeCusto){
    this.modalService.modalPerfilCcusto.emit(centrodecusto);
  }

  novocentrodecusto(){

    var _obj = {
      centrodecusto: null,
      clientes: this.clientes,
      vertical: this.vertical,
      gestores: this.gestores
    }

    this.modalService.modalDetalhesCentroDeCusto.emit(_obj);
  }

  editar(centrodecusto: CentroDeCusto){

    var _obj = {
      centrodecusto: centrodecusto,
      clientes: this.clientes,
      vertical: this.vertical,
      gestores: this.gestores
    }

    this.modalService.modalDetalhesCentroDeCusto.emit(_obj);
  }

  excluir(centrodecustos: CentroDeCusto){
    
    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          this.cadastroService.excluir("centrodecusto", centrodecustos.id);
            
          this.toastr.success('Centro de custo excluído com sucesso!', 'Sucesso!');
          this.getcentrodecustos();               
           
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    
  }

  importar(){
    
    if(this.fileToRead){
      this.fileReader.readAsText(this.fileToRead, "UTF-8");
    }else{
      this.toastr.warning('Nenhum arquivo selecionado!', 'Atenção!');
    }
  
  }

  onFileSelect(input: HTMLInputElement) {

    const files = input.files;

    this.fileToRead = null;

    if (files && files.length) {
       
          if(files[0].name.indexOf("centrodecusto") == -1 ||
              files[0].name.indexOf("capacity") == -1 ||
                files[0].name.indexOf("csv") == -1){
           
            this.toastr.warning('Arquivo incorreto!', 'Atenção!');

            input.value = "";

          }else{
            this.fileToRead = files[0];

            this.fileReader = new FileReader();

             this.fileReader.onload = (function(crudService, toastr, centrodecusto, clientes, vertical, gestores){
              
              return function(e){
                 

                  const textFromFileLoaded = e.target.result;              
                  let csvContent = textFromFileLoaded.split('\n');  
                   
                  var arquivoInvalido = false;
                  var clienteInvalida = false;
                  var verticalInvalido = false;
                  var responsavelInvalido = false;

                  let lista : CentroDeCusto[] = [];

                  for(var i= 1; i < csvContent.length; i++){
              
                    var linha = csvContent[i].split(';');

                    if(linha.length != 11){
                      arquivoInvalido = true;
                    }else{
                      let obj : CentroDeCusto = new CentroDeCusto();
                      obj.descricaoMin = linha[0];
                      obj.descricao = linha[1];

                      let achouCliente: any = null;;
                      for (var t = 0; t < clientes.length; t++) {
                          if (clientes[t].codigo === linha[2]) {
                            achouCliente = clientes[t];
                          }
                      }
                    
                      if(achouCliente){
                        obj.cliente = achouCliente.id;
                      }else{
                        clienteInvalida = true;
                        i = csvContent.length;
                      }

                      let achouResponsavel: any = null;;
                      for (var t = 0; t < gestores.length; t++) {
                          if (gestores[t].email === linha[3]) {
                            achouResponsavel = gestores[t];
                          }
                      }
                    
                      if(achouResponsavel){
                        obj.responsavel = achouResponsavel.id;
                      }else{
                        responsavelInvalido = true;
                        i = csvContent.length;
                      }

                      var achouVertical: any = null;
                      for (var n = 0; n< vertical.length; n++) {
                          if (vertical[n].codigo ===  linha[4]) {
                            achouVertical = vertical[n];
                          }
                      }
                      
                      if(achouVertical){
                        obj.vertical = achouVertical.id
                      }else{
                        verticalInvalido = true;
                        i = csvContent.length;
                      }
                      
                      obj.horasVendidas = Number(linha[5]);
                      obj.horasConsumidas =  Number(linha[6]);
                      obj.valorVenda = Number(linha[7]);
                      obj.valorCustoPrevisto = Number(linha[8]);
                      obj.valorCustoReal = Number(linha[9]);
                      
                      let ativo: string = linha[10];
                      if(ativo.toUpperCase().trim() == "S"){
                        obj.ativo = true;
                      }else{
                        obj.ativo = false;
                      }

                      lista.push(obj);
                    }

                  }

                  if(responsavelInvalido){
                    toastr.warning('Responsável invalido! Verifique o responsavel informado.', 'Atenção!');
                    input.value = "";
                  }else if(clienteInvalida){
                    toastr.warning('Cliente invalido! Verifique o cliente informado.', 'Atenção!');
                    input.value = "";
                  }else if(verticalInvalido){
                    toastr.warning('Vertical invalida! Verifique a vertical informada.', 'Atenção!');
                    input.value = "";
                  }else if(arquivoInvalido){
                    toastr.warning('Arquivo invalido! Verifique a quantidades de colunas.', 'Atenção!');
                    input.value = "";
                  }else{
                    if(lista.length == 0){
                      toastr.warning('Arquivo vazio!', 'Atenção!');
                      input.value = "";
                    }else{
                      
                      for(var l = 0; l < lista.length; l++){
                        
                        var existe = false;
                        for(var c = 0; c < centrodecusto.length; c++){
                          if(centrodecusto[c].descricaoMin == lista[l].descricaoMin){
                            existe = true;
                          }
                        }

                        if(existe == false){
                          crudService.salvar("centrodecusto", lista[l]);
                        }

                      }

                      input.value = "";
                      toastr.success('Importação executada!', 'Sucesso!');

                    }
                  }
                  
              };
              
            })(this.CrudService, this.toastr, this.centrodecustos, this.clientes, this.vertical, this.gestores);  

          }

         

    }

  }

}
