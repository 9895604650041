import { Semana } from './semana';

export class RecursoMC {

    public id: string;
    public tipo: string;
    public semanas: Semana[];
    public custo: number;
    public horasSemana: number;

    constructor(){

    }
}
