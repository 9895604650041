
export class Notificacao {

    public id: string;
    public assunto: string;
    public titulo: string;
    public msg: string;
    public status: string;
    public emailDestino: string;
    public link: string;
    
    constructor(){
        
    }
}
