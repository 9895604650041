
export class Perfil {

    public id: string;
    public codigo: string;
    public descricao: string;
    public tecnologia: string;
    public nivel: string;
    public custoHora: Number;
    public grupo: string; 

    constructor(){
        
    }
}
