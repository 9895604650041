import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';
import { FirebaseServiceProvider } from '../providers/firebase-service';
import { ClientesService } from '../services/clientes.service';
import { Subscription } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-dashboard',
  templateUrl: '../views/login/login.component.html'
})
export class AuthComponent {

  isLoading = false;
  error: string = null;
  movSub: Subscription;
  
  constructor(
      private authService: AuthService, 
      private router: Router,
      private fbService: FirebaseServiceProvider,
      private clienteService: ClientesService
  ) {
     if(this.authService.autoLogin()){
        this.isLoading = true;
        //this.fbService.init('cliente');
        this.router.navigate(['/dashboard']);
     }else{
        this.fbService.init('global');
     }
  }

  onRegistrar(){
    this.router.navigate(['/register']);
  }

  onSubmit(form: NgForm) {

    this.error = null;
    
    if (!form.valid) {
      return;
    }

    const email = form.value.email;
    const password = form.value.password;

    this.isLoading = true;

    this.authService.login(email, password)
      .then(
      (result) => {
      
          this.isLoading = false;

          if (result.user.emailVerified || true) {
            this.authService.handleAuthentication(result.user.email, result.user.uid, result.user.refreshToken, 3600);

            const dominio = result.user.email.split("@")[1];
           
            if (this.movSub && this.movSub.closed == false){
              this.movSub.unsubscribe();
            }
        
            if (!this.movSub || this.movSub.closed){
                     
              this.movSub = this.clienteService.getCliente(dominio).subscribe(config =>{
                  
                if(config){
                  localStorage.setItem('clienteNextCapacity', dominio);

                  const secretKey = "coachitagro!!!!!";
                  let configCrypto = CryptoJS.AES.encrypt(JSON.stringify(config), secretKey).toString();
                  
                  localStorage.setItem(dominio, configCrypto);
                  //this.fbService.init('cliente');

                  this.router.navigate(['/dashboard']);
                }else{  
                  this.error = "Domínio não permitido! Favor contatar o administrador."
                }
                  
               },error=>{
                    console.log(error);
               });
        
            }

          } else {
            this.error = "E-mail não verificado!"
          }

      },
      error => {
        console.log(error);
        this.error = this.authService.handleError(error.code);
        this.isLoading = false;
      }
    );
  
    form.reset();
  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

}
