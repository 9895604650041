import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CrudService } from "../../services/crudService";
import { ModalService } from '../../services/modal.service';
import { Colaborador } from '../../model/colaborador';
import { ConfirmationDialogService } from '../../components/confirmation-dialog/confirmation-dialog.service';
import { CsvDataService } from '../../services/csvdata.service';
import { ColaboradorCSV } from '../../model/colaboradorCSV';

@Component({
  selector: 'app-colaboradores',
  templateUrl: './colaboradores.component.html',
  styleUrls: ['./colaboradores.component.scss']
})
export class ColaboradoresComponent implements OnInit {

  movSub: Subscription;
  movSubPerfil: Subscription;
  colaboradors: any[];
  gestores: any[];
  perfil: any[];
  fileReader: FileReader;
  fileToRead: File;

  constructor(
      private spinner: NgxSpinnerService,
      private CrudService: CrudService,
      private modalService: ModalService,
      private cadastroService: CrudService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService,
      private csvDataService: CsvDataService
  ){

  }

  ngOnInit() {
    this.getperfil();
  }

  downloadTemplate(){

    let colaboradoresCsv: any = [];

    if(this.colaboradors.length > 0){


      for(var i= 0; i < this.colaboradors.length; i++){

        let colaboradorCSV = new ColaboradorCSV();

        colaboradorCSV._1_sigla = this.colaboradors[i].sigla;
        colaboradorCSV._2_nome = this.colaboradors[i].nome;
        colaboradorCSV._3_email = this.colaboradors[i].email;

        for(var p = 0; p < this.perfil.length; p++){
          if(this.colaboradors[i].perfil === this.perfil[p].id){
            colaboradorCSV._4_perfil = this.perfil[p].codigo;
          }
        }

        colaboradorCSV._5_valorHora = this.colaboradors[i].valorHora;
        colaboradorCSV._6_saldoHora = this.colaboradors[i].saldoHora;

        for(var g = 0; g < this.gestores.length; g++){
          if(this.colaboradors[i].gestorDireto === this.gestores[g].id){
            colaboradorCSV._7_gestorDireto = this.gestores[g].email;
          }
        }

        if(this.colaboradors[i].gestor){
          colaboradorCSV._8_gestor = "S";
        }else{
          colaboradorCSV._8_gestor = "N";
        }

        if(this.colaboradors[i].mediador){
          colaboradorCSV._9_mediador = "S";
        }else{
          colaboradorCSV._9_mediador = "N";
        }

        if(this.colaboradors[i].ativo){
          colaboradorCSV._10_ativo = "S";
        }else{
          colaboradorCSV._10_ativo = "N";
        }

        colaboradoresCsv.push(colaboradorCSV);
      }
    }else{

      let colaboradorCSV = new ColaboradorCSV();

      colaboradorCSV._1_sigla = "";
      colaboradorCSV._2_nome = "";
      colaboradorCSV._3_email = "";
      colaboradorCSV._4_perfil = "";
      colaboradorCSV._5_valorHora = 0;
      colaboradorCSV._6_saldoHora = 0;
      colaboradorCSV._7_gestorDireto = "";
      colaboradorCSV._8_gestor = "";
      colaboradorCSV._9_mediador = "";
      colaboradorCSV._10_ativo = "";

      colaboradoresCsv.push(colaboradorCSV);
    }
    this.csvDataService.exportToCsv("colaborador_capacity.csv", colaboradoresCsv);
  }

  async getcolaboradors(){

    this.colaboradors = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();

      this.movSub = this.CrudService.listar("colaborador", null, null).subscribe(
        (result: any) =>{

          this.colaboradors =  result;
          console.log(result);
          this.setGestores(this.colaboradors);
          this.spinner.hide();

       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }


  }

  async getperfil(){

    this.perfil = [];

    if (this.movSubPerfil && this.movSubPerfil.closed == false){
      this.movSubPerfil.unsubscribe();
    }

    if (!this.movSubPerfil || this.movSubPerfil.closed){

      this.movSubPerfil = this.cadastroService.listar("perfil", null, null).subscribe(
        (result: any) =>{

          this.perfil =  result;
          console.log(result);
          this.getcolaboradors();
       },error=>{
            console.log(error);
       });

    }


  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (this.movSubPerfil && this.movSubPerfil.closed == false){
      this.movSubPerfil.unsubscribe();
    }
  }

  novocolaborador(){

    var _obj = {
      colaborador: null,
      colaboradores: this.colaboradors
    }

    this.modalService.modalDetalhesColaborador.emit(_obj);
  }

  editar(colaborador: Colaborador){

    var _obj = {
      colaborador: colaborador,
      colaboradores: this.colaboradors
    }

    this.modalService.modalDetalhesColaborador.emit(_obj);
  }

  excluir(colaboradors: Colaborador){

    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{

        if(confirmed){
          this.cadastroService.excluir("colaborador", colaboradors.id);

          this.toastr.success('Colaborador excluído com sucesso!', 'Sucesso!');
          this.getcolaboradors();

        }

      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });

  }

  geraCor(){
    var hexadecimais = '0123456789ABCDEF';
    var cor = '#';

    // Pega um número aleatório no array acima
    for (var i = 0; i < 6; i++ ) {
    //E concatena à variável cor
        cor += hexadecimais[Math.floor(Math.random() * 16)];
    }
    return cor;
  }

  atualizar(c: Colaborador){

    this.cadastroService.atualizar("colaborador", c);
    this.toastr.success('Nova cor atualizada!', 'Sucesso!');
  }

  mudarCor(c: Colaborador){
    c.cor = this.geraCor();
    this.atualizar(c);
  }

  importar(){

    if(this.fileToRead){
      this.fileReader.readAsText(this.fileToRead, "UTF-8");
    }else{
      this.toastr.warning('Nenhum arquivo selecionado!', 'Atenção!');
    }

  }

  setGestores(colaboradores: any[]){

    let _gestores: any[] = [];

    for(var i = 0; i < colaboradores.length; i++){

      if(colaboradores[i].gestor || colaboradores[i].mediador){
        _gestores.push(colaboradores[i]);
      }
    }

    this.gestores = _gestores;
  }

  onFileSelect(input: HTMLInputElement) {

    const files = input.files;

    this.fileToRead = null;

    if (files && files.length) {

          if(files[0].name.indexOf("colaborador") == -1 ||
              files[0].name.indexOf("capacity") == -1 ||
                files[0].name.indexOf("csv") == -1){

            this.toastr.warning('Arquivo incorreto!', 'Atenção!');

            input.value = "";

          }else{
            this.fileToRead = files[0];

            this.fileReader = new FileReader();

             this.fileReader.onload = (function(crudService, toastr, colaboradores, perfil, gestores, geraCor){

              return function(e){


                  const textFromFileLoaded = e.target.result;
                  let csvContent = textFromFileLoaded.split('\n');


                  var arquivoInvalido = false;
                  var perfilInvalido = false;
                  var gestorInvalido = false;

                  let lista : Colaborador[] = [];

                  for(var i= 1; i < csvContent.length; i++){

                    var linha = csvContent[i].split(';');

                    if(linha.length != 10){
                      arquivoInvalido = true;
                    }else{
                      let obj : Colaborador = new Colaborador();
                      obj.sigla = linha[0];
                      obj.cor = geraCor();
                      obj.nome = linha[1];
                      obj.email = linha[2];

                      var achouPerfil: any = null;
                      for (var t = 0; t < perfil.length; t++) {
                          if (perfil[t].codigo === linha[3]) {
                            achouPerfil = perfil[t];
                          }
                      }

                      if(achouPerfil){
                        obj.perfil = achouPerfil.id;
                      }else{
                        perfilInvalido = true;
                        i = csvContent.length;
                      }

                      obj.valorHora = Number(linha[4]);
                      obj.saldoHora = Number(linha[5]);

                      var achouGestor: any = null;
                      for (var n = 0; n< gestores.length; n++) {
                          if (gestores[n].email ===  linha[6]) {
                            achouGestor = gestores[n];
                          }
                      }

                      if(achouGestor){
                        obj.gestorDireto = achouGestor.id;
                      }else{
                        gestorInvalido = true;
                        i = csvContent.length;
                      }

                      let gestor: string = linha[7];
                      if(gestor.toUpperCase().trim() == "S"){
                        obj.gestor = true;
                      }else{
                        obj.gestor = false;
                      }

                      let mediador: string = linha[8];
                      if(mediador.toUpperCase().trim() == "S"){
                        obj.mediador = true;
                      }else{
                        obj.mediador = false;
                      }

                      let ativo: string = linha[9];
                      if(ativo.toUpperCase().trim() == "S"){
                        obj.ativo = true;
                      }else{
                        obj.ativo = false;
                      }

                      lista.push(obj);
                    }

                  }

                  if(perfilInvalido){
                    toastr.warning('Perfil invalido! Verifique o perfil informado.', 'Atenção!');
                    input.value = "";
                  }else if(gestorInvalido){
                    toastr.warning('Gestor direto invalido! Verifique o gestor direto informado.', 'Atenção!');
                    input.value = "";
                  }else if(arquivoInvalido){
                    toastr.warning('Arquivo invalido! Verifique a quantidades de colunas.', 'Atenção!');
                    input.value = "";
                  }else{
                    if(lista.length == 0){
                      toastr.warning('Arquivo vazio!', 'Atenção!');
                      input.value = "";
                    }else{

                      for(var l = 0; l < lista.length; l++){

                        var existe = false;
                        for(var c = 0; c < colaboradores.length; c++){
                          if(colaboradores[c].email == lista[l].email){
                            existe = true;
                          }
                        }

                        if(existe == false){
                          crudService.salvar("colaborador", lista[l]);
                        }

                      }

                      input.value = "";
                      toastr.success('Importação executada!', 'Sucesso!');

                    }
                  }

              };

            })(this.CrudService, this.toastr, this.colaboradors, this.perfil, this.gestores, this.geraCor);

          }



    }

  }


}
