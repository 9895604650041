
export class CentroDeCusto {

    public id: string;
    public descricaoMin: string;
    public descricao: string;
    public cliente: string;
    public vertical: string;
    public responsavel: string;
    public horasVendidas: Number;
    public horasConsumidas: Number;
    public valorVenda: Number;
    public valorCustoPrevisto: Number;
    public valorCustoReal: Number;
    public ativo: boolean;

    constructor(){
        
    }
}
