
export class Vertical {

    public id: string;
    public codigo: string;
    public descricao: string;
 
    constructor(){
        
    }
}
