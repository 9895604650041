import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Cliente } from '../../../model/cliente';
import { NgForm } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';
import { throwError, BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-detalhes-clientes',
  templateUrl: 'clientes-detalhes.component.html'
})
export class ClientesDetalhesComponent implements OnInit{

  @ViewChild('modalcliente', {static: false}) public modalcliente: ModalDirective;

  sub: boolean = false;
  _cliente: Cliente = new Cliente();
  @Output() loadclientes = new EventEmitter();
  titulo: string = "Novo Cliente";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  colaboradores: any[];
  clientes: any[];
  movSub: Subscription;
  movSubCli: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CrudService) {
  }

  ngOnInit() {
    this.getColaboradores();
    this.getClientes();

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesCliente.subscribe(
        (p: Cliente) => {

          if(p == null){
            this.titulo = "Novo Cliente"
            this._cliente = new Cliente();
          }else{
            this.titulo = "Editar Cliente"
            this._cliente = p;
          }

          this.limpaModal();

          this.modalcliente.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  hideModal(){
    this.modalcliente.hide();
    this.loadclientes.emit();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this._cliente.id){
      this.atualizar();
    }else{
      this.salvar();
    }


    form.reset();
  }

  salvar(){


    if( this._cliente.sigla.length!=3){
      this.toastr.success('A sigla do cliente tem que ter 3 letras!', 'Atenção!');
      return;
    }

    let objCliente = JSON.parse(JSON.stringify(this._cliente));

    if (this.movSubSalvar && this.movSubSalvar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed){

      this.movSubSalvar = this.cadastroService.findByGeneric("cliente", "codigo", this._cliente.codigo).subscribe(
        (result: any) =>{

          if(result.length > 0){
            this.toastr.error('Este código já esta sendo utilizado por outro cliente!', 'Atenção!');
            this.hideModal();
          }else{
            this.cadastroService.salvar("cliente", objCliente);

            this.toastr.success('Cliente cadastrado com sucesso!', 'Sucesso!');

            this.hideModal();

            this.movSubSalvar.unsubscribe();
          }



       },error=>{
            console.log(error);
            this.hideModal();
       });

    }

  }

  atualizar(){

    if( this._cliente.sigla.length!=3){
      this.toastr.success('A sigla do cliente tem que ter 3 letras!', 'Atenção!');
      this.hideModal();

    } else {

    let objCliente = JSON.parse(JSON.stringify(this._cliente));

    if (this.movSubAlterar && this.movSubAlterar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubAlterar || this.movSubAlterar.closed){

      this.movSubAlterar = this.cadastroService.findByGeneric("cliente", "codigo", this._cliente.codigo).subscribe(
        (result: any) =>{

          if(result.length > 0){
            if(result[0].codigo == objCliente.codigo){
              this.cadastroService.atualizar("cliente", objCliente);

              this.toastr.success('Cliente atualizado com sucesso!', 'Sucesso!');

              this.hideModal();

              this.movSubAlterar.unsubscribe();
            }
          }

       },error=>{
            console.log(error);
            this.hideModal();
       });

    }
  }

  }

  async getColaboradores(){
    this.colaboradores = [];
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
    if (!this.movSub || this.movSub.closed){
      this.movSub = this.cadastroService.listar("colaborador", null, null).subscribe(
        (result: any) =>{

          this.colaboradores =  result;
          this.colaboradores.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
       },error=>{
            console.log(error);
       });
    }
  }

    async getClientes(){
      this.clientes = [];
      if (this.movSubCli && this.movSubCli.closed == false){
        this.movSubCli.unsubscribe();
      }
      if (!this.movSubCli || this.movSubCli.closed){
        this.movSubCli = this.cadastroService.listar("cliente", null, null).subscribe(
          (result: any) =>{
           this.clientes =  result;
         },error=>{
              console.log(error);
         });
      }
  }

  validaSigla(){

      this._cliente.sigla = this._cliente.sigla.toUpperCase()
      for(var c=0; c<this.clientes.length; c++){
        if( this._cliente.sigla == this.clientes[c].sigla){
          this.toastr.error("A sigla " +  this._cliente.sigla +" já existe. Defina outra!");
          this._cliente.sigla = '';
          break;
        }
      }

  }


  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

}
