import { Alocacao } from './alocacao';

export class AlocacaoResumo {

    public cliente: string;
    public centrodecusto: string;
    public colaborador: string;
    public nomeColaborador: string;
    public horas: Number;
    public alocacoesDetalhes: Alocacao[];
    public collapsed: boolean;
    
    constructor(){ 
        
    }
}
