import { Component, OnDestroy, Inject, OnInit, OnChanges, AfterContentChecked } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems } from '../../_nav';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { Colaborador } from '../../model/colaborador';
import { Subscription } from 'rxjs';
import { CrudService } from '../../services/crudService';
import { ModalService } from '../../services/modal.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements AfterContentChecked, OnInit, OnDestroy{

  public navItems = navItems;
  public sidebarMinimized = true;
  public minimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  private currentRoute: string;
  colaboradores: Colaborador[] = [];
  subColaboradores: Subscription;
  usuarioLogado: any;
  mostraMenu: boolean = true;
  mostraMenuAside: boolean = false;

  constructor(
    @Inject(DOCUMENT) _document?: any,
    private authService?: AuthService,
    private router?: Router,
    private crudService?: CrudService,
    private modalService?: ModalService,
    private toastr?: ToastrService) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });

    this.getColaboradores();
  }

  ngOnInit() {
    this.getColaboradores();
  }

  ngAfterContentChecked(){
    this.currentRoute = this.router.url;

    if(this.usuarioLogado){

      if(this.usuarioLogado.cadastrador || this.usuarioLogado.gestor || this.usuarioLogado.mediador){

        if(this.currentRoute.toLowerCase().indexOf("dashboard") != -1){
          this.minimized = true;
          this.mostraMenuAside = true;

          if(this.usuarioLogado.cadastrador || this.usuarioLogado.mediador){
            this.mostraMenu = true;
          }else{
            this.mostraMenu = false;
          }

        }else{
          this.minimized = false;
          this.mostraMenuAside = false;
        }

      }else{
        this.mostraMenu = false;
        this.mostraMenuAside = false;
      }

      if(this.currentRoute.toLowerCase().indexOf("mcdigital") != -1){
        if(!this.usuarioLogado.comercial){
          this.router.navigateByUrl("/dashboard");
          this.toastr.error('Usuário sem permissão para MC Digital!', 'Sem permissão!');
        }
      }

    }else{
      this.mostraMenu = false;
      this.mostraMenuAside = false;
    }
  }

  ngOnDestroy(): void {

    if (this.subColaboradores && this.subColaboradores.closed == false){
      this.subColaboradores.unsubscribe();
    }

    this.changes.disconnect();
  }

  onLogout() {

    this.authService.logout();
  }

  alterarSenha(){
    this.modalService.modalAlterarSenha.emit(null);
  }

  mcdigital(){
    this.router.navigateByUrl("/cadastros/mcdigital")
  }

  alocacao(){
    this.router.navigateByUrl("/relatorios/alocacao")
  }

  projetos(){
    this.router.navigateByUrl("/relatorios/controle-projetos")
  }

  faturamentoAnual(){
    this.router.navigateByUrl("/relatorios/faturamento-anual")
	//this.router.navigateByUrl("/relatorios/faturamento-anual")
  }

  graficos(){
    this.router.navigateByUrl("/views/graficos")
  }

  solicitarAusencia(){

    let obj = {
      usuarioLogado: this.usuarioLogado,
      colaboradores: this.colaboradores
    }

    this.modalService.modalSolicitarAusencia.emit(obj);
  }

  acompanharAusencias(){
    this.modalService.modalAusencia.emit(this.usuarioLogado);
  }

  aprovarAusencias(){

    let obj = {
      usuarioLogado: this.usuarioLogado,
      colaboradores: this.colaboradores
    }

    this.modalService.modalAprovarAusencia.emit(obj);
  }

  filtroColaborador(colaborador: any){
    this.modalService.modalFiltroColaborador.emit(colaborador);
  }

  checkUsuarioLogado(){

    let user = JSON.parse(localStorage.getItem('userNextCapacity'));

    for(var i = 0; i < this.colaboradores.length; i++){
      if(this.colaboradores[i].email == user.email){

        this.usuarioLogado = {
          gestor : this.colaboradores[i].gestor,
          mediador : this.colaboradores[i].mediador,
          cadastrador : this.colaboradores[i].cadastrador,
          colaboradorID : this.colaboradores[i].id,
          email : this.colaboradores[i].email,
          nome: this.colaboradores[i].nome,
          gestorDireto: this.colaboradores[i].gestorDireto,
          financeiro: this.colaboradores[i].financeiro,
          tecnico: this.colaboradores[i].tecnico,
          comercial: this.colaboradores[i].comercial
        }

      }
    }

    this.subColaboradores.unsubscribe();

  }

  getColaboradores(){

    this.colaboradores = [];

    if (this.subColaboradores && this.subColaboradores.closed == false){
      this.subColaboradores.unsubscribe();
    }

    if (!this.subColaboradores || this.subColaboradores.closed){

      this.subColaboradores = this.crudService.listar("colaborador", "ativo", true).subscribe(
        (result: any) =>{
          this.colaboradores =  result;
          this.checkUsuarioLogado();

       },error=>{
            console.log(error);
       });

    }

  }

}
