import { Directive, Input , ElementRef, Renderer2, OnInit, RendererStyleFlags2} from '@angular/core';

@Directive({
  selector: '[appDatasProjeto]'
})
export class DatasProjetoDirective implements OnInit{

  @Input() codigo: string;
  @Input() datas: any[];
  @Input() dataTipo: string;

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){

     //console.log("########## data xpto",  this.datas)

      for(var i = 0; i < this.datas.length; i++){



        if(this.datas[i].Codigo.trim() == this.codigo.trim() ){

          if(this.dataTipo == 'Data Início Plan'){
            const text = this.render.createText(this.datas[i].DataInicioPlan.toString());
            this.render.appendChild(this.elRef.nativeElement, text);
          }

          if(this.dataTipo == 'Data Fim Real'){
            const text = this.render.createText(this.datas[i].DataFimReal.toString());
            this.render.appendChild(this.elRef.nativeElement, text);
          }

          if(this.dataTipo == 'Faturado' || this.dataTipo == 'Faturamento Planejado'){
            var faturado = 0;
            if(this.datas[i].Faturado != ''){
              const text = this.render.createText( parseFloat(this.datas[i].Faturado).toFixed(2).toString() );
              faturado = parseFloat(this.datas[i].Faturado)
              if(this.dataTipo == 'Faturado'){
                this.render.appendChild(this.elRef.nativeElement, text);
              }
            }
          }

          if(this.dataTipo == 'Não Faturado' || this.dataTipo == 'Faturamento Planejado'){
            var naoFaturado = 0;
            if(this.datas[i].NaoFaturado != ''){
              const text = this.render.createText( parseFloat(this.datas[i].NaoFaturado).toFixed(2).toString() );
              naoFaturado = parseFloat(this.datas[i].NaoFaturado)
              if(this.dataTipo == 'Não Faturado'){
                this.render.appendChild(this.elRef.nativeElement, text);
              }

            }
          }

          if(this.dataTipo == 'Valor CR' || this.dataTipo == 'Faturamento Planejado'){
            var valorCr = 0;
            if(this.datas[i].ValorChangeRequest != ''){
              const text = this.render.createText( parseFloat(this.datas[i].ValorChangeRequest).toFixed(2).toString() );
              valorCr = parseFloat(this.datas[i].ValorChangeRequest)
              if(this.dataTipo == 'Valor CR'){
                this.render.appendChild(this.elRef.nativeElement, text);
              }
            }
          }

          if(this.dataTipo == 'percentNF'){
            if(this.datas[i].Faturado != '' || this.datas[i].NaoFaturado != '' ) {
              var vFat = parseFloat(this.datas[i].Faturado);
              var vNaoFat = parseFloat(this.datas[i].NaoFaturado);
              const text = this.render.createText( (vNaoFat/(vNaoFat+vFat)*100).toFixed(2).toString() )
              this.render.appendChild(this.elRef.nativeElement, text);
            }
          }

          if(this.dataTipo == 'Faturamento Planejado'){
            var faturamentoPlanejado = faturado + naoFaturado - valorCr;
            if(faturamentoPlanejado > 0){
              const text = this.render.createText( faturamentoPlanejado.toFixed(2).toString() );
              this.render.appendChild(this.elRef.nativeElement, text);
            }
          }




        }
      }
  }
}

