import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CrudService } from "../../services/crudService";
import { ModalService } from '../../services/modal.service';
import { Vertical } from '../../model/vertical';
import { ConfirmationDialogService } from '../../components/confirmation-dialog/confirmation-dialog.service';
import { CsvDataService } from '../../services/csvdata.service';
import { VerticalCSV } from '../../model/verticalCSV';
 
@Component({
  selector: 'app-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})  
export class VerticalComponent implements OnInit {
      
  movSub: Subscription;
  vertical: any[];
  fileReader: FileReader;
  fileToRead: File;

  constructor(
      private spinner: NgxSpinnerService,
      private CrudService: CrudService,
      private modalService: ModalService,
      private cadastroService: CrudService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService,
      private csvDataService: CsvDataService
  ){
    
  }

  ngOnInit() {
    this.getvertical();
  }

  downloadTemplate(){

    let _verticalCSV: any = [];

    if(this.vertical.length > 0){
      for(var i= 0; i < this.vertical.length; i++){

        let verticalCSV = new VerticalCSV();
  
        verticalCSV._1_codigo = this.vertical[i].codigo;
        verticalCSV._2_descricao = this.vertical[i].descricao;
  
        _verticalCSV.push(verticalCSV);
      }
    }else{
      let verticalCSV = new VerticalCSV();
  
      verticalCSV._1_codigo = "";
      verticalCSV._2_descricao = "";

      _verticalCSV.push(verticalCSV);
    }    
    
    this.csvDataService.exportToCsv("vertical_capacity.csv", _verticalCSV);
  }

  async getvertical(){
 
    this.vertical = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();
     
      this.movSub = this.CrudService.listar("vertical", null, null).subscribe(
        (result: any) =>{

          this.vertical =  result;
          console.log(result);

          this.spinner.hide();
  
       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }
    

  }


  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

  novovertical(){
    this.modalService.modalDetalhesVertical.emit(null);
  }

  editar(vertical: Vertical){
    this.modalService.modalDetalhesVertical.emit(vertical);
  }
 
  excluir(vertical: Vertical){
    
    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          this.cadastroService.excluir("vertical", vertical.id);
            
          this.toastr.success('Vertical excluída com sucesso!', 'Sucesso!');
          this.getvertical();               
           
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    
  }

  importar(){
    
    if(this.fileToRead){
      this.fileReader.readAsText(this.fileToRead, "UTF-8");
    }else{
      this.toastr.warning('Nenhum arquivo selecionado!', 'Atenção!');
    }
  
  }

  onFileSelect(input: HTMLInputElement) {

    const files = input.files;

    this.fileToRead = null;

    if (files && files.length) {
       
          if(files[0].name.indexOf("vertical") == -1 ||
              files[0].name.indexOf("capacity") == -1 ||
                files[0].name.indexOf("csv") == -1){
           
            this.toastr.warning('Arquivo incorreto!', 'Atenção!');

            input.value = "";

          }else{
            this.fileToRead = files[0];

            this.fileReader = new FileReader();

             this.fileReader.onload = (function(crudService, toastr, vertical){
              
              return function(e){
                 
                  const textFromFileLoaded = e.target.result;              
                  let csvContent = textFromFileLoaded.split('\n');  
                   
                  var arquivoInvalido = false;

                  let lista : Vertical[] = [];

                  for(var i= 1; i < csvContent.length; i++){
              
                    var linha = csvContent[i].split(';');

                    if(linha.length != 2){
                      arquivoInvalido = true;
                    }else{
                      let obj : Vertical = new Vertical();
                      obj.codigo = linha[0];
                      obj.descricao = linha[1];
                      
                      lista.push(obj);
                    }

                  }

                  if(arquivoInvalido){
                    toastr.warning('Arquivo invalido! Verifique a quantidades de colunas.', 'Atenção!');
                    input.value = "";
                  }else{
                    if(lista.length == 0){
                      toastr.warning('Arquivo vazio!', 'Atenção!');
                      input.value = "";
                    }else{
                      
                      for(var l = 0; l < lista.length; l++){
                        
                        var existe = false;
                        for(var c = 0; c < vertical.length; c++){
                          if(vertical[c].codigo == lista[l].codigo){
                            existe = true;
                          }
                        }

                        if(existe == false){
                          crudService.salvar("vertical", lista[l]);
                        }

                      }

                      input.value = "";
                      toastr.success('Importação executada!', 'Sucesso!');

                    }
                  }
                  
              };
              
            })(this.CrudService, this.toastr, this.vertical);  

          }

         

    }

  }

}
