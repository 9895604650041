
export class PerfilCCusto {

    public id: string;
    public centrodecusto: string;
    public perfil: string;
    public horas: Number;
    public custo: Number;
    public tipo: string;

    constructor(){
        
    }
}
