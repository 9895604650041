import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';


@Directive({
  selector: '[appFaturamentoResumo]'
})
export class FaturamentoResumoDirective implements OnInit{

  @Input() mes: string;
  @Input() projeto: string;
  @Input() dados: any[];
  @Input() dadosC: any[];
  @Input() dadosColab: any[];
  @Input() campo: string;


  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){



    if(this.dados != null){
      for(var i = 0; i < this.dados.length; i++){
        if(this.projeto.trim() == this.dados[i].Projeto.trim()){
          var valor = 0
          let arrayD: string[] = this.dados[i].VctoValor.split(",");
          for(var n = 0; n < arrayD.length; n++){

            if(this.mes != null && arrayD[n].substring(0, 2) == this.mes ){
              valor += parseFloat(arrayD[n].substring(3))
            }

            valor += parseFloat(arrayD[n].substring(3))

          }

        }
      }
      const text = this.render.createText(valor.toFixed(2).toString());
      this.render.appendChild(this.elRef.nativeElement, text);
    }

    if(this.dadosC != null){
        for(var i = 0; i < this.dadosC.length; i++){
          if(this.projeto.trim() == this.dadosC[i].Projeto.trim()){

            if(this.campo =='cliente'){
               const text = this.render.createText(this.dadosC[i].Cliente);
               this.render.appendChild(this.elRef.nativeElement, text);
            }
            if(this.campo == 'datainicio'){
              const text = this.render.createText(this.dadosC[i].DataInicio);
              this.render.appendChild(this.elRef.nativeElement, text);
            }
            if(this.campo == 'comissao'){
              const text = this.render.createText(this.dadosC[i].Comissao);
              this.render.appendChild(this.elRef.nativeElement, text);
            }
            if(this.campo == 'area'){
              const text = this.render.createText( this.dadosC[i].Area == null ? '???' :this.dadosC[i].Area);
              this.render.appendChild(this.elRef.nativeElement, text);
            }

            if (this.campo == 'comercial') {

              console.log('***** this.dadosColab', this.dadosColab);
              console.log('##### this.dadosC', this.dadosC);


              for(var z = 0; z < this.dadosColab.length; z++){

                if(this.dadosC[i].Comercial.trim() == this.dadosColab[z].nome.trim() ){
                  var responsavel = this.dadosColab[z].aka.trim();
                  break;
                } else {
                  var responsavel = this.dadosC[i].Comercial.trim();
                }

              }
              var text = this.render.createText(  this.dadosC[i].Comercial == 'Selecione' ? '???' : responsavel  ) ;
              this.render.appendChild(this.elRef.nativeElement, text);
            }

          }
        }


    }


  }




}

