import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[appStatusMC]'
})
export class StatusMCDirective implements OnInit{

  @Input() value: number;

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){

      if(this.value == 0){

        const text = this.render.createText('Não solicitado');

        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-secondary");

      }else if(this.value == 1){

        const text = this.render.createText('Aguardando...');

        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-warning");

      }else if(this.value == 2){

        const text = this.render.createText('Aprovado');

        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-success");

      }else if(this.value == 3){

        const text = this.render.createText('Reprovado');

        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-danger");

      }else if(this.value == 4){

        const text = this.render.createText('Finalizado');

        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-success");

      } else if(this.value == -1){
        const text = this.render.createText('Cancelado');
        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-danger");
      }
      else{

        const text = this.render.createText('Em criação...');

        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-secondary");
      }


    }


}

