import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';
import { Perfil } from '../model/perfil';

@Directive({
  selector: '[appPerfil]'
})
export class PerfilDirective implements OnInit{
    
  @Input() id: string;
  @Input() perfil: any[];

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){

    for(var i = 0; i < this.perfil.length; i++){

      if(this.perfil[i].id == this.id){

        const text = this.render.createText(this.perfil[i].descricao);
  
        this.render.appendChild(this.elRef.nativeElement, text);
      }
    }

  }
    

}

