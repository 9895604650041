import { AngularFireAuth } from '@angular/fire/auth';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlocacaoComponent } from './alocacao/alocacao.component';
import { AusenciasComponent } from './ausencias/ausencias.component';
import { ControleProjetosComponent } from './controle-projetos/controle-projetos.component';
import { FaturamentoAnualComponent } from './faturamento-anual/faturamento-anual.component';
import { FaturamentoCompletoComponent } from './faturamento-completo/faturamento-completo.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Relatorios'
    },
    children: [

      {
        path: 'alocacao',
        component: AlocacaoComponent,
        data: {
          title: 'Alocação'
        }
      },
      {
        path: 'ausencias',
        component: AusenciasComponent,
        data: {
          title: 'Ausências'
        }
      },
      {
        path: 'controle-projetos',
        component: ControleProjetosComponent,
        data: {
          title: 'Controle de Projetos'
        }
      },
	  
	 
      {
        path: 'faturamento-anual',
        component: FaturamentoAnualComponent,
        data: {
          title: 'Faturamento Anual'
        }
      },
	
	  
	  {
        path: 'faturamento-completo',
        component: FaturamentoCompletoComponent,
        data: {
          title: 'Faturamento Completo'
        }
      },



    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RelatoriosRoutingModule {}


/*
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlocacaoComponent } from './alocacao/alocacao.component';
import { AusenciasComponent } from './ausencias/ausencias.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Relatorios'
    },
    children: [

      {
        path: 'alocacao',
        component: AlocacaoComponent,
        data: {
          title: 'Alocação'
        }
      },
      {
        path: 'ausencias',
        component: AusenciasComponent,
        data: {
          title: 'Ausências'
        }
      }


    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RelatoriosRoutingModule {}
*/
