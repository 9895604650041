import { Component, OnInit, Input } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from 'angularfire2/storage';
import { Observable } from 'rxjs/internal/Observable';
import { finalize } from 'rxjs/operators';
import { MCDigital } from '../../model/mcdigital';
import { Subscription } from 'rxjs';
import { CrudService } from '../../services/crudService';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  @Input() mcdigital: MCDigital;

  // Main task
  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: Observable<string>;

  // State for dropzone CSS toggling
  isHovering: boolean;

  files: any[] = [];
  movSubAlterar: Subscription;

  btnStart: boolean = true;
  lblBtnEnviar: string = "Enviar";

  constructor(private storage: AngularFireStorage,
    private cadastroService: CrudService,
    private toastr: ToastrService) { }


  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  addFile(event: FileList) {
    this.files = [];
    const file = event.item(0)
    this.files.push(file);
  }

  startUpload() {
      if(this.files && this.files.length > 0 && this.files[0].size <= 10485760){

        this.btnStart = false;
        this.lblBtnEnviar = "Aguarde..."

        for(let f of this.files){

          // The File object
          const file = f;

          // The storage path
          const path = `assets/${file.name}`;

          // The main task
          this.task = this.storage.upload(path, file);

          // Progress monitoring
          this.percentage = this.task.percentageChanges();
          this.snapshot   = this.task.snapshotChanges();

          // The file's download URL
          const task = this.storage.upload(path, file);
          const ref = this.storage.ref(path);

          task.snapshotChanges().pipe(
            finalize(() => {
              ref.getDownloadURL().subscribe(url => {

                let anexo = {
                  "nome": file.name,
                  "url": url
                }

                if(this.mcdigital.anexos && this.mcdigital.anexos.length > 0){
                  this.mcdigital.anexos.push(anexo);
                }else{
                  this.mcdigital.anexos = [];
                  this.mcdigital.anexos.push(anexo);
                }

                this.atualizar();
              });

            })
          )
          .subscribe();

          }
        }else{

          if(this.files[0].size > 10485760){
            this.toastr.warning('Este arquivo não poderá ser anexado porque ultrapassa o limite de 10 Mb!', 'Atenção!');
            //this.atualizar();
          } else {
          this.toastr.warning('Selecione algum arquivo!', 'Atenção!');
          }



        }

  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
  }

  atualizar(){

    let objmcdigital = JSON.parse(JSON.stringify(this.mcdigital));

    if (this.movSubAlterar && this.movSubAlterar.closed == false){
      this.movSubAlterar.unsubscribe();
    }

    if (!this.movSubAlterar || this.movSubAlterar.closed){

      this.movSubAlterar = this.cadastroService.findByGeneric("mcdigital", "siglamc", objmcdigital.siglamc).subscribe(
        (result: any) =>{

          if(result.length > 0){
            if(result[0].cnpj == objmcdigital.cnpj){
              this.cadastroService.atualizar("mcdigital", objmcdigital);

              this.toastr.success('Anexo enviado com sucesso!', 'Sucesso!');
              this.btnStart = true;
              this.lblBtnEnviar = "Enviar"
              this.movSubAlterar.unsubscribe();

              setTimeout(()=>{
                this.percentage = null;
              }, 1000);

            }
          }

       },error=>{
          this.btnStart = true;
          this.lblBtnEnviar = "Enviar"
          console.log(error);

          setTimeout(()=>{
            this.percentage = null;
          }, 1000);
       });

    }

  }

}
