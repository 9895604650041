import {Component, ViewChild, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import * as $ from 'jquery';
import { Ausencia } from '../../model/ausencia';
import { ToastrService } from 'ngx-toastr';
import { CrudService } from '../../services/crudService';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { Notificacao } from '../../model/notificacao';

@Component({
  selector: 'app-aprovar-ausencias',
  templateUrl: 'aprovar-ausencias.component.html'
})
export class AprovarAusenciasComponent implements OnInit{


  @ViewChild('modalaprovarausencia', {static: false}) public modalaprovarausencia: ModalDirective;

  sub: boolean = false;

  titulo: string = "Aprovar Ausências";
  movSubAusencia: Subscription;
  ausencias: any = [];
  movSubSalvar: Subscription;
  usuarioLogado: any;
  colaboradores: any[];
  capacity: any[];
  subCapacity: Subscription;

  constructor(
    private modalService: ModalService,
    private crudService: CrudService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService) {
  }

  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalAprovarAusencia.subscribe(
        (obj: any) => {

          this.usuarioLogado = obj.usuarioLogado;
          this.colaboradores = obj.colaboradores;

          this.getAusencias();

          this.limpaModal();

          this.modalaprovarausencia.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  getAusencias(){

    this.ausencias = [];

    if (this.movSubAusencia && this.movSubAusencia.closed == false){
      this.movSubAusencia.unsubscribe();
    }

    if (!this.movSubAusencia || this.movSubAusencia.closed){
      this.movSubAusencia = this.crudService.findByGeneric("ausencia", "gestorDireto", this.usuarioLogado.colaboradorID).subscribe(

        (result: any) => {

           for (let a of result) {

             if (a.subaprovacoes && a.subaprovacoes.length > 0) {

              let aprovacaoPendente = false;

              //
              for(let s of a.subaprovacoes){

                var existeGestor = false;
                for(let col of this.colaboradores){
                  if(s.gestorID == col.id){
                    existeGestor = true;

                  }
                }

                //se nao existir gestor, o mesmo esta desativado pois ja nao trabalha na empresa, entao APROVADO AUTOMATICA A SUBAPROVACAO
                if(existeGestor == false){
                  s.status = 1;
                  this.crudService.atualizar("ausencia", a);
                }

                if(s.status != 1){
                  aprovacaoPendente = true;
                }
              }
              //

              //if(aprovacaoPendente == false){   => comentado em 11/10/21 Enio
                this.ausencias.sort((b, c) => (b.periodoDe < c.periodoAte) ? 1 : ((c.periodoAte < b.periodoDe) ? -1 : 0)).push(a);
             // } 

            } else {
              this.ausencias.sort((c, b) => (c.periodoDe < b.periodoAte) ? 1 : ((b.periodoAte < c.periodoDe) ? -1 : 0)).push(a);

            }
          }

          this.getAusenciasSubAprovacoes();
       },error=>{
            console.log(error);
       });

    }

  }

  getAusenciasSubAprovacoes(){

    if (this.movSubAusencia && this.movSubAusencia.closed == false){
      this.movSubAusencia.unsubscribe();
    }

    if (!this.movSubAusencia || this.movSubAusencia.closed){
       this.movSubAusencia = this.crudService.findByGeneric("ausencia", null, null).subscribe(
        (ausencias: Ausencia[]) =>{

         for(let a of ausencias){
          if(a.subaprovacoes && a.subaprovacoes.length > 0){
            for(let s of a.subaprovacoes){
              if(s.gestorID == this.usuarioLogado.colaboradorID){
                this.ausencias.push(a);
              }
            }
          }
         }

       },error=>{
            console.log(error);
       });

    }

  }

  getSalvarCapacity(idColaborador, periodoDe, periodoAte, ausencia, _status, gestorNome){

    this.capacity = [];

    if (this.subCapacity && this.subCapacity.closed == false){
      this.subCapacity.unsubscribe();
    }

    if (!this.subCapacity || this.subCapacity.closed){

      this.subCapacity = this.crudService.listar("capacity", null, null).subscribe(
        (result: any) =>{

          this.capacity = [];

          for(let r of result){
            let data = new Date( r.data.seconds*1000);

            if( data >= periodoDe &&  data <= periodoAte){
              for(let d of r.detalhes){
                if(d.colaborador == idColaborador && d.gestor == this.usuarioLogado.email){
                  this.capacity.push(d);
                }
              }
            }
          }

          if(_status == 1){
            if(this.capacity.length == 0){
              this.salvar(ausencia, _status, gestorNome)
            }else{
              this.toastr.error('Ainda existem capacity registrado nesse período, necessario a exclusão! Favor comunicar os gestores responsaveis!', 'Atenção!');
            }
          }else{
            this.salvar(ausencia, _status, gestorNome)
          }

          this.subCapacity.unsubscribe();
       },error=>{
            console.log(error);
       });

    }

  }

  salvar(ausencia: any, _status: any, gestorNome: string){

      this.crudService.atualizar("ausencia", ausencia);
      this.getAusencias();

      if(_status == 1){
        this.toastr.success('Ausência aprovada com sucesso!', 'Sucesso!');
      }else{
        this.toastr.warning('Ausência foi reprovada!', 'Atenção!');
      }

      let notificacao = new Notificacao();

      if(_status == 1){
        notificacao.assunto = "Next Capacity - Aprovacao de Ausencia";
        notificacao.titulo = "Next Capacity - Aprovação de Ausência";
      }else{
        notificacao.assunto = "Next Capacity - Reprovacao de Ausencia";
        notificacao.titulo = "Next Capacity - Reprovação de Ausência";
      }

      notificacao.emailDestino = ausencia.colaborador;
      notificacao.link = "https://capacity.nextmobile.com.br/";

      var options = { year: 'numeric', month: '2-digit', day: '2-digit' };

      let ausenciaAny = JSON.parse(JSON.stringify(ausencia));

      let dataDe = new Date(ausenciaAny.periodoDe.seconds*1000);
      let dataAte = new Date(ausenciaAny.periodoAte.seconds*1000);

      let colaboradorNome = "";
      for(var c=0; c<this.colaboradores.length; c++){
        if(this.colaboradores[c].email == ausencia.colaborador){
          colaboradorNome = this.colaboradores[c].nome;
        }
      }

      let msg = "";
      if(_status == 1){

        if(ausencia.status == 3){
          msg = "<h3>Ausência <span style='color: green;'>PARCIALMENTE APROVADA !</span></h3>"+
          "<h3><b>Motivo:</b> "+ausencia.motivo+"</h3>"+
          "<h3><b>Período:</b> De "+dataDe.toLocaleDateString('pt-BR', options)+" Até "+dataAte.toLocaleDateString('pt-BR', options)+"</h3>"+
          "<hr><h3><b>Gestor:</b> "+gestorNome+"</h3>"+
          "<h3><b>Colaborador:</b> "+colaboradorNome+"</h3>"+
          "<h3><b>Observação:</b> "+ausencia.obs+"</h3>";
        }else{
          msg = "<h3>Ausência <span style='color: green;'>APROVADA !</span></h3>"+
          "<h3><b>Motivo:</b> "+ausencia.motivo+"</h3>"+
          "<h3><b>Período:</b> De "+dataDe.toLocaleDateString('pt-BR', options)+" Até "+dataAte.toLocaleDateString('pt-BR', options)+"</h3>"+
          "<hr><h3><b>Gestor:</b> "+gestorNome+"</h3>"+
          "<h3><b>Colaborador:</b> "+colaboradorNome+"</h3>"+
          "<h3><b>Observação:</b> "+ausencia.obs+"</h3>";
        }


      }else{
        msg = "<h3>Ausência <span style='color: red;'>REPROVADA !</span></h3>"+
        "<h3><b>Motivo:</b> "+ausencia.motivo+"</h3>"+
        "<h3><b>Período:</b> De "+dataDe.toLocaleDateString('pt-BR', options)+" Até "+dataAte.toLocaleDateString('pt-BR', options)+"</h3>"+
        "<hr><h3><b>Gestor:</b> "+gestorNome+"</h3>"+
        "<h3><b>Colaborador:</b> "+colaboradorNome+"</h3>"+
        "<h3><b>Observação:</b> "+ausencia.obs+"</h3>";
      }

      notificacao.msg = msg;
      notificacao.status = "P";


      this.crudService.salvar("notificacoes", notificacao);

      if(ausencia.subaprovacoes && ausencia.subaprovacoes.length > 0 && ausencia.status == 3){

        let count = 0;
        for(let sub of ausencia.subaprovacoes){
          if(sub.status == 1){
            count ++;
          }
        }

        if(count == ausencia.subaprovacoes.length){

          let notificacaoGestor = new Notificacao();
          notificacaoGestor.assunto = "Next Capacity - Solicitacao de Ausencia";

          for(var c=0; c<this.colaboradores.length; c++){

            if(this.colaboradores[c].id == ausencia.gestorDireto){
              notificacaoGestor.emailDestino = this.colaboradores[c].email;
            }
          }
          notificacaoGestor.link = "https://capacity.nextmobile.com.br/";

          var options = { year: 'numeric', month: '2-digit', day: '2-digit' };

          let ausenciaAny = JSON.parse(JSON.stringify(ausencia));
          let dataDe = new Date(ausenciaAny.periodoDe.seconds*1000);
          let dataAte = new Date(ausenciaAny.periodoAte.seconds*1000);

          let msg = "<h3><b>Colaborador:</b> "+colaboradorNome+"</h3>"+
                    "<h3><b>Motivo:</b> "+ausencia.motivo+"</h3>"+
                     "<h3><b>Período:</b> De "+dataDe.toLocaleDateString('pt-BR', options)+" Até "+dataAte.toLocaleDateString('pt-BR', options)+"</h3>";

          notificacaoGestor.msg = msg;
          notificacaoGestor.status = "P";
          notificacaoGestor.titulo = "Next Capacity - Solicitação de Ausência";

          this.crudService.salvar("notificacoes", notificacaoGestor);

        }
      }

  }

  ngOnDestroy(){
    if (this.movSubAusencia && this.movSubAusencia.closed == false){
      this.movSubAusencia.unsubscribe();
    }
  }

  hideModal(){
    this.modalaprovarausencia.hide();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  aprovacao(ausencia: any, _status: Number){

    if(ausencia.status == 0 || ausencia.status == 3){

      if(!ausencia.obs || (ausencia.obs && ausencia.obs == "")){
        this.toastr.warning('Por favor, informa uma observação!', 'Atenção!');
      }else{

        var text = "";
        if(_status == 1){
          text = "APROVAR";
        }else{
          text = "REPROVAR";
        }

        this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja '+text+' ?')
        .then(async (confirmed) =>{

          if(confirmed){

            let gestorNome = "";
            let idColaborador = "";

            if(ausencia.gestorDireto != this.usuarioLogado.colaboradorID){
              if(ausencia.subaprovacoes.length > 0){
                for(let s of ausencia.subaprovacoes){
                  if(s.gestorID == this.usuarioLogado.colaboradorID){

                    for(var c=0; c<this.colaboradores.length; c++){
                      if(this.colaboradores[c].id == s.gestorID){
                        gestorNome = this.colaboradores[c].nome;
                      }

                      if(this.colaboradores[c].email == ausencia.colaborador){
                        idColaborador = this.colaboradores[c].id;
                      }
                    }

                    if(s.status == 1){
                      this.toastr.warning('Ausência já APROVADA!', 'Atenção!');
                      return;
                    }else if(s.status == 2){
                      this.toastr.warning('Ausência já REPROVADA!', 'Atenção!');
                      return;
                    }

                    s.status = _status;
                    s.obs = ausencia.obs;

                    if(_status == 1){

                      if(ausencia.status == 0){
                        ausencia.status = 3;
                      }

                      ausencia.obs = "";
                    }else{
                      ausencia.status = 2;
                    }

                  }
                }
              }
            }else{

              for(var c=0; c<this.colaboradores.length; c++){
                if(this.colaboradores[c].id == ausencia.gestorDireto){
                  gestorNome = this.colaboradores[c].nome;
                }

                if(this.colaboradores[c].email == ausencia.colaborador){
                  idColaborador = this.colaboradores[c].id;
                }
              }

              ausencia.status = _status;
            }

            let dataDe = new Date(ausencia.periodoDe.seconds*1000);
            let dataAte = new Date(ausencia.periodoAte.seconds*1000);

            this.getSalvarCapacity(idColaborador,dataDe, dataAte, ausencia, _status, gestorNome);



          }

        })
        .catch(() => {
          console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
        });

      }

    }else{
      if(ausencia.status == 1){
        this.toastr.warning('Ausência já APROVADA!', 'Atenção!');
      }

      if(ausencia.status == 2){
        this.toastr.warning('Ausência já REPROVADA!', 'Atenção!');
      }

    }


  }

}



/*
import {Component, ViewChild, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import * as $ from 'jquery';
import { Ausencia } from '../../model/ausencia';
import { ToastrService } from 'ngx-toastr';
import { CrudService } from '../../services/crudService';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { Notificacao } from '../../model/notificacao';

@Component({
  selector: 'app-aprovar-ausencias',
  templateUrl: 'aprovar-ausencias.component.html'
})
export class AprovarAusenciasComponent implements OnInit{


  @ViewChild('modalaprovarausencia', {static: false}) public modalaprovarausencia: ModalDirective;

  sub: boolean = false;

  titulo: string = "Aprovar Ausências";
  movSubAusencia: Subscription;
  ausencias: any = [];
  movSubSalvar: Subscription;
  usuarioLogado: any;
  colaboradores: any[];
  capacity: any[];
  subCapacity: Subscription;

  constructor(
    private modalService: ModalService,
    private crudService: CrudService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService) {
  }

  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalAprovarAusencia.subscribe(
        (obj: any) => {

          this.usuarioLogado = obj.usuarioLogado;
          this.colaboradores = obj.colaboradores;

          this.getAusencias();

          this.limpaModal();

          this.modalaprovarausencia.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  getAusencias(){

    this.ausencias = [];

    if (this.movSubAusencia && this.movSubAusencia.closed == false){
      this.movSubAusencia.unsubscribe();
    }

    if (!this.movSubAusencia || this.movSubAusencia.closed){
       this.movSubAusencia = this.crudService.findByGeneric("ausencia", "gestorDireto", this.usuarioLogado.colaboradorID).subscribe(
        (result: any) =>{

          for(let a of result){
            if(a.subaprovacoes && a.subaprovacoes.length > 0){

              let aprovacaoPendente = false;

              for(let s of a.subaprovacoes){

                var existeGestor = false;
                for(let col of this.colaboradores){
                  if(s.gestorID == col.id){
                    existeGestor = true;
                  }
                }

                //se nao existir gestor, o mesmo esta desativado pois ja nao trabalha na empresa, entao APROVADO AUTOMATICA A SUBAPROVACAO
                if(existeGestor == false){
                  s.status = 1;
                  this.crudService.atualizar("ausencia", a);
                }

                if(s.status != 1){
                  aprovacaoPendente = true;
                }
              }

              if(aprovacaoPendente == false){
                this.ausencias.push(a);
              }
            }else{
              this.ausencias.push(a);
            }
          }

          this.getAusenciasSubAprovacoes();
       },error=>{
            console.log(error);
       });

    }

  }

  getAusenciasSubAprovacoes(){

    if (this.movSubAusencia && this.movSubAusencia.closed == false){
      this.movSubAusencia.unsubscribe();
    }

    if (!this.movSubAusencia || this.movSubAusencia.closed){
       this.movSubAusencia = this.crudService.findByGeneric("ausencia", null, null).subscribe(
        (ausencias: Ausencia[]) =>{

         for(let a of ausencias){
          if(a.subaprovacoes && a.subaprovacoes.length > 0){
            for(let s of a.subaprovacoes){
              if(s.gestorID == this.usuarioLogado.colaboradorID){
                this.ausencias.push(a);
              }
            }
          }
         }

       },error=>{
            console.log(error);
       });

    }

  }

  getSalvarCapacity(idColaborador, periodoDe, periodoAte, ausencia, _status, gestorNome){

    this.capacity = [];

    if (this.subCapacity && this.subCapacity.closed == false){
      this.subCapacity.unsubscribe();
    }

    if (!this.subCapacity || this.subCapacity.closed){

      this.subCapacity = this.crudService.listar("capacity", null, null).subscribe(
        (result: any) =>{

          this.capacity = [];

          for(let r of result){
            let data = new Date( r.data.seconds*1000);

            if( data >= periodoDe &&  data <= periodoAte){
              for(let d of r.detalhes){
                if(d.colaborador == idColaborador && d.gestor == this.usuarioLogado.email){
                  this.capacity.push(d);
                }
              }
            }
          }

          if(_status == 1){
            if(this.capacity.length == 0){
              this.salvar(ausencia, _status, gestorNome)
            }else{
              this.toastr.error('Ainda existem capacity registrado nesse período, necessario a exclusão! Favor comunicar os gestores responsaveis!', 'Atenção!');
            }
          }else{
            this.salvar(ausencia, _status, gestorNome)
          }

          this.subCapacity.unsubscribe();
       },error=>{
            console.log(error);
       });

    }

  }

  salvar(ausencia: any, _status: any, gestorNome: string){

      this.crudService.atualizar("ausencia", ausencia);
      this.getAusencias();

      if(_status == 1){
        this.toastr.success('Ausência aprovada com sucesso!', 'Sucesso!');
      }else{
        this.toastr.warning('Ausência foi reprovada!', 'Atenção!');
      }

      let notificacao = new Notificacao();

      if(_status == 1){
        notificacao.assunto = "Next Capacity - Aprovacao de Ausencia";
        notificacao.titulo = "Next Capacity - Aprovação de Ausência";
      }else{
        notificacao.assunto = "Next Capacity - Reprovacao de Ausencia";
        notificacao.titulo = "Next Capacity - Reprovação de Ausência";
      }

      notificacao.emailDestino = ausencia.colaborador;
      notificacao.link = "https://capacity.nextmobile.com.br/";

      var options = { year: 'numeric', month: '2-digit', day: '2-digit' };

      let ausenciaAny = JSON.parse(JSON.stringify(ausencia));

      let dataDe = new Date(ausenciaAny.periodoDe.seconds*1000);
      let dataAte = new Date(ausenciaAny.periodoAte.seconds*1000);

      let colaboradorNome = "";
      for(var c=0; c<this.colaboradores.length; c++){
        if(this.colaboradores[c].email == ausencia.colaborador){
          colaboradorNome = this.colaboradores[c].nome;
        }
      }

      let msg = "";
      if(_status == 1){

        if(ausencia.status == 3){
          msg = "<h3>Ausência <span style='color: green;'>PARCIALMENTE APROVADA !</span></h3>"+
          "<h3><b>Motivo:</b> "+ausencia.motivo+"</h3>"+
          "<h3><b>Período:</b> De "+dataDe.toLocaleDateString('pt-BR', options)+" Até "+dataAte.toLocaleDateString('pt-BR', options)+"</h3>"+
          "<hr><h3><b>Gestor:</b> "+gestorNome+"</h3>"+
          "<h3><b>Colaborador:</b> "+colaboradorNome+"</h3>"+
          "<h3><b>Observação:</b> "+ausencia.obs+"</h3>";
        }else{
          msg = "<h3>Ausência <span style='color: green;'>APROVADA !</span></h3>"+
          "<h3><b>Motivo:</b> "+ausencia.motivo+"</h3>"+
          "<h3><b>Período:</b> De "+dataDe.toLocaleDateString('pt-BR', options)+" Até "+dataAte.toLocaleDateString('pt-BR', options)+"</h3>"+
          "<hr><h3><b>Gestor:</b> "+gestorNome+"</h3>"+
          "<h3><b>Colaborador:</b> "+colaboradorNome+"</h3>"+
          "<h3><b>Observação:</b> "+ausencia.obs+"</h3>";
        }


      }else{
        msg = "<h3>Ausência <span style='color: red;'>REPROVADA !</span></h3>"+
        "<h3><b>Motivo:</b> "+ausencia.motivo+"</h3>"+
        "<h3><b>Período:</b> De "+dataDe.toLocaleDateString('pt-BR', options)+" Até "+dataAte.toLocaleDateString('pt-BR', options)+"</h3>"+
        "<hr><h3><b>Gestor:</b> "+gestorNome+"</h3>"+
        "<h3><b>Colaborador:</b> "+colaboradorNome+"</h3>"+
        "<h3><b>Observação:</b> "+ausencia.obs+"</h3>";
      }

      notificacao.msg = msg;
      notificacao.status = "P";


      this.crudService.salvar("notificacoes", notificacao);

      if(ausencia.subaprovacoes && ausencia.subaprovacoes.length > 0 && ausencia.status == 3){

        let count = 0;
        for(let sub of ausencia.subaprovacoes){
          if(sub.status == 1){
            count ++;
          }
        }

        if(count == ausencia.subaprovacoes.length){

          let notificacaoGestor = new Notificacao();
          notificacaoGestor.assunto = "Next Capacity - Solicitacao de Ausencia";

          for(var c=0; c<this.colaboradores.length; c++){

            if(this.colaboradores[c].id == ausencia.gestorDireto){
              notificacaoGestor.emailDestino = this.colaboradores[c].email;
            }
          }
          notificacaoGestor.link = "https://capacity.nextmobile.com.br/";

          var options = { year: 'numeric', month: '2-digit', day: '2-digit' };

          let ausenciaAny = JSON.parse(JSON.stringify(ausencia));
          let dataDe = new Date(ausenciaAny.periodoDe.seconds*1000);
          let dataAte = new Date(ausenciaAny.periodoAte.seconds*1000);

          let msg = "<h3><b>Colaborador:</b> "+colaboradorNome+"</h3>"+
                    "<h3><b>Motivo:</b> "+ausencia.motivo+"</h3>"+
                     "<h3><b>Período:</b> De "+dataDe.toLocaleDateString('pt-BR', options)+" Até "+dataAte.toLocaleDateString('pt-BR', options)+"</h3>";

          notificacaoGestor.msg = msg;
          notificacaoGestor.status = "P";
          notificacaoGestor.titulo = "Next Capacity - Solicitação de Ausência";

          this.crudService.salvar("notificacoes", notificacaoGestor);

        }
      }

  }

  ngOnDestroy(){
    if (this.movSubAusencia && this.movSubAusencia.closed == false){
      this.movSubAusencia.unsubscribe();
    }
  }

  hideModal(){
    this.modalaprovarausencia.hide();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  aprovacao(ausencia: any, _status: Number){

    if(ausencia.status == 0 || ausencia.status == 3){

      if(!ausencia.obs || (ausencia.obs && ausencia.obs == "")){
        this.toastr.warning('Por favor, informa uma observação!', 'Atenção!');
      }else{

        var text = "";
        if(_status == 1){
          text = "APROVAR";
        }else{
          text = "REPROVAR";
        }

        this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja '+text+' ?')
        .then(async (confirmed) =>{

          if(confirmed){

            let gestorNome = "";
            let idColaborador = "";

            if(ausencia.gestorDireto != this.usuarioLogado.colaboradorID){
              if(ausencia.subaprovacoes.length > 0){
                for(let s of ausencia.subaprovacoes){
                  if(s.gestorID == this.usuarioLogado.colaboradorID){

                    for(var c=0; c<this.colaboradores.length; c++){
                      if(this.colaboradores[c].id == s.gestorID){
                        gestorNome = this.colaboradores[c].nome;
                      }

                      if(this.colaboradores[c].email == ausencia.colaborador){
                        idColaborador = this.colaboradores[c].id;
                      }
                    }

                    if(s.status == 1){
                      this.toastr.warning('Ausência já APROVADA!', 'Atenção!');
                      return;
                    }else if(s.status == 2){
                      this.toastr.warning('Ausência já REPROVADA!', 'Atenção!');
                      return;
                    }

                    s.status = _status;
                    s.obs = ausencia.obs;

                    if(_status == 1){

                      if(ausencia.status == 0){
                        ausencia.status = 3;
                      }

                      ausencia.obs = "";
                    }else{
                      ausencia.status = 2;
                    }

                  }
                }
              }
            }else{

              for(var c=0; c<this.colaboradores.length; c++){
                if(this.colaboradores[c].id == ausencia.gestorDireto){
                  gestorNome = this.colaboradores[c].nome;
                }

                if(this.colaboradores[c].email == ausencia.colaborador){
                  idColaborador = this.colaboradores[c].id;
                }
              }

              ausencia.status = _status;
            }

            let dataDe = new Date(ausencia.periodoDe.seconds*1000);
            let dataAte = new Date(ausencia.periodoAte.seconds*1000);

            this.getSalvarCapacity(idColaborador,dataDe, dataAte, ausencia, _status, gestorNome);



          }

        })
        .catch(() => {
          console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
        });

      }

    }else{
      if(ausencia.status == 1){
        this.toastr.warning('Ausência já APROVADA!', 'Atenção!');
      }

      if(ausencia.status == 2){
        this.toastr.warning('Ausência já REPROVADA!', 'Atenção!');
      }

    }


  }

}
*/
