import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { CentroDeCusto } from '../../../model/centrodecusto';
import { NgForm } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-detalhes-centrodecusto',
  templateUrl: 'centrodecusto-detalhes.component.html'
})
export class CentroDeCustoDetalhesComponent implements OnInit{

  @ViewChild('modalcentrodecusto', {static: false}) public modalcentrodecusto: ModalDirective;

  sub: boolean = false;
  _centrodecusto: CentroDeCusto = new CentroDeCusto();
  @Output() loadcentrodecustos = new EventEmitter();
  titulo: string = "Novo Centro de Custo";
  clientes: any[];
  gestores: any[];
  vertical: any[];

  movSub: Subscription;
  movSubSalvar: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CrudService) {
  }

  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesCentroDeCusto.subscribe(
        (p: any) => {

          if(p.centrodecusto == null){
            this.titulo = "Novo Centro de Custo"
            this._centrodecusto = new CentroDeCusto();
          }else{
            this.titulo = "Editar Centro de Custo"
            this._centrodecusto = p.centrodecusto;
            debugger;
          }

          this.clientes = p.clientes;
          this.vertical = p.vertical;
          this.gestores = p.gestores;
          this.limpaModal();

          this.modalcentrodecusto.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  hideModal(){
    this.modalcentrodecusto.hide();
    this.loadcentrodecustos.emit();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this._centrodecusto.id){
      this.atualizar();
    }else{
      this.salvar();
    }

    form.reset();
  }

  salvar(){

    let objCentrocusto = JSON.parse(JSON.stringify(this._centrodecusto));

    if (this.movSubSalvar && this.movSubSalvar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed){

      this.movSubSalvar = this.cadastroService.findByGeneric("centrodecusto", "descricaoMin", this._centrodecusto.descricaoMin).subscribe(
        (result: any) =>{

          if(result.length > 0){
            this.toastr.error('Este código já esta sendo utilizado por outro centro de custo!', 'Atenção!');
            this.hideModal();
          }else{

            this.cadastroService.salvar("centrodecusto", objCentrocusto);

            this.toastr.success('Centro de custo cadastrado com sucesso!', 'Sucesso!');

            this.hideModal();

            this.movSubSalvar.unsubscribe();
          }

       },error=>{
            console.log(error);
            this.hideModal();
       });

    }

  }

  atualizar(){

    console.log("...Atualizar")

    let objCentrocusto = JSON.parse(JSON.stringify(this._centrodecusto));

    if (this.movSubSalvar && this.movSubSalvar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed){

      this.movSubSalvar = this.cadastroService.findByGeneric("centrodecusto", "descricaoMin", this._centrodecusto.descricaoMin).subscribe(
        (result: any) =>{

          if(result.length > 0){
            if(result[0].descricaoMin == objCentrocusto.descricaoMin){

              this.cadastroService.atualizar("centrodecusto", objCentrocusto);

              this.toastr.success('Centro de custo atualizado com sucesso!', 'Sucesso!');

              this.hideModal();

              this.movSubSalvar.unsubscribe();
            }
          }

       },error=>{
            console.log(error);
            this.hideModal();
       });

    }

  }

}
