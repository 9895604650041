import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Risco } from '../../../model/risco';
import { NgForm } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';
 
@Component({
  selector: 'app-detalhes-riscos',
  templateUrl: 'riscos-detalhes.component.html'
})   
export class RiscosDetalhesComponent implements OnInit{
      
  @ViewChild('modalriscos', {static: false}) public modalriscos: ModalDirective;
  
  sub: boolean = false;
  _risco: Risco = new Risco();
  @Output() loadriscos = new EventEmitter();
  titulo: string = "Novo risco";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  
  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CrudService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesRiscos.subscribe(
        (p: Risco) => {
         
          if(p == null){
            this.titulo = "Novo Risco"
            this._risco = new Risco();
          }else{
            this.titulo = "Editar Risco"
            this._risco = p;
          }

          this.limpaModal();

          this.modalriscos.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  hideModal(){
    this.modalriscos.hide();
    this.loadriscos.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this._risco.id){
      this.atualizar();
    }else{
      this.salvar();
    }
      
    form.reset();
  }

  salvar(){

    let objriscos = JSON.parse(JSON.stringify(this._risco));

    if (this.movSubSalvar && this.movSubSalvar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed){
     
      this.movSubSalvar = this.cadastroService.findByGeneric("riscos", "descricao", this._risco.descricao).subscribe(
        (result: any) =>{

          if(result.length > 0){
            this.toastr.error('Este risco já esta sendo utilizado!', 'Atenção!');
            this.hideModal();
          }else{
            this.cadastroService.salvar("riscos", objriscos);

            this.toastr.success('Risco cadastrado com sucesso!', 'Sucesso!');
         
            this.hideModal();

            this.movSubSalvar.unsubscribe();
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }
 
 
   
  } 

  atualizar(){

    let objriscos = JSON.parse(JSON.stringify(this._risco));
    
    if (this.movSubAlterar && this.movSubAlterar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubAlterar || this.movSubAlterar.closed){
     
      this.movSubAlterar = this.cadastroService.findByGeneric("riscos", "descricao", this._risco.descricao).subscribe(
        (result: any) =>{

          if(result.length > 0){
            if(result[0].cnpj == objriscos.cnpj){
              this.cadastroService.atualizar("riscos", objriscos);

              this.toastr.success('Risco atualizado com sucesso!', 'Sucesso!');
           
              this.hideModal();
            
              this.movSubAlterar.unsubscribe();
            }
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }
    
  }
}
