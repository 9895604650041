import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { AlterarSenha } from '../../model/alterarSenha';
import { AuthService } from '../../auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: 'alterar-senha.component.html'
})    
export class AlterarSenhaComponent implements OnInit{
 
    
  @ViewChild('modalAlterarSenha', {static: false}) public modalAlterarSenha: ModalDirective;
  
  sub: boolean = false;

  titulo: string = "Alterar Senha";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  _senha: AlterarSenha;

  constructor(
    private modalService: ModalService,
    private authService: AuthService,
    private toastr: ToastrService,) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalAlterarSenha.subscribe(
        (u: any) => {
         
          this._senha = new AlterarSenha();
          this._senha.confirmarsenha = "";
          this._senha.novasenha = "";
          this.limpaModal();

          this.modalAlterarSenha.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  hideModal(){
    this.modalAlterarSenha.hide();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return; 
    }

    if(this._senha.novasenha != this._senha.confirmarsenha){
      this.toastr.warning("Senhas não conferem, confirme sua nova senha!");
    }else{
      this.salvar();
    }

    form.reset();
  }

  salvar(){
 
    this.authService.alteraSenha(this._senha.novasenha).then(() => {
      this.toastr.success("Senha alterada com sucesso!");
    }, (error) => { 
      console.log(error);
      this.toastr.error("Não foi possivel alterar a senha!");
    });   
  } 

  
}
