import { Directive, Input , ElementRef, Renderer2, OnInit, RendererStyleFlags2} from '@angular/core';
import { Colaborador } from '../model/colaborador';
import { CapacityDetalhes } from '../model/capacityDetalhes';

@Directive({
  selector: '[appColaboradorBadge]'
})
export class ColaboradorBadgeDirective implements OnInit{
    
  @Input() detalhe: CapacityDetalhes;
  @Input() id: string;
  @Input() colaboradores: any[]; 
  @Input() nome: boolean;

  constructor(private elRef: ElementRef, private render: Renderer2){

  } 
 
  ngOnInit(){

    if(this.id){
      for(var i = 0; i < this.colaboradores.length; i++){

        if(this.colaboradores[i].id == this.id){
  
          const text = this.nome == true ? this.render.createText(this.colaboradores[i].nome) : this.render.createText(this.colaboradores[i].sigla);
    
          this.render.appendChild(this.elRef.nativeElement, text);
          this.render.addClass(this.elRef.nativeElement, "badge");
          this.render.addClass(this.elRef.nativeElement, "badge-danger");
          this.render.setStyle(this.elRef.nativeElement, "background-color", this.colaboradores[i].cor, RendererStyleFlags2.Important);
        }
      }
    }else{
      for(var i = 0; i < this.colaboradores.length; i++){

        if(this.colaboradores[i].id == this.detalhe.colaborador){
  
          const text = this.nome == true ? this.render.createText(this.colaboradores[i].nome) : this.render.createText(this.detalhe.horas + "-" +this.colaboradores[i].sigla);
     
          this.render.appendChild(this.elRef.nativeElement, text);
          this.render.addClass(this.elRef.nativeElement, "cal-day-badge");
          this.render.setStyle(this.elRef.nativeElement, "background-color", this.colaboradores[i].cor, RendererStyleFlags2.Important);
        }
      }
    }
   

  }
    
 

}

