import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { Ausencia } from '../../model/ausencia';
import { AuthService } from '../../auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { CrudService } from '../../services/crudService';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-ausencias',
  templateUrl: 'ausencias.component.html'
})    
export class AusenciasComponent implements OnInit{
  
    
  @ViewChild('modalausencia', {static: false}) public modalausencia: ModalDirective;
  
  sub: boolean = false;

  titulo: string = " Ausência";
  movSubAusencia: Subscription;
  ausencias: any = [];
  movSubSalvar: Subscription;
  usuarioLogado: any;
  colaboradores: any[];
  movSubCol: Subscription;

  constructor(
    private modalService: ModalService,
    private crudService: CrudService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalAusencia.subscribe(
        (u: any) => {
         
          this.usuarioLogado = u;
          this.getColaboradores();
          
          this.limpaModal();

          this.modalausencia.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  async getColaboradores(){
 
    this.colaboradores = [];

    if (this.movSubCol && this.movSubCol.closed == false){
      this.movSubCol.unsubscribe();
    } 

    if (!this.movSubCol || this.movSubCol.closed){

      this.movSubCol = this.crudService.listar("colaborador", null, null).subscribe(
        (result: any) =>{

          this.colaboradores =  result;
          this.getAusencias();
       },error=>{
            console.log(error);
       });

    }
    

  }

  getAusencias(){

    this.ausencias = [];

    if (this.movSubAusencia && this.movSubAusencia.closed == false){
      this.movSubAusencia.unsubscribe();
    }

    if (!this.movSubAusencia || this.movSubAusencia.closed){
       this.movSubAusencia = this.crudService.findByGeneric("ausencia", "colaborador", this.usuarioLogado.email).subscribe(
        (result: any) =>{

          this.ausencias =  result;
          this.movSubAusencia.unsubscribe();
       },error=>{
            console.log(error);
       });

    }

  }

  ngOnDestroy(){
    if (this.movSubAusencia && this.movSubAusencia.closed == false){
      this.movSubAusencia.unsubscribe();
    }
  }

  hideModal(){
    this.modalausencia.hide();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  excluir(ausencia: Ausencia){
    
    if(ausencia.status == 0){
      this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          this.crudService.excluir("ausencia", ausencia.id);
          this.getAusencias();  
          this.toastr.success('Ausencia excluída com sucesso!', 'Sucesso!');
           
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    }else{
      this.toastr.error('Não é possível excluir ausência aprovada ou reprovada!', 'Atenção!');
    }
    
    
  }

}
