// Angular
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Dropdowns Component
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Components Routing
import { ComponentsRoutingModule } from './components-routing.module';
import { TableModule } from 'primeng/table';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from '../auth/auth-interceptor.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgxDropzoneModule } from 'ngx-dropzone';
import DirectivesModule from '../directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsRoutingModule,
    BsDropdownModule.forRoot(),
    TableModule,
    NgxSpinnerModule,
    ModalModule.forRoot(),
    AngularFireAuthModule,
    NgxDropzoneModule,
    DirectivesModule 
  ],
  declarations: [
    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
})
export class ComponentsModule { }
