import {Component, ViewChild, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { Ausencia } from '../../model/ausencia';
import { ToastrService } from 'ngx-toastr';
import { CrudService } from '../../services/crudService';
import { Notificacao } from '../../model/notificacao';

@Component({
  selector: 'app-solicitar-ausencia',
  templateUrl: 'solicitar-ausencia.component.html'
})    
export class SolicitarAusenciaComponent implements OnInit{
  
    
  @ViewChild('modalsolicitarausencia', {static: false}) public modalsolicitarausencia: ModalDirective;
  
  sub: boolean = false;

  titulo: string = "* Solicitar Ausência *";
  movSubMotivo: Subscription;
  movSubCC: Subscription;
  _ausencia: Ausencia = new Ausencia();
  motivos: any = [];
  colaboradores: any = [];
  movSubSalvar: Subscription;
  usuarioLogado: any;
  capacity: any[];
  subCapacity: Subscription;
  centrodecustos: any[];

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private crudService: CrudService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalSolicitarAusencia.subscribe(
        (u: any) => {
         
          this.usuarioLogado = u.usuarioLogado;
          this.colaboradores = u.colaboradores;
          this.getMotivos();
          this.getcentrodecustos();
          this.limpaModal();

          this.modalsolicitarausencia.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  async getcentrodecustos(){
 
    this.centrodecustos = [];

    if (this.movSubCC && this.movSubCC.closed == false){
      this.movSubCC.unsubscribe();
    }

    if (!this.movSubCC || this.movSubCC.closed){

      this.movSubCC = this.crudService.listar("centrodecusto", null, null).subscribe(
        (result: any) =>{

          this.centrodecustos =  result;
  
       },error=>{
            console.log(error);
       });

    }
    

  }

  getMotivos(){

    this.motivos = [];

    if (this.movSubMotivo && this.movSubMotivo.closed == false){
      this.movSubMotivo.unsubscribe();
    }

    if (!this.movSubMotivo || this.movSubMotivo.closed){
       this.movSubMotivo = this.crudService.listar("motivo", null, null).subscribe(
        (result: any) =>{

          this.motivos =  result;
  
       },error=>{
            console.log(error);
       });

    }

  }

  ngOnDestroy(){
    if (this.movSubMotivo && this.movSubMotivo.closed == false){
      this.movSubMotivo.unsubscribe();
    }
  }

  hideModal(){
    this.modalsolicitarausencia.hide();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return; 
    }

    let objAusencia = JSON.parse(JSON.stringify(this._ausencia));

    let splitDateDe = objAusencia.periodoDe.split("-");
    let datePeriodoDe : Date = new Date(splitDateDe[0], splitDateDe[1]-1, splitDateDe[2], 0, 0, 0);
    
    let splitDateAte = objAusencia.periodoAte.split("-");
    let datePeriodoAte : Date = new Date(splitDateAte[0], splitDateAte[1]-1, splitDateAte[2], 0, 0, 0);

    if(objAusencia.horas <= 0 || objAusencia.horas > 8){
      this.toastr.warning("A quantidade de horas deve estar entre 1 a 8.")
    }else if(splitDateAte < splitDateDe){
      this.toastr.warning("Periodo (ATÉ) não pode ser menor que Período (DE).")
    }else{
      objAusencia.periodoDe = datePeriodoDe;
      objAusencia.periodoAte = datePeriodoAte;
      objAusencia.status = 0;
      objAusencia.colaborador = this.usuarioLogado.email;
      objAusencia.gestorDireto = this.usuarioLogado.gestorDireto;
      
      this.salvarGetCapacity(objAusencia)
    }

    form.reset();
  }

  salvarGetCapacity(objAusencia: any){

    this.capacity = [];

    if (this.subCapacity && this.subCapacity.closed == false){
      this.subCapacity.unsubscribe();
    }

    if (!this.subCapacity || this.subCapacity.closed){
     
      this.subCapacity = this.crudService.listar("capacity", null, null).subscribe(
        (result: any) =>{

          this.capacity = [];

          for(let r of result){
            let data = new Date( r.data.seconds*1000);

            if( data >= objAusencia.periodoDe &&  data <= objAusencia.periodoAte){
              for(let d of r.detalhes){
                if(d.colaborador == this.usuarioLogado.colaboradorID){
                  this.capacity.push(r);
                }
              }
            }  
          }

          this.salvar(objAusencia);
         
       },error=>{
            console.log(error);
       });

    }
    
  }

  salvar(objAusencia: any){
 
    if (this.movSubSalvar && this.movSubSalvar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed){
     
      this.movSubSalvar = this.crudService.findByGeneric("ausencia", "colaborador", this.usuarioLogado.email ).subscribe(
        (result: any) =>{

          let existeAusencia = false;
          if(result.length > 0){

            for(var a = 0; a < result.length; a++){

              let dataDe = new Date( result[a].periodoDe.seconds*1000);
              let dataAte = new Date( result[a].periodoAte.seconds*1000);

              if( dataDe >= objAusencia.periodoDe &&  dataAte <= objAusencia.periodoAte && result[a].status == 1){
                existeAusencia = true;
              } 

            }
            
          }

          if(existeAusencia == false){

            if(this.capacity.length > 0){

              let subaprovacoes = [];
              //ja existe capacity durante o periodo de ausencia, então precisar da aprovacao dos gestores do centro de custo

              for(let d of this.capacity){

                  let idGestor = "";
                  let emailGestor = "";

                  for(var cc=0; cc<this.centrodecustos.length; cc++){      
                    if(this.centrodecustos[cc].id == d.centrodecusto){
                      idGestor = this.centrodecustos[cc].responsavel;
                    }
                  }

                  for(var c=0; c<this.colaboradores.length; c++){      
                    if(this.colaboradores[c].id == idGestor){
                      emailGestor = this.colaboradores[c].email;
                    }
                  }

                  if(idGestor != objAusencia.gestorDireto){
                    let subAprovacao = {
                      "gestorID" : idGestor,
                      "gestorEmail" : emailGestor,
                      "status" : 0
                    }
                    
                    subaprovacoes.push(subAprovacao);
                  }
                  
              }

              subaprovacoes = subaprovacoes.filter(function (a) {
                return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
              }, Object.create(null))

              objAusencia.subaprovacoes = subaprovacoes;

            }else{
              objAusencia.subaprovacoes = [];
            }
            
            this.crudService.salvar("ausencia", objAusencia);

            if(objAusencia.subaprovacoes.length > 0){

              for(let sub of objAusencia.subaprovacoes){

                let notificacao = new Notificacao();
                notificacao.assunto = "Next Capacity - Solicitacao de Ausencia";
    
                let colaboradorNome = "";
                for(var c=0; c<this.colaboradores.length; c++){
                  if(this.colaboradores[c].email == objAusencia.colaborador){
                    colaboradorNome = this.colaboradores[c].nome;
                  }
                }

                notificacao.emailDestino = sub.gestorEmail;
                
                notificacao.link = "https://capacity.nextmobile.com.br/";
    
                var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    
                let msg = "<h3><b>Colaborador:</b> "+colaboradorNome+"</h3>"+
                          "<h3><b>Motivo:</b> "+objAusencia.motivo+"</h3>"+
                          "<h3><b>Período:</b> De "+objAusencia.periodoDe.toLocaleDateString('pt-BR', options)+" Até "+objAusencia.periodoAte.toLocaleDateString('pt-BR', options)+"</h3>";
    
                notificacao.msg = msg;
                notificacao.status = "P";
                notificacao.titulo = "Next Capacity - Solicitação de Ausência ***";
    
                this.crudService.salvar("notificacoes", notificacao);

                console.log("notificacao.msg 0", notificacao.msg)
    
              }

             

              this.toastr.success('Ausencia solicitada com sucesso!', 'Sucesso!');
            }else{
              let notificacao = new Notificacao();
              notificacao.assunto = "Next Capacity - Solicitacao de Ausencia";
  
              let colaboradorNome = "";
              for(var c=0; c<this.colaboradores.length; c++){
                if(this.colaboradores[c].email == objAusencia.colaborador){
                  colaboradorNome = this.colaboradores[c].nome;
                }

                
  
                if(this.colaboradores[c].id == objAusencia.gestorDireto){
                  notificacao.emailDestino = this.colaboradores[c].email;
                  console.log("### notificacao.emailDestino ", notificacao.emailDestino)

                }
              }
              notificacao.link = "https://capacity.nextmobile.com.br/";
  
              var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  
              let msg = "<h3><b>Colaborador:</b> "+colaboradorNome+"</h3>"+
                        "<h3><b>Motivo:</b> "+objAusencia.motivo+"</h3>"+
                        "<h3><b>Período:</b> De "+objAusencia.periodoDe.toLocaleDateString('pt-BR', options)+" Até "+objAusencia.periodoAte.toLocaleDateString('pt-BR', options)+"</h3>";
  
              notificacao.msg = msg;
              notificacao.status = "P";
              notificacao.titulo = "Next Capacity - Solicitação de Ausência";
  
              this.crudService.salvar("notificacoes", notificacao);

              console.log("notificacao.msg ", notificacao.msg)
              console.log("**********************************")
  
              this.toastr.success('Ausencia solicitada com sucesso!', 'Sucesso!');
            }
            
        
            this.hideModal();
            this.subCapacity.unsubscribe();
            this.movSubSalvar.unsubscribe();
          }else{
            this.toastr.error('Já existe ausencia cadastrada para esse periodo!', 'Atenção!');
            this.hideModal();
          }
           
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }
    
  }
  
}
