// Angular
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Dropdowns Component
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Components Routing
import { TableModule } from 'primeng/table';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AlocacaoComponent } from './alocacao/alocacao.component';
import { AuthInterceptorService } from '../auth/auth-interceptor.service';
import { RelatoriosRoutingModule } from './relatorios-routing.module';
import DirectivesModule from '../directives/directives.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AusenciasComponent } from './ausencias/ausencias.component';
import { ControleProjetosComponent } from './controle-projetos/controle-projetos.component';
import { FaturamentoAnualComponent } from './faturamento-anual/faturamento-anual.component';
import { FaturamentoCompletoComponent } from './faturamento-completo/faturamento-completo.component';
import { DropdownModule } from 'primeng/primeng';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RelatoriosRoutingModule,
    BsDropdownModule.forRoot(),
    TableModule,
    NgxSpinnerModule,
    ModalModule.forRoot(),
    AngularFireAuthModule,
    NgxDropzoneModule,
    DirectivesModule,
    CollapseModule,
    DropdownModule

  ],
  declarations: [
    AlocacaoComponent,
    AusenciasComponent,
    ControleProjetosComponent,
    FaturamentoAnualComponent,
	FaturamentoCompletoComponent


  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ]
})
export class RelatoriosModule { }



/*
// Angular
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Dropdowns Component
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Components Routing
import { TableModule } from 'primeng/table';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AlocacaoComponent } from './alocacao/alocacao.component';
import { AuthInterceptorService } from '../auth/auth-interceptor.service';
import { RelatoriosRoutingModule } from './relatorios-routing.module';
import DirectivesModule from '../directives/directives.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AusenciasComponent } from './ausencias/ausencias.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RelatoriosRoutingModule,
    BsDropdownModule.forRoot(),
    TableModule,
    NgxSpinnerModule,
    ModalModule.forRoot(),
    AngularFireAuthModule,
    NgxDropzoneModule,
    DirectivesModule,
    CollapseModule
  ],
  declarations: [
    AlocacaoComponent,
    AusenciasComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ]
})
export class RelatoriosModule { }
*/
