import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { ToastrModule } from 'ngx-toastr';
import { ComponentsModule } from './components/components.module';
import { HttpClientModule } from '@angular/common/http';
import { AuthComponent } from './auth/auth.component';
import { FormsModule } from '@angular/forms';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { FirebaseServiceProvider, firebaseInit } from './providers/firebase-service';
import { CadastrosModule } from './cadastros/cadastros.module';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AlterarSenhaComponent } from './components/alterar-senha/alterar-senha.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SolicitarAusenciaComponent } from './components/solicitar-ausencia/solicitar-ausencia.component';
import { AusenciasComponent } from './components/ausencias/ausencias.component';
import { TableModule } from 'primeng/table';
import DirectivesModule from './directives/directives.module';
import { AprovarAusenciasComponent } from './components/aprovar-ausencias/aprovar-ausencias.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { RelatoriosModule } from './relatorios/relatorios.module';
import { GraficosModule } from './views/graficos/graficos.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    ChartsModule,
    AngularFirestoreModule ,
    ToastrModule.forRoot(), // ToastrModule added
    ComponentsModule,
    CadastrosModule,
    RelatoriosModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    StorageServiceModule,
    ModalModule.forRoot(),
    TableModule,
    DirectivesModule,
    AngularFireStorageModule,
    GraficosModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    RegisterComponent,
    AuthComponent,
    LoadingSpinnerComponent,
    ConfirmationDialogComponent,
    AlterarSenhaComponent,
    SolicitarAusenciaComponent,
    AusenciasComponent,
    AprovarAusenciasComponent
  ],
  providers: [
    FirebaseServiceProvider,
    { provide: APP_INITIALIZER, useFactory: firebaseInit, deps: [FirebaseServiceProvider], multi: true },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [ AppComponent ],
  entryComponents: [ConfirmationDialogComponent]
})
export class AppModule { }
