import { CrudService } from '../../services/crudService';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import * as xlsx from 'xlsx';
import { EventoCapacity } from '../../model/eventoCapacity';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-faturamento-anual',
  templateUrl: './faturamento-anual.component.html',
  styleUrls: ['./faturamento-anual.component.scss']
})
export class FaturamentoAnualComponent implements OnInit {

  @ViewChild('tabela', { static: false }) tabela;
  movSubFaturamento: Subscription;
  movSubFaturamentoDados: Subscription;
  movSubFaturamentoAnual: Subscription;
  movSubColab: Subscription;
  faturamentoanualresumo: any[] = [];
  faturamentoanual: any[] = [];
  faturamentoresumo: any[] = [];
  colaboradores: any[] = [];
  frozenCols: any[] = [];
  faturamentodados: any[] = [];
  tipoRel: string = 'H';
  relTipo: string;
  faturamentoanualcalc: any[] = [];
  faturamentoanualcalcfiltro: any[] = [];
  clientes: any[] = [];
  public centro: any[] = [];
  comercial: any[] = [];
  area: any[] = [];
  comissao: any[] = [];
  faturado: any[] = [];
  _eventoCapacity: EventoCapacity = new EventoCapacity();
  strOp: string;
  cliente: string
  areaS: string;
  comercialS: string;
  comissaoS: string;
  dataInicio: string;
  permissao: boolean;
  referencia: number;
  data = new Date();

  constructor(
    private crudService: CrudService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.faturamentoanualresumo = [];
    this.faturamentoanual = [];
    this._eventoCapacity.cliente = "Todos";
    this._eventoCapacity.centrodecusto = "Todos";
    this._eventoCapacity.comercial = "Todos";
    this._eventoCapacity.area = "Todos";
    this._eventoCapacity.comissao = "Todos";
    this.faturamentoanualcalcfiltro = [];
    this.faturamentoanualcalc = [];
    this.getcolaboradores();

    console.log(" this._eventoCapacity ", this._eventoCapacity)

    /*
   this.getFaturamentoDados();
   this.getFaturamentoAnual();
   this.getFaturamentoAnualResumo();
   */

  }


  async getcolaboradores() {

    if (this.movSubColab && this.movSubColab.closed == false) {
      this.movSubColab.unsubscribe();
    }
    if (!this.movSubColab || this.movSubColab.closed) {
      this.movSubColab = this.crudService.listar("colaborador", null, null).subscribe(
        (result: any) => {
          this.colaboradores = result;
          let usuario = JSON.parse(localStorage.getItem('userNextCapacity'));
          for (var n = 0; n < this.colaboradores.length; n++) {
            this.permissao = false;
            if (usuario.email.trim() == this.colaboradores[n].email.trim()) {
              this.permissao = this.colaboradores[n].financeiro;
              break;
            }
          }
          if (this.permissao) {
            this.getFaturamentoDados();
          } else {
            this.toastr.error('Usuário não permitido para visualizar este relatório!', 'Atenção!');
          }
        }, error => {
          console.log(error);
        });
    }
    console.log("1 - getcolaboradores ")
  }

  async getFaturamentoDados() {
    if (this.faturamentodados.length == 0) {
      this.spinner.show();
      if (this.movSubFaturamentoDados && this.movSubFaturamentoDados.closed == false) {
        this.movSubFaturamentoDados.unsubscribe();
      }
      if (!this.movSubFaturamentoDados || this.movSubFaturamentoDados.closed) {
        this.movSubFaturamentoDados = this.crudService.listar("multidadosFaturamentoDados", null, null).subscribe(
          (result: any) => {
            this.faturamentodados = result;

            this.faturamentodados.sort((a, b) => (a.Projeto > b.Projeto) ? 1 : ((b.Projeto > a.Projeto) ? -1 : 0));
            /*
            for (var n = 0; n < this.faturamentodados.length; n++) {
              for (var i = 0; i < this.colaboradores.length; i++) {
                if (this.faturamentodados[n].Comercial.trim() == this.colaboradores[i].nome.trim()) {
                  this.faturamentodados[n].Comercial = this.colaboradores[i].aka.trim();
                }
              }
            }
            */
            for (var n = 0; n < this.faturamentodados.length; n++) {
              if (this.faturamentodados[n].Comercial.trim() != 'Selecione') {
                for (var i = 0; i < this.colaboradores.length; i++) {
                  if (this.faturamentodados[n].Comercial.trim() == this.colaboradores[i].nome.trim()) {
                    if (this.colaboradores[i].aka != undefined) {
                      this.faturamentodados[n].Comercial = this.colaboradores[i].aka.trim();
                      break;
                    }
                  }
                }
              } else {
                this.faturamentodados[n].Comercial = 'Indefinido no MD';
              }
            }
          }, error => {
            console.log(error);
          });
      }
      this.getFaturamentoAnual('');
    }
    console.log("2 - getFaturamentoDados ")
  }


  async getFaturamentoAnual(tipo) {
    let filtro = [];
    if (this.faturamentoanual.length == 0) {
      if (this.movSubFaturamentoAnual && this.movSubFaturamentoAnual.closed == false) {
        this.movSubFaturamentoAnual.unsubscribe();
      }
      if (!this.movSubFaturamentoAnual || this.movSubFaturamentoAnual.closed) {
        this.movSubFaturamentoAnual = this.crudService.listar("multidadosFaturamentoAnual", null, null).subscribe(
          (result: any) => {
            this.faturamentoanual = result;

            let sortBy = [{
              prop: 'Projeto',
              direction: 1
            }, {
              prop: 'Referencia',
              direction: 1
            }];
            this.faturamentoanual.sort(function (a, b) {
              let i = 0, result = 0;
              while (i < sortBy.length && result === 0) {
                result = sortBy[i].direction * (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString() ? -1 : (a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString() ? 1 : 0));
                i++;
              }
              return result;
            })
            this.faturamentoanual.push({ Projeto: 'XXXXX', VctoFatura: '00/00/00' })
            let valor = this.faturamentoanual[0].ValorFatura;
            for (var n = 0; n < this.faturamentoanual.length - 1; n++) {

              if (n > 0) { valor = valor + this.faturamentoanual[n].ValorFatura; }
              if (this.faturamentoanual[n].Projeto == this.faturamentoanual[n + 1].Projeto && this.faturamentoanual[n].VctoFatura.substr(3, 2) == this.faturamentoanual[n + 1].VctoFatura.substr(3, 2)) {
              } else {
                for (var z = 0; z < this.faturamentodados.length; z++) {
                  if (this.faturamentoanual[n].Projeto == this.faturamentodados[z].Projeto) {
                    this.cliente = this.faturamentodados[z].Cliente;
                    this.areaS = this.faturamentodados[z].Area;
                    this.comercialS = this.faturamentodados[z].Comercial;
                    this.comissaoS = this.faturamentodados[z].Comissao;
                    this.dataInicio = this.faturamentodados[z].DataInicio;
                    break;
                  }
                }
                this.faturamentoanualcalc.push({ 'cliente': this.cliente, 'projeto': this.faturamentoanual[n].Projeto, 'comercial': this.comercialS, 'area': this.areaS, 'comissao': this.comissaoS, 'dataInicio': this.dataInicio, 'data': ("  01/" + this.faturamentoanual[n].VctoFatura.substr(3, 2) + '/' + this.faturamentoanual[n].VctoFatura.substr(8, 2) ), 'valor': valor, status: this.faturamentoanual[n].Status.trim() == 'Faturado' ? 'Sim' : 'Não' });

                //console.log(this.cliente, ' - ', this.faturamentoanual[n].Projeto, ' - ', this.comercialS, ' - ', this.areaS, ' - ', this.dataInicio, ' - ',   this.comissaoS)

                valor = 0;
              }
            }
            this.faturamentoanualcalcfiltro = JSON.parse(JSON.stringify(this.faturamentoanualcalc));
            //this.spinner.hide();
            this.getFaturamentoAnualResumo();
          }, error => {
            console.log(error);
          });
      }
    } else {

      if (this._eventoCapacity.cliente && this._eventoCapacity.cliente != "Todos" && tipo == 'cliente') { //OK
        this._eventoCapacity.centrodecusto = "Todos";
        this._eventoCapacity.comercial = "Todos";
        this._eventoCapacity.area = "Todos";
        this._eventoCapacity.comissao = "Todos";
        filtro = [];
        filtro.push(this._eventoCapacity.cliente);
        this.faturamentoanualcalcfiltro = this.faturamentoanualcalc.filter(({ cliente }) => filtro.includes(cliente));
      }

      if (this._eventoCapacity.centrodecusto && this._eventoCapacity.centrodecusto != "Todos" && tipo == 'centro') {
        this._eventoCapacity.cliente = "Todos";
        this._eventoCapacity.comercial = "Todos";
        this._eventoCapacity.area = "Todos";
        this._eventoCapacity.comissao = "Todos";
        filtro = [];
        filtro.push(this._eventoCapacity.centrodecusto);
        this.faturamentoanualcalcfiltro = this.faturamentoanualcalc.filter(({ projeto }) => filtro.includes(projeto));
      }

      if (this._eventoCapacity.comercial && this._eventoCapacity.comercial != "Todos" && tipo == 'comercial') {
        this._eventoCapacity.centrodecusto = "Todos";
        this._eventoCapacity.cliente = "Todos";
        this._eventoCapacity.area = "Todos";
        this._eventoCapacity.comissao = "Todos";
        filtro = [];
        filtro.push(this._eventoCapacity.comercial);
        this.faturamentoanualcalcfiltro = this.faturamentoanualcalc.filter(({ comercial }) => filtro.includes(comercial));
      }

      if (this._eventoCapacity.area && this._eventoCapacity.area != "Todos" && tipo == 'area') {
        this._eventoCapacity.centrodecusto = "Todos";
        this._eventoCapacity.cliente = "Todos";
        this._eventoCapacity.comercial = "Todos";
        this._eventoCapacity.comissao = "Todos";
        filtro = [];
        filtro.push(this._eventoCapacity.area);
        this.faturamentoanualcalcfiltro = this.faturamentoanualcalc.filter(({ area }) => filtro.includes(area));
      }

      if (this._eventoCapacity.comissao && this._eventoCapacity.comissao != "Todos" && tipo == 'comissao') {
        this._eventoCapacity.centrodecusto = "Todos";
        this._eventoCapacity.cliente = "Todos";
        this._eventoCapacity.comercial = "Todos";
        this._eventoCapacity.area = "Todos";
        filtro = [];
        filtro.push(this._eventoCapacity.comissao);
        this.faturamentoanualcalcfiltro = this.faturamentoanualcalc.filter(({ comissao }) => filtro.includes(comissao));
      }





      console.log("filtro ", filtro)
      console.log("this._eventoCapacity.cliente", this._eventoCapacity.cliente)
      console.log("this._eventoCapacity.centrodecusto", this._eventoCapacity.centrodecusto)
      console.log("this._eventoCapacity.comercial", this._eventoCapacity.comercial)
      if (filtro.length == 0) {
        this.faturamentoanualcalcfiltro = JSON.parse(JSON.stringify(this.faturamentoanualcalc));
      }

    }
    console.log("3 - getFaturamentoAnual ")
  }

  async getFaturamentoAnualResumo() {
    //console.log("*** getFaturamentoAnualResumo ***")
    if (this.faturamentoanualresumo.length == 0) {

      this.faturamentoanualresumo = [];
      if (this.movSubFaturamento && this.movSubFaturamento.closed == false) {
        this.movSubFaturamento.unsubscribe();
      }
      if (!this.movSubFaturamento || this.movSubFaturamento.closed) {
        this.movSubFaturamento = this.crudService.listar("multidadosFaturamentoAnualResumo", null, null).subscribe(
          (result: any) => {
            this.faturamentoanualresumo = result;
            this.faturamentoanualresumo.sort((a, b) => (a.Projeto > b.Projeto) ? 1 : ((b.Projeto > a.Projeto) ? -1 : 0));
            this.getDados();
            this.spinner.hide();
          }, error => {
            console.log(error);
          });
      }
    }
    console.log("4 - getFaturamentoAnualResumo ")

  }


  async getDados() {
    this.clientes = [];
    this.faturamentodados.sort((a, b) => (a.Cliente > b.Cliente) ? 1 : ((b.Cliente > a.Cliente) ? -1 : 0));
    for (var n = 0; n < this.faturamentodados.length; n++) {
      this.clientes.push(this.faturamentodados[n].Cliente)
      this.centro.push(this.faturamentodados[n].Projeto)
      this.comercial.push(this.faturamentodados[n].Comercial)
      this.area.push(this.faturamentodados[n].Area)
      this.comissao.push(this.faturamentodados[n].Comissao)
      //console.log("*** getDados ***", n)
      if (n == this.faturamentodados.length - 1) {
        this.spinner.hide();
      }
    }
    this.clientes = this.clientes.filter(this.unique);
    this.centro = this.centro.filter(this.unique);
    this.comercial = this.comercial.filter(this.unique);
    this.area = this.area.filter(this.unique);
    this.comissao = this.comissao.filter(this.unique);

    console.log("5 - getDados ")
  }

  unique(value, index, self) {
    return self.indexOf(value) === index;
  }

  getFiltro(array, filtro) {
    return array.filter(o => Object.keys(filtro).every(k => [].concat(filtro[k]).some(v => o[k].includes(v))));
  }


  async getPermissao() {
    let usuario = JSON.parse(localStorage.getItem('userNextCapacity'));
    //console.log("this.colaboradores.length ", this.colaboradores.length)
    for (var n = 0; n < this.colaboradores.length; n++) {


      //console.log(usuario.email.trim(), " - ", this.colaboradores[n].email.trim)
      if (usuario.email.trim() == this.colaboradores[n].email.trim) {
        return this.colaboradores[n].financeiro;
      }
    }
    return false;
  }


  ngOnDestroy() {
    if (this.movSubFaturamento && this.movSubFaturamento.closed == false) {
      this.movSubFaturamento.unsubscribe();
    }
    if (this.movSubFaturamentoDados && this.movSubFaturamentoDados.closed == false) {
      this.movSubFaturamentoDados.unsubscribe();
    }
    if (this.movSubFaturamentoAnual && this.movSubFaturamentoAnual.closed == false) {
      this.movSubFaturamentoAnual.unsubscribe();
    }
    if (this.movSubColab && this.movSubColab.closed == false) {
      this.movSubColab.unsubscribe();
    }

  }


  exportarExcel() {
    //const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.tabela.nativeElement);
    raw: Boolean;
    var table = null;
    table = this.tabela.nativeElement;
    const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(table, { raw: true, dateNF: 'dd/mm/yyyy', cellStyles:true });
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Resumo'); 

   
    console.log('data ', this.data.getFullYear().toString() )
    let nomeArq = 'Faturamento Anual - ' + (this.data.getDate().toString() + '-' + ("0" + (this.data.getMonth() + 1)).slice(-2) + '-' + this.data.getFullYear().toString().substring(2)) + '.xlsx'
    xlsx.writeFile(wb, nomeArq);
  }



}
