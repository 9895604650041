import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { MCDigital } from '../../../model/mcdigital';
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';
import { Semana } from '../../../model/semana';
import { RecursoMC } from '../../../model/recursomc';
import { ConfirmationDialogService } from '../../../components/confirmation-dialog/confirmation-dialog.service';
import { Notificacao } from '../../../model/notificacao';
import { NgxSpinnerService } from 'ngx-spinner';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-detalhes-mcdigital',
  templateUrl: 'mcdigital-detalhes.component.html'
})
export class MCDigitalDetalhesComponent implements OnInit{

  @ViewChild('modalmcdigital', {static: false}) public modalmcdigital: ModalDirective;

  sub: boolean = false;
  _mcdigital: MCDigital = new MCDigital();
  @Output() loadmcdigital = new EventEmitter();
  titulo: string = "Nova MC Digital";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  movSubPerfil: Subscription;
  movSubColaboradores: Subscription;
  movSubEmpresa: Subscription;
  movSubCc: Subscription;
  subCliente: Subscription;
 _semanas: any = [];
  perfil: any = [];
  colaboradores: any = [];
  recurso: RecursoMC = new RecursoMC();
  recursos: any = [];
  clientes: any[];
  empresas: any[];
  movSubRisco: Subscription;
  riscos: any[];
  movSubCli: boolean;
  usuarioLogado: { gestor: any; mediador: any; colaboradorID: any; email: any; id: any; nome: any; financeiro: any; tecnico: any; };
  user: any;
  subColaboradores: Subscription;
  mcdigital: MCDigital[];
  movSub: Subscription;
  gestores: any = [];
  //sigla: string ;
  atividade: string;
  dataAtual: Date = new Date();
  displayCodigo: boolean = false;
  novaVersao: boolean = false;
  centrosCusto: any = [];
  descontoMax: number = 0;
  totalHorasPerfil:  any = [];
  descontoMaxRisco : number;
  statusT  : number = 0;
  statusF  : number = 0;
  desabilitar : boolean = false;
  desabilitarVal : boolean = false;



  constructor(
    private spinner: NgxSpinnerService,
    private CrudService: CrudService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CrudService,
    private confirmationDialogService: ConfirmationDialogService) {
    this.user = JSON.parse(localStorage.getItem('userNextCapacity'));
  }

  ngOnInit() {

    this.user = JSON.parse(localStorage.getItem('userNextCapacity'));

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesMCDigital.subscribe(
        (p: MCDigital) => {

          if(p == null){
            this.titulo = "Nova MC Digital"
            this._semanas = [];
            this.recursos = [];
            this._mcdigital = new MCDigital();
            this._mcdigital.versao = 0;
            this.getmcdigital();
            this.atividade= ''
            this.desabilitar = false;


          }else{
            this.titulo = "Editar MC Digital"
            this._mcdigital = p;
            this.displayCodigo = true
            this.changePeriodo();
            this.desabilitar = true;


            if((this._mcdigital.statusTecnico==0 && this._mcdigital.statusFinanceiro==0) ||  (this._mcdigital.statusTecnico==2 && this._mcdigital.statusFinanceiro==2)){
              this.desabilitarVal = false;
            }

            if(this._mcdigital.statusTecnico==-1 && this._mcdigital.statusFinanceiro==-1 ){
              this.desabilitarVal = true;
            }
            if(this._mcdigital.statusTecnico==1 && this._mcdigital.statusFinanceiro==1 ){
              this.desabilitarVal = true;
            }



            if(this._mcdigital.statusTecnico==0 && this._mcdigital.statusFinanceiro==0){
              this.desabilitar = false;
            }



            if(this._mcdigital.siglamc.substring(3,6) == 'CRQ' ){
              this.atividade = 'CRQ';
              this.getCentrosCusto();

            } else {
              this.atividade = 'XXX';
            }

            console.log("this.atividade ", this.atividade)



          }

          this.gestores = [];

          this.recurso.tipo = "perfil";
          this.changeTipo(p);
          this.limpaModal();
          this.getEmpresas();
          this.getRiscos();
          this.getClientes();

          this.modalmcdigital.show();

        }, error => {
          console.log(error.message);
        }
      );


    }

  }


  hideModal(){
    this.modalmcdigital.hide();
    this.loadmcdigital.emit();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  newVersion(){
    this.novaVersao = true
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    let objMCDigital = JSON.parse(JSON.stringify(this._mcdigital));

    if(objMCDigital.id){
      //if(objMCDigital.statusTecnico == 1 &&  objMCDigital.statusFinanceiro == 1){
      if(objMCDigital.statusTecnico != 0 && objMCDigital.statusFinanceiro != 0){

        if(this.novaVersao){

          this._mcdigital.ativo = false;

          console.log("desconto" ,this._mcdigital.desconto)
          console.log("desconto max" ,this._mcdigital.descontoMax)

          if(this._mcdigital.statusTecnico == 2 && this._mcdigital.statusFinanceiro == 2 && this._mcdigital.desconto < this._mcdigital.descontoMax ){
            this.statusT = this._mcdigital.statusTecnico;
            this.statusF = this._mcdigital.statusFinanceiro;
          } else{
            this.statusT = 0;
            this.statusF = 0;
            if(this._mcdigital.desconto > this._mcdigital.descontoMax){
              this.toastr.warning('O desconto oferecido está maior que o desconto máximo permitido. Será necessário uma nova aprovação!', 'Atenção!');
            }
          }


          this._mcdigital.statusTecnico = -1;
          this._mcdigital.statusFinanceiro = -1;

          this.atualizar();
          this._mcdigital.versao = this._mcdigital.versao + 1;

          this._mcdigital.statusTecnico = 0;
          this._mcdigital.statusFinanceiro = 0;

          this._mcdigital.ativo = true;
          this.salvar();
          form.reset();


        } else if(objMCDigital.statusTecnico == 2 && objMCDigital.statusFinanceiro == 2){
          this.atualizar();
          form.reset();

        } else{
          this.toastr.warning("Não é possivel alterar essa MC Digital, pois já foi solicitado aprovação.");
        }
      } else{

        //Se tecnico ou financeiro ja foi reprovado ou aprovado , e esta havendo alteração... então solicitando nova aprovação
        if(objMCDigital.statusTecnico < 4 && objMCDigital.statusFinanceiro < 4){
          //this._mcdigital.statusTecnico = 1;
          //this._mcdigital.statusFinanceiro = 1;
          this.solicitar(objMCDigital);
        }

        this.atualizar();

        form.reset();
      }
    } else{
      this._mcdigital.statusTecnico = 0;
      this._mcdigital.statusFinanceiro = 0;
      this._mcdigital.ativo = true;
      this._mcdigital.versao = 0;
      this.salvar();
      form.reset();
    }


  }

  solicitar(mcdigital: MCDigital){

      //mcdigital.statusFinanceiro = 1;
      //this.enviaNotificacaoSolicitacao(mcdigital, false, true);

      //mcdigital.statusTecnico = 1;
      //this.enviaNotificacaoSolicitacao(mcdigital, true, false);

      this.cadastroService.atualizar("mcdigital", mcdigital);

      //this.toastr.success('Nova solicitação de aprovação foi enviada', 'Sucesso!');


  }

  enviaNotificacaoSolicitacao(mcdigital: MCDigital, tecnico: boolean, financeiro: boolean){

    let notificacao = new Notificacao();
    notificacao.assunto = "Next Capacity - Solicitacao de Aprovacao MC Digital";

    let tecnicos = []
    let financeiros = [];
    let solicitanteNome = "";
    let clienteNome = "";

    for(var c=0; c<this.clientes.length; c++){
      if(mcdigital.cliente == this.clientes[c].id){
        clienteNome = this.clientes[c].nome;
      }
    }

    for(var c=0; c<this.colaboradores.length; c++){

      if(mcdigital.solicitante == this.colaboradores[c].id){
        solicitanteNome = this.colaboradores[c].nome;
      }

      if(this.colaboradores[c].tecnico){
        tecnicos.push(this.colaboradores[c]);
      }

      if(this.colaboradores[c].financeiro){
        financeiros.push(this.colaboradores[c]);
      }
    }

    if(tecnico){
      for(var t=0; t<tecnicos.length; t++){
        notificacao.link = "https://capacity.nextmobile.com.br/";

        let msg = "<h3><b>Solicitante:</b> "+solicitanteNome+"</h3>"+
                  "<h3><b>MC Digital:</b> "+mcdigital.siglamc+"</h3>"+
                  "<h3><b>Cliente:</b> "+clienteNome+"</h3>"+
                  "<h3><b>Valor:</b> R$ "+mcdigital.custoprojeto.toFixed(2)+"</h3>";

        notificacao.msg = msg;
        notificacao.status = "P";
        notificacao.titulo = "Next Capacity - Solicitação de Aprovação MC Digital";
        notificacao.emailDestino = tecnicos[t].email;

        this.cadastroService.salvar("notificacoes", notificacao);
      }
    }

    if(financeiro){
      for(var t=0; t<financeiros.length; t++){
        notificacao.link = "https://capacity.nextmobile.com.br/";

        let msg = "<h3><b>Solicitante:</b> "+solicitanteNome+"</h3>"+
                  "<h3><b>MC Digital:</b> "+mcdigital.siglamc+"</h3>"+
                  "<h3><b>Cliente:</b> "+clienteNome+"</h3>"+
                  "<h3><b>Valor:</b> R$ "+mcdigital.custoprojeto.toFixed(2)+"</h3>";

        notificacao.msg = msg;
        notificacao.status = "P";
        notificacao.titulo = "Next Capacity - Solicitacao de Aprovação MC Digital";
        notificacao.emailDestino = financeiros[t].email;

        this.cadastroService.salvar("notificacoes", notificacao);
      }
    }

  }

  enviaNotificacaoAprovacao(mcdigital: MCDigital, aprovado: boolean, tipo: string){

    let notificacao = new Notificacao();

    if(aprovado){
      notificacao.assunto = "Next Capacity - MC Digital ("+tipo+") Aprovada !";
    }else{
      notificacao.assunto = "Next Capacity - MC Digital ("+tipo+") Reprovada !";
    }

    let clienteNome = "";
    for(var c=0; c<this.clientes.length; c++){
      if(mcdigital.cliente == this.clientes[c].id){
        clienteNome = this.clientes[c].nome;
      }
    }


    let emailSolicitante = "";
    let aprovador = "";
    for(var c=0; c<this.colaboradores.length; c++){

      if(mcdigital.solicitante == this.colaboradores[c].id){
        emailSolicitante = this.colaboradores[c].email;
      }

      if(this.usuarioLogado.id == this.colaboradores[c].id){
        aprovador = this.colaboradores[c].nome;
      }
    }

    notificacao.link = "https://capacity.nextmobile.com.br/";

    let msg="";

    if(aprovado){
       msg = "<h3><b>Quem aprovou ("+tipo+") :</b> "+aprovador+"</h3>"+
      "<h3><b>MC Digital:</b> "+mcdigital.siglamc+"</h3>"+
      "<h3><b>Cliente:</b> "+clienteNome+"</h3>"+
      "<h3><b>Valor:</b> R$ "+mcdigital.custoprojeto.toFixed(2)+"</h3>";
    }else{
       msg = "<h3><b>Quem reprovou ("+tipo+") : </b> "+aprovador+"</h3>"+
      "<h3><b>MC Digital:</b> "+mcdigital.siglamc+"</h3>"+
      "<h3><b>Cliente:</b> "+clienteNome+"</h3>"+
      "<h3><b>Valor:</b> R$ "+mcdigital.custoprojeto.toFixed(2)+"</h3>";
    }

    notificacao.msg = msg;
    notificacao.status = "P";

    if(aprovado){
      notificacao.titulo = "Next Capacity - MC Digital ("+tipo+") <span style='color: green;'>Aprovada</span> !";
    }else{
      notificacao.titulo = "Next Capacity - MC Digital ("+tipo+") <span style='color: red;'>Reprovada</span> !";
    }

    notificacao.emailDestino = emailSolicitante;

    this.cadastroService.salvar("notificacoes", notificacao);


  }

  setUsuarioLogado(){

    for(var i = 0; i < this.colaboradores.length; i++){
      if(this.colaboradores[i].email == this.user.email){

        this.usuarioLogado = {
          gestor : this.colaboradores[i].gestor,
          mediador : this.colaboradores[i].mediador,
          colaboradorID : this.colaboradores[i].id,
          email : this.colaboradores[i].email,
          id : this.colaboradores[i].id,
          nome: this.colaboradores[i].nome,
          financeiro: this.colaboradores[i].financeiro,
          tecnico: this.colaboradores[i].tecnico
        }

      }
    }

    if(!this.usuarioLogado){
      this.toastr.warning('Colaborador não localizado!!! Favor contatar o administrador.', 'Atenção!');
    }

  }

  changeTipo(p: any){
    if(this.recurso.tipo == "perfil"){
      if(p){
        this.getPerfil(p);
      }else{
        this.colaboradores = null;
        this.getPerfil(p);
      }
    }else{
      this.perfil = null;
      this.getColaboradores(null);
    }
  }

  changeRisco(){
    for(let r of this.riscos){
      if(r.id == this._mcdigital.risco){
        this._mcdigital.percRisco = r.percentual;
        this._mcdigital.descontoMaxRisco = r.descontoMax;
      }
    }

    console.log("this._mcdigital.descontoMaxRisco ",this._mcdigital.descontoMaxRisco)

    this.calculaProjeto();
  }

  changeEmpresa(){

    for(let e of this.empresas){
      if(e.id == this._mcdigital.empresa){
        this._mcdigital.impostoEmpresa = e.imposto;
        this._mcdigital.markup = e.markup;
        this._mcdigital.overhead = e.overhead;
        this._mcdigital.comissao = e.comissao;
      }
    }
    this.calculaProjeto();
  }

  changeMargem(){

    if(this._mcdigital.margem < 20){
      this._mcdigital.margem = 20;
    }

    this.calculaProjeto();
  }

  adicionarRecurso(){

    if(!this._mcdigital.empresa){
      this.toastr.warning("Informe a empresa faturante.");
      return;
    } else if(!this._mcdigital.cliente){
      this.toastr.warning("Informe o Cliente.");
      return;
    } else if(!this._mcdigital.siglaColaborador){
      this.toastr.warning("Informe o Responsável Comercial.");
      return;
    } else if(!this.atividade){
        this.toastr.warning("Informe a Demanda.");
        return;
    } else{
      for(let e of this.empresas){
        if(e.id == this._mcdigital.empresa){
          this._mcdigital.impostoEmpresa = e.imposto;
        }
      }
    }


    if(!this._mcdigital.risco){
      this.toastr.warning("Informe o risco.");
      return;
    }else{
      for(let r of this.riscos){
        if(r.id == this._mcdigital.risco){
          this._mcdigital.percRisco = r.percentual;
        }
      }
    }



    if(!this._mcdigital.margem){
      this.toastr.warning("Informe a margem de contribuição.");
      return;
    }

    let objMCDigital = JSON.parse(JSON.stringify(this._mcdigital));

    if(objMCDigital.periodoDe && objMCDigital.periodoAte){

      let custo = 0;
      if(this.recurso.tipo == "perfil"){
       for(let p of this.perfil){
         if(p.id == this.recurso.id){
           custo = p.custoHora;
         }
       }
      }else{
        for(let c of this.colaboradores){
          if(c.id == this.recurso.id){
            custo = parseFloat(c.valorHora.replace(",","."));
          }
        }
      }

      if(custo <= 0){
        this.toastr.warning("O custo deste perfil/colaborador é menor ou igual a zero. Necessário checar cadastro.");
        return;
      }

      this.recurso.custo = custo;

      let _newRecurso = JSON.parse(JSON.stringify(this.recurso));
      let _semanas = JSON.parse(JSON.stringify(this._semanas));
      _newRecurso.semanas = _semanas;

      this.recursos.push(_newRecurso);
      this._mcdigital.recursos = this.recursos;
    }else{
      this.toastr.warning("Informe o periodo.");
      return;
    }

  }

  salvar(){

    let _mcdgital = JSON.parse(JSON.stringify(this._mcdigital));
    _mcdgital.recursos = JSON.parse(JSON.stringify(this.recursos));

    if (this.movSubSalvar && this.movSubSalvar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed){

      this.movSubSalvar = this.cadastroService.findByGeneric("mcdigital", "siglamc", _mcdgital.siglamc).subscribe(
        (result: any) =>{

          //if (result.length > 0 && !this.novaVersao) {     //ATENÇÃO: APÓS CADASTROS DAS MCS ANTIGAS TROCAR A LINHA DEBAIXO
          if (result.length > 10  ) {
            this.toastr.error('Esta Sigla já esta sendo utilizado por outra MC Digital!', 'Atenção!');
            this.hideModal();
          }else{

            if(_mcdgital.statusFinanceiro < 4 && _mcdgital.statusTecnico < 4){
              _mcdgital.solicitante = this.usuarioLogado.id;

              _mcdgital.statusFinanceiro = this.statusF;
              _mcdgital.statusTecnico = this.statusT;

              this.cadastroService.salvar("mcdigital", _mcdgital);

              this.toastr.success('MC Digital cadastrada com sucesso!', 'Sucesso!');

            }else{
              this.toastr.error('Não é possivel alterar essa MC Digital, pois ela ja fi finalizada!', 'Atenção!');
            }

            this.hideModal();

            this.movSubSalvar.unsubscribe();
          }

       },error=>{
            console.log(error);
            this.hideModal();
       });

    }

  }

  changePeriodo(){

    this._semanas = [];
    let objMCDigital = JSON.parse(JSON.stringify(this._mcdigital));

    if(objMCDigital.periodoDe && objMCDigital.periodoAte){

      let splitDateDe = objMCDigital.periodoDe.split("-");
      let datePeriodoDe : Date = new Date(splitDateDe[0], splitDateDe[1]-1, splitDateDe[2], 0, 0, 0);

      let splitDateAte = objMCDigital.periodoAte.split("-");
      let datePeriodoAte : Date = new Date(splitDateAte[0], splitDateAte[1]-1, splitDateAte[2], 0, 0, 0);

      if(splitDateAte < splitDateDe){
        this.toastr.warning("Periodo (ATÉ) não pode ser menor que Período (DE).")
      }else{
        objMCDigital.periodoDe = datePeriodoDe;
        objMCDigital.periodoAte = datePeriodoAte;

        let diff = Math.abs(datePeriodoAte.getTime() - datePeriodoDe.getTime()); // Subtrai uma data pela outra
        let days = Math.ceil(diff / (1000 * 60 * 60 * 24)); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).

        let semanas = Math.ceil(days/7); //arredonda pra cima

        for(var i = 0; i < semanas; i++){

          let sem: Semana = new Semana();
          sem.id = i;
          sem.horas = 0;

          this._semanas.push(sem);
        }

        if(this.recursos && this.recursos.length > 0){
          for(let r of this.recursos){

            if(r.semanas.length < this._semanas.length){
              let i: number = r.semanas.length;
              while(r.semanas.length < this._semanas.length){
                i = i + 1;
                let sem: Semana = new Semana();
                sem.id = i;
                sem.horas = 0;
                r.semanas.push(sem);
              }
            }

            if( r.semanas.length > this._semanas.length){
              let i: number = r.semanas.length-1;
              while(r.semanas.length > this._semanas.length){
                const index = r.semanas.map(e => e.id).indexOf(i);
                if (index > -1) {
                  r.semanas.splice(index, 1);
                }
                i = i - 1;
              }
            }
          }
        }
      }
   }
  }

  async getPerfil(p: any){

    this.perfil = [];

    if (this.movSubPerfil && this.movSubPerfil.closed == false){
      this.movSubPerfil.unsubscribe();
    }

    if (!this.movSubPerfil || this.movSubPerfil.closed){

      this.movSubPerfil = this.cadastroService.listar("perfil", null, null).subscribe(
        (result: any) =>{
          this.perfil =  result;
          this.perfil.sort((a,b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0));

          this.getColaboradores(p);
       },error=>{
            console.log(error);
       });

    }

  }

  async getClientes(){

    this.clientes = [];

    if (this.subCliente && this.subCliente.closed == false){
      this.subCliente.unsubscribe();
    }

    if (!this.subCliente || this.subCliente.closed){

      this.subCliente = this.cadastroService.listar("cliente", "ativo", true).subscribe(
        (result: any) =>{
          this.clientes =  result;
          this.clientes.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));

       },error=>{
            console.log(error);
       });

    }

  }

  async getColaboradores(p: any){

    this.colaboradores = [];

    if (this.movSubColaboradores && this.movSubColaboradores.closed == false){
      this.movSubColaboradores.unsubscribe();
    }

    if (!this.movSubColaboradores || this.movSubColaboradores.closed){

      this.movSubColaboradores = this.cadastroService.listar("colaborador", null, null).subscribe(
        (result: any) =>{

          this.colaboradores =  result;
          console.log("colaboradores",this.colaboradores);
          this.colaboradores.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
          this.setUsuarioLogado();

          this.gestores = [];
          for(var c=0; c<this.colaboradores.length; c++){
            if(this.colaboradores[c].gestor == true){
              this.gestores.push(this.colaboradores[c]);
            }
          }
          if(p){
            this.recursos = p.recursos;
          }
       },error=>{
            console.log(error);
       });

    }


  }

  geraSiglaCC(){
      if(this.titulo == 'Nova MC Digital'){
        //if(this.atividade !='CR' ){
          this._mcdigital.siglamc = '';
          this._mcdigital.responsavelComercial =  $('#responsavel').find(":selected").text();

          console.log(' this._mcdigital.responsavelComercial' ,  this._mcdigital.responsavelComercial )

          this.displayCodigo = false;
          let mes = String.fromCharCode(65 + this.dataAtual.getMonth())
          let ano = this.dataAtual.getFullYear().toString().substring(2, 4);
          let clienteSigla = '';
          let indice = '01';
          for(var c=0; c<this.clientes.length; c++){
            if(this._mcdigital.cliente == this.clientes[c].id){
              clienteSigla = this.clientes[c].sigla;
            }
          }
          let codeParcial = clienteSigla + this.atividade + ano + this._mcdigital.siglaColaborador + mes;
          for(var cc=0; cc<this.mcdigital.length; cc++){
            if(this.mcdigital[cc].siglamc.substring(0, 11) == codeParcial){
              if(indice <= (parseInt(this.mcdigital[cc].siglamc.substring(12, 13)) + 1).toString().padStart(2, "0")){
                indice = (parseInt(this.mcdigital[cc].siglamc.substring(12, 13)) + 1).toString().padStart(2, "0")
              }
            }
          }
          this._mcdigital.siglamc = codeParcial + indice
          if( this._mcdigital.siglamc.length == 13){
            this.displayCodigo = true;
          }

      /*
      } else {
          let indice = '01';
          for(var cc=0; cc<this.mcdigital.length; cc++){
            if(this.mcdigital[cc].descricaoCR == this._mcdigital.descricaoCR){
              if(indice <= (parseInt(this.mcdigital[cc].siglamc.substring(2, 4)) + 1).toString().padStart(2, "0")){
              indice = (parseInt(this.mcdigital[cc].siglamc.substring(2, 4)) + 1).toString().padStart(2, "0")
              }
            }
          }
          this._mcdigital.siglamc = 'CR' + indice + ' - ' +  this._mcdigital.descricaoCR;
          if(this._mcdigital.descricaoCR != null){
            this.displayCodigo = true;
          }
      }
      */

     if(this.atividade !='CR' && this.centrosCusto.length == 0){
      this.getCentrosCusto();
     }

      }
  }

  async getEmpresas(){

    this.empresas = [];

    if (this.movSubEmpresa && this.movSubEmpresa.closed == false){
      this.movSubEmpresa.unsubscribe();
    }

    if (!this.movSubEmpresa || this.movSubEmpresa.closed){

      this.movSubEmpresa = this.cadastroService.listar("empresas", null, null).subscribe(
        (result: any) =>{
          this.empresas =  result;
          this.empresas.sort((a,b) => (a.razaoSocial > b.razaoSocial) ? 1 : ((b.descricao > a.descricao) ? -1 : 0));

          console.log("Empresas ",  this.empresas)

       },error=>{
            console.log(error);
       });

    }

  }

  async getRiscos(){

    this.riscos = [];

    if (this.movSubRisco && this.movSubRisco.closed == false){
      this.movSubRisco.unsubscribe();
    }

    if (!this.movSubRisco || this.movSubRisco.closed){

      this.movSubRisco = this.cadastroService.listar("riscos", null, null).subscribe(
        (result: any) =>{
          this.riscos =  result;
          this.riscos.sort((a,b) => (a.percentual > b.percentual) ? 1 : ((b.percentual > a.percentual) ? -1 : 0));
       },error=>{
            console.log(error);
       });

    }

  }

  async getmcdigital(){
    this.mcdigital = [];
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
    if (!this.movSub || this.movSub.closed){
      this.spinner.show();
      this.movSub = this.CrudService.listar("mcdigital", null, null).subscribe(
        (result: any) =>{

          this.mcdigital =  result;
          console.log("this.mcdigital ", result);
          this.spinner.hide();

       },error=>{
            console.log(error);
            this.spinner.hide();
       });
    }
  }

  async getCentrosCusto(){
    this.centrosCusto = [];
    if (this.movSubCc && this.movSubCc.closed == false){
      this.movSubCc.unsubscribe();
    }
    if (!this.movSubCc || this.movSubCc.closed){
      this.spinner.show();
      this.movSubCc = this.CrudService.listar("centrodecusto", null, null).subscribe(
        (result: any) =>{
          this.centrosCusto =  result;
          this.centrosCusto.sort((a,b) => (a.descricaoMin > b.descricaoMin) ? 1 : ((b.descricaoMin > a.descricaoMin) ? -1 : 0));
          this.spinner.hide();
       },error=>{
            console.log(error);
            this.spinner.hide();
       });
    }
  }


  calculaProjeto(){


    console.log("CALCULANDO PROJETO this.recursos ", this.recursos)

    let totalRecursos = 0;
    let totalHoras = 0;
    let i=0;

    for(let r of this.recursos){
      let custoRecurso = 0;
      this.totalHorasPerfil[i] = 0;
      for(let s of r.semanas){

        custoRecurso = custoRecurso + (r.custo * s.horas);
        totalHoras = totalHoras +  s.horas;
        this.totalHorasPerfil[i] = this.totalHorasPerfil[i] + s.horas;
      }

      r.horasSemana = this.totalHorasPerfil[i];

      i += 1;
      totalRecursos = totalRecursos + custoRecurso;
    }

    /* Cálculo antigo - Início

    let valorMargem = totalRecursos * (this._mcdigital.margem/100);
    let totalComMargem = totalRecursos + valorMargem;

    let valorRisco = totalComMargem * (this._mcdigital.percRisco/100);
    let totalComRisco = totalComMargem + valorRisco;

    let totalComPlanejadas = totalComRisco + this._mcdigital.planejadas;

    let valorImposto = totalComPlanejadas * (this._mcdigital.impostoEmpresa/100);
    let totalComImposto = totalComPlanejadas + valorImposto;

    this._mcdigital.valorvenda = Number(totalComImposto.toFixed(2));
     this._mcdigital.custoprojeto = totalRecursos + this._mcdigital.planejadas;

    Cálculo antigo - Fim */



    this._mcdigital.custoprojeto = totalRecursos;
    this._mcdigital.totalhoras = totalHoras;
    this._mcdigital.descontoMax = Math.round((totalRecursos * this._mcdigital.markup + this._mcdigital.planejadas + this._mcdigital.incrementoFinanceiro ) * this._mcdigital.percRisco/100);
   // if(this._mcdigital.descontoMaxRisco < this._mcdigital.descontoMax){
   //  this._mcdigital.descontoMax = this._mcdigital.descontoMaxRisco;
  //}

    console.log("this._mcdigital.descontoMax ", this._mcdigital.descontoMax)
    console.log("this._mcdigital.descontoMaxRisco ", this._mcdigital.descontoMaxRisco)


    this._mcdigital.valorvenda = totalRecursos * this._mcdigital.markup + this._mcdigital.planejadas - this._mcdigital.desconto + this._mcdigital.incrementoFinanceiro;

  }

  excluir(p: RecursoMC){

    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
    .then((confirmed) =>{

      if(confirmed){

        console.log(" exc this._mcdigital.statusTecnico",this._mcdigital.statusTecnico)
        console.log("exc this._mcdigital.statusFinanceiro", this._mcdigital.statusFinanceiro)


        if( this._mcdigital.statusFinanceiro < 4 || this._mcdigital.statusTecnico < 4 || this._mcdigital.statusTecnico == null ){
          this._mcdigital.recursos = this._mcdigital.recursos.filter(r => r !== p);
          this.recursos =  this._mcdigital.recursos;

          this.calculaProjeto();
          this.toastr.success('Recurso excluído com sucesso!', 'Sucesso!');
        }else{
          this.toastr.error('Não é possivel alterar essa MC Digital, pois ela ja foi finalizada!', 'Atenção!');
        }


      }

    })
    .catch(() => {
      console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });

  }



  atualizar(){

    let objmcdigital = JSON.parse(JSON.stringify(this._mcdigital));
    objmcdigital.recursos = JSON.parse(JSON.stringify(this.recursos));

    console.log("¨*******this.recursos**********", this.recursos)

    if (this.movSubAlterar && this.movSubAlterar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubAlterar || this.movSubAlterar.closed){

      this.movSubAlterar = this.cadastroService.findByGeneric("mcdigital", "siglamc", this._mcdigital.siglamc).subscribe(
        (result: any) =>{

          if(result.length > 0){
            if(result[0].cnpj == objmcdigital.cnpj){

              if(objmcdigital.statusFinanceiro < 4 && objmcdigital.statusTecnico < 4){
                this.cadastroService.atualizar("mcdigital", objmcdigital);

                this.toastr.success('MC Digital atualizada com sucesso!', 'Sucesso!');
              }else{
                this.toastr.error('Não é possivel alterar essa MC Digital, pois ela ja foi finalizada!', 'Atenção!');
              }


              this.hideModal();

              this.movSubAlterar.unsubscribe();
            }
          }

       },error=>{
            console.log(error);
            this.hideModal();
       });

    }

  }
}
