import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CrudService } from "../../services/crudService";
import { ModalService } from '../../services/modal.service';
import { Perfil } from '../../model/perfil';
import { ConfirmationDialogService } from '../../components/confirmation-dialog/confirmation-dialog.service';
import { PerfilCSV } from '../../model/perfilCSV';
import { CsvDataService } from '../../services/csvdata.service';
  
@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})   
export class PerfilComponent implements OnInit {
      
  movSub: Subscription;
  perfil: any[];
  fileReader: FileReader;
  fileToRead: File;
  tecnologias: any[];
  niveis: any[];
  movSubNiveis: Subscription;
  movSubTecnologia: Subscription;

  constructor(
      private spinner: NgxSpinnerService,
      private CrudService: CrudService,
      private modalService: ModalService,
      private cadastroService: CrudService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService,
      private csvDataService: CsvDataService
  ){
    
  }

  ngOnInit() {
    this.getperfil();
    this.getTecnologias();
    this.getNíveis();
  }

  downloadTemplate(){

    let _perfilCSV: any = [];

    if(this.perfil.length > 0){
      for(var i= 0; i < this.perfil.length; i++){

        let perfilCSV = new PerfilCSV();
  
        perfilCSV._1_codigo = this.perfil[i].codigo;
        perfilCSV._2_descricao = this.perfil[i].descricao;
        perfilCSV._3_tecnologia = this.perfil[i].tecnologia;
        perfilCSV._4_nivel = this.perfil[i].nivel;
        perfilCSV._5_custoHora = this.perfil[i].custoHora;
  
        _perfilCSV.push(perfilCSV);
      }
    }else{
      let perfilCSV = new PerfilCSV();
  
      perfilCSV._1_codigo = "";
      perfilCSV._2_descricao = "";
      perfilCSV._3_tecnologia = "";
      perfilCSV._4_nivel = "";
      perfilCSV._5_custoHora = 0;
      _perfilCSV.push(perfilCSV);
    }    
    
    this.csvDataService.exportToCsv("perfil_capacity.csv", _perfilCSV);
  }

  getTecnologias(){

    this.tecnologias = [];

    if (this.movSubTecnologia && this.movSubTecnologia.closed == false){
      this.movSubTecnologia.unsubscribe();
    }

    if (!this.movSubTecnologia || this.movSubTecnologia.closed){
       this.movSubTecnologia = this.CrudService.listar("tecnologia", null, null).subscribe(
        (result: any) =>{

          this.tecnologias =  result;
  
       },error=>{
            console.log(error);
       });

    }

  }

  getNíveis(){

    this.niveis = [];

    if (this.movSubNiveis && this.movSubNiveis.closed == false){
      this.movSubNiveis.unsubscribe();
    }
    
    if (!this.movSubNiveis || this.movSubNiveis.closed){
     
      this.movSubNiveis = this.CrudService.listar("nivel", null, null).subscribe(
        (result: any) =>{

          this.niveis =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }

  }

  async getperfil(){
 
    this.perfil = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();
     
      this.movSub = this.CrudService.listar("perfil", null, null).subscribe(
        (result: any) =>{

          this.perfil =  result;
          console.log(result);

          this.spinner.hide();
  
       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }
    

  }


  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (this.movSubNiveis && this.movSubNiveis.closed == false){
      this.movSubNiveis.unsubscribe();
    }

    if (this.movSubTecnologia && this.movSubTecnologia.closed == false){
      this.movSubTecnologia.unsubscribe();
    }
  }

  novoperfil(){

    let obj = {
      "perfil": null,
      "tecnologias": this.tecnologias,
      "niveis": this.niveis
    }
    this.modalService.modalDetalhesPerfil.emit(obj);
  }

  editar(perfil: Perfil){

    let obj = {
      "perfil": perfil,
      "tecnologias": this.tecnologias,
      "niveis": this.niveis
    }
    this.modalService.modalDetalhesPerfil.emit(obj);
  }
 
  excluir(perfil: Perfil){
    
    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          this.cadastroService.excluir("perfil", perfil.id);
            
          this.toastr.success('Perfil excluído com sucesso!', 'Sucesso!');
          this.getperfil();               
           
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    
  }

  importar(){
    
    if(this.fileToRead){
      this.fileReader.readAsText(this.fileToRead, "UTF-8");
    }else{
      this.toastr.warning('Nenhum arquivo selecionado!', 'Atenção!');
    }
  
  }

  onFileSelect(input: HTMLInputElement) {

    const files = input.files;

    this.fileToRead = null;

    if (files && files.length) {
       
          if(files[0].name.indexOf("perfil") == -1 ||
              files[0].name.indexOf("capacity") == -1 ||
                files[0].name.indexOf("csv") == -1){
           
            this.toastr.warning('Arquivo incorreto!', 'Atenção!');

            input.value = "";

          }else{
            this.fileToRead = files[0];

            this.fileReader = new FileReader();

             this.fileReader.onload = (function(crudService, toastr, perfil, tecnologias, niveis){
              
              return function(e){
                 

                  const textFromFileLoaded = e.target.result;              
                  let csvContent = textFromFileLoaded.split('\n');  
                   

                  var arquivoInvalido = false;
                  var tecnologiaInvalida = false;
                  var nivelInvalido = false;

                  let lista : Perfil[] = [];

                  for(var i= 1; i < csvContent.length; i++){
              
                    var linha = csvContent[i].split(';');

                    if(linha.length != 5){
                      arquivoInvalido = true;
                    }else{
                      let obj : Perfil = new Perfil();
                      obj.codigo = linha[0];
                      obj.descricao = linha[1];

                      var achouTecnologia = false;
                      for (var t = 0; t < tecnologias.length; t++) {
                          if (tecnologias[t].nome === linha[2]) {
                            achouTecnologia = true;
                          }
                      }
                    
                      if(achouTecnologia){
                        obj.tecnologia = linha[2];
                      }else{
                        tecnologiaInvalida = true;
                        i = csvContent.length;
                      }

                      var achouNivel = false;
                      for (var n = 0; n< niveis.length; n++) {
                          if (niveis[n].nome ===  linha[3]) {
                            achouNivel = true;
                          }
                      }
                      
                      if(achouNivel){
                        obj.nivel = linha[3];
                      }else{
                        nivelInvalido = true;
                        i = csvContent.length;
                      }
                      
                      obj.custoHora = linha[4];
                      
                      lista.push(obj);
                    }

                  }

                  if(tecnologiaInvalida){
                    toastr.warning('Tecnologia invalido! Verifique a tecnologia informada.', 'Atenção!');
                    input.value = "";
                  }else if(nivelInvalido){
                    toastr.warning('Nivel invalido! Verifique o nivel informado.', 'Atenção!');
                    input.value = "";
                  }else if(arquivoInvalido){
                    toastr.warning('Arquivo invalido! Verifique a quantidades de colunas.', 'Atenção!');
                    input.value = "";
                  }else{
                    if(lista.length == 0){
                      toastr.warning('Arquivo vazio!', 'Atenção!');
                      input.value = "";
                    }else{
                      
                      for(var l = 0; l < lista.length; l++){
                        
                        var existe = false;
                        for(var c = 0; c < perfil.length; c++){
                          if(perfil[c].codigo == lista[l].codigo){
                            existe = true;
                          }
                        }

                        if(existe == false){
                          crudService.salvar("perfil", lista[l]);
                        }

                      }

                      input.value = "";
                      toastr.success('Importação executada!', 'Sucesso!');

                    }
                  }
                  
              };
              
            })(this.CrudService, this.toastr, this.perfil, this.tecnologias, this.niveis);  

          }

         

    }

  }

}
