
export class AlterarSenha {

    public novasenha: string;
    public confirmarsenha: string;
 
    constructor(){
        
    }
}
