
export class FeriadoCSV {

    public _1_dataFeriado: string;
    public _2_descricao: string;

    constructor(){
        
    }
}
