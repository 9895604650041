import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { GraficosComponent } from './graficos.component';
import { GraficosRoutingModule } from './graficos-routing.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


@NgModule({
  imports: [
    GraficosRoutingModule,
    ChartsModule,
    FormsModule,
    CommonModule
  ],
  declarations: [ GraficosComponent ],
  exports: [GraficosComponent]
})
export class GraficosModule { }



