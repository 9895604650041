export class Alocacao {

    public cliente: string;
    public centrodecusto: string;
    public data: Date;
    public colaborador: string;
    public nomeColaborador: string;
    public horas: Number;
    
    constructor(){ 
        
    }
}
