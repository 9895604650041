
export class PerfilCSV {

    public _1_codigo: string;
    public _2_descricao: string;
    public _3_tecnologia: string;
    public _4_nivel: string;
    public _5_custoHora: Number;
 
    constructor(){
        
    }
}
