import { Component } from '@angular/core';
import { AuthService, AuthResponseData } from '../../auth/auth.service';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ClientesService } from '../../services/clientes.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent {

  isLoading = false;
  error: string = null;
  movSub: Subscription;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private clienteService: ClientesService) {}

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    const email = form.value.email;
    const password = form.value.password;
    const password2 = form.value.password2;

    if(password != password2){
      this.error = "Senha devem ser iguais";
      return;
    }

    this.isLoading = true;

    const dominio = email.split("@")[1];
          
    this.authService.login("ics@coachit.com.br", "123456")
    .then(
    (result) => {
    
        this.isLoading = false;

        if (this.movSub && this.movSub.closed == false){
          this.movSub.unsubscribe();
        }
    
        if (!this.movSub || this.movSub.closed){
                 
          this.movSub = this.clienteService.getCliente(dominio).subscribe(config =>{
              
            console.log("config"+config);

            if(config){
              
              let authObs: Observable<AuthResponseData>;
    
              authObs = this.authService.signup(email, password);
            
              authObs.subscribe(
                resData => {
                  console.log(resData);
                  this.isLoading = false;
                  this.error = null;
          
                  this.authService.verifyEmail(resData.idToken).subscribe(res =>{
                    console.log(res);
                  });
                  
                  this.toastr.success('Conta criada com sucesso! Em alguns instantes você receberá um e-mail para confirmação seu e-mail.', 'Sucesso!');
                },
                errorMessage => {
                  console.log(errorMessage);
                  this.error = errorMessage;
                  this.isLoading = false;
                }
              );
    
            }else{  
              this.isLoading = false;
              this.error = "Domínio não permitido! Favor contatar o administrador."
            }
              
           },error=>{
                console.log(error);
           });
    
        }

    },
    error => {
      console.log(error);
      this.error = this.authService.handleError(error.code);
      this.isLoading = false;
    }
  );

    form.reset();
  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }
}
