
export class Semana {

    public id: Number;
    public horas: Number;
 
    constructor(){
        
    }
}
