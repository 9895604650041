import { RecursoMC } from './recursomc';

export class MCDigital {

    public id: string;
    public cliente: string;
    public empresa: string;
    public impostoEmpresa: number;
    public siglamc: string;
    public risco: string;
    public percRisco: number;
    public periodoDe: Date;
    public periodoAte: Date;
    public recursos: RecursoMC[];
    public margem: number;
    public totalhoras: number;
    public custoprojeto: number;
    public valorvenda: number;
    public planejadas: number;
    public reembolsadas: number;
    public statusTecnico: number;
    public statusFinanceiro: number;
    public anexos: any[];
    public solicitante: string;
    public justificativa: string;
    public justificativaTec: string;
    public justificativaFin: string;
    public markup: number;
    public overhead: number;
    public siglaCliente: string;
    public siglaColaborador: string;
    public descricaoCR: string;
    public responsavelComercial: string;
    public ccVinculo: string;
    public versao: number;
    public ativo: boolean;
    public desconto: number;
    public incrementoFinanceiro: number;
    public condicaoPagamento: number;
    public comissao: number;
    public descontoMax: number;
    public descontoMaxRisco: number;
    public nomeCliente: string;
    public descricaoMC: string; 

    constructor(){
        this.margem = 20;
        this.custoprojeto = 0;
        this.valorvenda = 0;
        this.planejadas = 0;
        this.reembolsadas = 0;
        this.totalhoras = 0;
        this.incrementoFinanceiro = 0;
        this.desconto = 0;
    }
}
