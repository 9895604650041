import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FirebaseServiceProvider } from '../providers/firebase-service';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

//teste alteracao
  public firestore: AngularFirestore = this.fbService.dbCliente;

  constructor(private fbService: FirebaseServiceProvider) {
  }
  getCollection(entidade: string): AngularFirestoreCollection<any> {
    return this.firestore.collection(entidade);
  }

  listar(entidade: string, campo: string, value: any): Observable<any> {
    return this.firestore.collection<any>(entidade, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;

      if (campo && value) { query = query.where(campo, '==', value) };

      return query;
    }).valueChanges();
  }

  getGestor( value: any): Observable<any> {
    return this.firestore.collection<any>("perfil", ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where("descricao", '==', value);
      return query;
    }).valueChanges();
  }

  getPerfilGrupo(value: any): Observable<any> {
    return this.firestore.collection<any>("perfil", ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where("grupo", '==', value);
      return query;
    }).valueChanges();
  }

  getFuncao(): Observable<any> {
    return this.firestore.collection<any>("perfil", ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where("id", '>', '0');
      return query;
    }).valueChanges();
  }

  listarByFiltro(entidade: string, filtro: any[]): Observable<any> {
    return this.firestore.collection<any>(entidade, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if(filtro){
        for(let f of filtro){
          if (f.campo && f.operacao && f.value) { query = query.where(f.campo, f.operacao, f.value) };
        }
      }
      return query;
    }).valueChanges();
  }

  findByGeneric(entidade: string, campo: string, value: any): Observable<any> {
    return this.firestore.collection<any>(entidade, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;

      if (value) { query = query.where(campo, '==', value) };

      return query;
    }).valueChanges();
  }

  salvar(entidade: string, objeto: any) {
    let id1: string = Math.random().toString(36).slice(2);
    let id2: string = Math.random().toString(36).slice(2);
    let id = id1+"-"+id2;
    objeto.id = id;
    this.getCollection(entidade).doc<any>(id).set(Object.assign({}, objeto));
    return id;
  }

  atualizar(entidade: string, objeto: any) {
    let id: string = objeto.id;
    this.getCollection(entidade).doc<any>(id).set(Object.assign({}, objeto));
  }

  excluir(entidade: string, id: string) {
    this.getCollection(entidade).doc<any>(id).delete();
  }

  CustoHrProj(): Observable<any> {
    return this.firestore.collection<any>('custoHorasProjeto', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.orderBy('Codigo');
      return query;
    }).valueChanges();
  }

}
