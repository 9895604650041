interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Capacity',
    url: '/dashboard',
    icon: 'icon-home',
    badge: {
      variant: 'info',
      text: 'Capacity'
    }
  },
  {
    title: true,
    name: 'Cadastros'
  },
  {
    name: 'Colaboradores',
    url: '/cadastros/colaboradores',
    icon: 'icon-people'
  },
  {
    name: 'Perfil',
    url: '/cadastros/perfil',
    icon: 'icon-user'
  },
  {
    name: 'Centro de Custo',
    url: '/cadastros/centrodecusto',
    icon: 'icon-folder'
  },
  {
    name: 'Clientes',
    url: '/cadastros/clientes',
    icon: 'icon-organization'
  },
  {
    name: 'Vertical',
    url: '/cadastros/vertical',
    icon: 'icon-options-vertical'
  },
  {
    name: 'Feriados',
        url: '/cadastros/feriados',
        icon: 'icon-clock'
  },
  {
    name: 'Empresas',
        url: '/cadastros/empresas',
        icon: 'icon-grid'
  },
  {
    name: 'Riscos',
        url: '/cadastros/riscos',
        icon: 'icon-fire'
  },
  {
    name: 'MC Digital',
        url: '/cadastros/mcdigital',
        icon: 'icon-wallet'
  },
  {
    title: true,
    name: 'Relatórios'
  },
  {
    name: 'Alocação',
    url: '/relatorios/alocacao',
    icon: 'icon-people'
  },
  {
    name: 'Ausências',
    url: '/relatorios/ausencias',
    icon: 'icon-people'
  },
  {
    name: 'Controle de Projetos',
    url: '/relatorios/controle-projetos',
    icon: 'icon-star'
  },
 
  {
    name: 'Faturamento Anual',
    url: '/relatorios/faturamento-anual',
    icon: 'icon-doc'
  },
  
  {
    name: 'Faturamento Total',
    url: '/relatorios/faturamento-completo',
    icon: 'icon-docs'
  },
  {
    name: 'Dashboards',
    url: '/graficos',
    icon: 'icon-chart'
  }


];


/*
interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Capacity',
    url: '/dashboard',
    icon: 'icon-home',
    badge: {
      variant: 'info',
      text: 'Capacity'
    }
  },
  {
    title: true,
    name: 'Cadastros'
  },
  {
    name: 'Colaboradores',
    url: '/cadastros/colaboradores',
    icon: 'icon-people'
  },
  {
    name: 'Perfil',
    url: '/cadastros/perfil',
    icon: 'icon-user'
  },
  {
    name: 'Centro de Custo',
    url: '/cadastros/centrodecusto',
    icon: 'icon-docs'
  },
  {
    name: 'Clientes',
    url: '/cadastros/clientes',
    icon: 'icon-organization'
  },
  {
    name: 'Vertical',
    url: '/cadastros/vertical',
    icon: 'icon-options-vertical'
  },
  {
    name: 'Feriados',
        url: '/cadastros/feriados',
        icon: 'icon-clock'
  },
  {
    name: 'Empresas',
        url: '/cadastros/empresas',
        icon: 'icon-docs'
  },
  {
    name: 'Riscos',
        url: '/cadastros/riscos',
        icon: 'icon-docs'
  },
  {
    name: 'MC Digital',
        url: '/cadastros/mcdigital',
        icon: 'icon-docs'
  },
  {
    title: true,
    name: 'Relatórios'
  },
  {
    name: 'Alocação',
    url: '/relatorios/alocacao',
    icon: 'icon-people'
  },
  {
    name: 'Ausências',
    url: '/relatorios/ausencias',
    icon: 'icon-people'
  }

];
*/
