
export class Feriado {

    public id: string;
    public dataFeriado: Date;
    public descricao: string;

    constructor(){
        
    }
}
