import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';
import { Perfil } from '../model/perfil';

@Directive({
  selector: '[appCustoProjeto]'
})
export class CustoProjetoDirective implements OnInit{

  @Input() codigo: string;
  @Input() custoProjeto: any[];
  @Input() despesas: any[];
  @Input() mcDigital: any[];
  @Input() retorno: string;


  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){

    var total = 0;

    let achou ='N'
    for(var i = 0; i < this.custoProjeto.length; i++){
      if(this.custoProjeto[i].Codigo.trim() == this.codigo.trim()){
        total = this.custoProjeto[i].Custo;
        achou = 'S'
        break;
      }
    }
    console.log("****** ", achou, " - ", this.codigo.trim())

    /* isto soma o custo de despesas ao custo das horas para ter o total custo realizado
    for(var i = 0; i < this.despesas.length; i++){
      if(this.despesas[i].Codigo.trim() == this.codigo.trim()){
        total += this.despesas[i].Valor
        break;
      }
    }
    */

    if(total > 0 && this.retorno == "custo") {
      const text = this.render.createText(total.toFixed(2).toString());
      this.render.appendChild(this.elRef.nativeElement, text);
    }

    if(this.retorno == "margem"){
      var margem = 0;
      for(var i = 0; i < this.mcDigital.length; i++){
        if(this.mcDigital[i].siglamc.trim() == this.codigo.trim()){
          margem = (this.mcDigital[i].valorvenda - total ) / this.mcDigital[i].valorvenda  * 100

          console.log(this.mcDigital[i].valorvenda,' --- ', total)
          break;
        }
      }
      if(margem > 0){
        const text = this.render.createText(margem.toFixed(2).toString());
        this.render.appendChild(this.elRef.nativeElement, text);
      }
    }
  }




}

