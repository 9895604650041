
export class Risco {

    public id: string;
    public descricao: string;
    public percentual: Number;
    public descontoMax: Number;

    constructor(){

    }
}
