import { Directive, Input , ElementRef, Renderer2, OnInit, RendererStyleFlags2} from '@angular/core';

@Directive({
  selector: '[appCentroCusto]'
})
export class CentroCustoDirective implements OnInit{

  @Input() id: string;
  @Input() centrosdecustos: any[];

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){

      for(var i = 0; i < this.centrosdecustos.length; i++){

        if(this.centrosdecustos[i].id == this.id){

          const text = this.render.createText(this.centrosdecustos[i].descricaoMin.substring(0, 9));

          this.render.appendChild(this.elRef.nativeElement, text);

          //this.render.addClass(this.elRef.nativeElement, "cal-day-badge");
          this.render.setStyle(this.elRef.nativeElement, "background-color", "#000000", RendererStyleFlags2.Important);
          this.render.setStyle(this.elRef.nativeElement, "color", "#ffffff", RendererStyleFlags2.Important);
        }
      }


  }



}

