import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CrudService } from "../../services/crudService";
import { ModalService } from '../../services/modal.service';
import { MCDigital } from '../../model/mcdigital';
import { ConfirmationDialogService } from '../../components/confirmation-dialog/confirmation-dialog.service';
import { Notificacao } from '../../model/notificacao';
import { CentroDeCusto } from '../../model/centrodecusto';
import { PerfilCCusto } from '../../model/PerfilCCusto';

@Component({
  selector: 'app-mcdigital',
  templateUrl: './mcdigital.component.html'

})
export class MCDigitalComponent implements OnInit {

  movSub: Subscription;
  mcdigital: MCDigital[];
  clientes: any[];
  subCliente: Subscription;
  colaboradores: any[];
  subColaboradores: Subscription;
  usuarioLogado: any;
  user: any;
  movSubSalvar: Subscription;

  constructor(
      private spinner: NgxSpinnerService,
      private CrudService: CrudService,
      private modalService: ModalService,
      private cadastroService: CrudService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService
  ){
    this.user = JSON.parse(localStorage.getItem('userNextCapacity'));
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userNextCapacity'));
    this.getClientes();
    this.getColaboradores();
  }

  getColaboradores(){

    this.colaboradores = [];

    if (this.subColaboradores && this.subColaboradores.closed == false){
      this.subColaboradores.unsubscribe();
    }

    if (!this.subColaboradores || this.subColaboradores.closed){

      this.subColaboradores = this.CrudService.listar("colaborador", "ativo", true).subscribe(
        (result: any) =>{
          this.colaboradores =  result;

          this.colaboradores.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
          this.setUsuarioLogado();
       },error=>{
            console.log(error);
       });

    }

  }

  setUsuarioLogado(){

    for(var i = 0; i < this.colaboradores.length; i++){
      if(this.colaboradores[i].email == this.user.email){

        this.usuarioLogado = {
          gestor : this.colaboradores[i].gestor,
          mediador : this.colaboradores[i].mediador,
          colaboradorID : this.colaboradores[i].id,
          email : this.colaboradores[i].email,
          id : this.colaboradores[i].id,
          nome: this.colaboradores[i].nome,
          financeiro: this.colaboradores[i].financeiro,
          tecnico: this.colaboradores[i].tecnico
        }

      }
    }



    if(!this.usuarioLogado){
      this.toastr.warning('Colaborador não localizado! Favor contatar o administrador.', 'Atenção!');
    }

  }

  async getmcdigital(){

    this.mcdigital = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();

      this.movSub = this.CrudService.listar("mcdigital", null, null).subscribe(
        (result: any) =>{

          this.mcdigital =  result;
          this.mcdigital.sort((a,b) => (a.siglamc > b.siglamc) ? 1 : ((b.siglamc > a.siglamc) ? -1 : 0));

          for(var i = 0; i < this.mcdigital.length; i++){
            for(var z = 0; z < this.clientes.length; z++){
              if(this.mcdigital[i].cliente == this.clientes[z].id){
                this.mcdigital[i].nomeCliente = this.clientes[z].nome;
              }
            }
          }

          this.spinner.hide();

       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }

  }

  async getClientes(){

    this.clientes = [];

    if (this.subCliente && this.subCliente.closed == false){
      this.subCliente.unsubscribe();
    }

    if (!this.subCliente || this.subCliente.closed){

      this.subCliente = this.cadastroService.listar("cliente", "ativo", true).subscribe(
        (result: any) =>{
          this.clientes =  result;
          this.clientes.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));


          this.getmcdigital();
       },error=>{
            console.log(error);
       });

    }

  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (this.subCliente && this.subCliente.closed == false){
      this.subCliente.unsubscribe();
    }
  }

  novomcdigital(){
    this.modalService.modalDetalhesMCDigital.emit(null);
  }

  editar(mcdigital: MCDigital){
    this.modalService.modalDetalhesMCDigital.emit(mcdigital);
   }

  desmembramento(mcdigital: MCDigital){
    this.modalService.modalDesmembramento.emit(mcdigital);
  }


  anexos(mcdigital: MCDigital){
    this.modalService.modalAnexosMCDigital.emit(mcdigital);
  }

  solicitar(mcdigital: MCDigital){

    this.confirmationDialogService.confirm('Confirmar?', 'Deseja solicitar aprovação?')
    .then((confirmed) =>{

      if(confirmed){

        if(mcdigital.anexos && mcdigital.anexos.length > 0){

          if(mcdigital.statusFinanceiro == 0 || mcdigital.statusTecnico  == 0 ||  mcdigital.statusFinanceiro == 3 || mcdigital.statusTecnico == 3){

              mcdigital.statusFinanceiro = 1;
              this.enviaNotificacaoSolicitacao(mcdigital, false, true);

              mcdigital.statusTecnico = 1;
              this.enviaNotificacaoSolicitacao(mcdigital, true, false);

              this.cadastroService.atualizar("mcdigital", mcdigital);

              this.toastr.success('Solicitação de aprovação enviada com sucesso', 'Sucesso!');
          }else{
            this.toastr.warning('Solicitação de aprovação já enviada!', 'Atenção!');
          }

        }else{
          this.toastr.warning('É necessário que haja pelo menos um anexo na MC Digital', 'Atenção!');
        }

      }

    })
    .catch(() => {
      console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });


  }

  excluir(mcdigital: MCDigital){

    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{

        if(confirmed){
          this.cadastroService.excluir("mcdigital", mcdigital.id);

          this.toastr.success('MCDigital excluído com sucesso!', 'Sucesso!');
          this.getmcdigital();

        }

      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });

  }

  criarCentro(mcdigital: MCDigital){

    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja criar centro de custo ?')
    .then((confirmed) =>{

      if(confirmed){
        this.verificaCriaCentrodecusto(mcdigital);

      }

    })
    .catch(() => {
      console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    });


  }

  aprovarTecnico(mcdigital: MCDigital){

    if(mcdigital.justificativaTec && mcdigital.justificativaTec != ""){
      this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja aprovar ? (TECNICO)')
      .then((confirmed) =>{

        if(confirmed){

          if(mcdigital.statusTecnico == 1){
            mcdigital.statusTecnico = 2;
            this.cadastroService.atualizar("mcdigital", mcdigital);

            this.toastr.success('MC Digital aprovado (TECNICO) !', 'Sucesso!');
            this.getmcdigital();

            this.enviaNotificacaoAprovacao(mcdigital, true, "TECNICO");

          }
        }

      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    }else{
      this.toastr.warning('Necessário informar uma justificativa !', 'Atenção!');
    }

  }

  aprovarFinanceiro(mcdigital: MCDigital){

    if(mcdigital.justificativaFin && mcdigital.justificativaFin != ""){
      this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja aprovar ? (FINANCEIRO)')
      .then((confirmed) =>{

        if(confirmed){

          if(mcdigital.statusFinanceiro == 1){
            mcdigital.statusFinanceiro = 2;
            this.cadastroService.atualizar("mcdigital", mcdigital);

            this.toastr.success('MC Digital aprovado (FINANCEIRO) !', 'Sucesso!');
            this.getmcdigital();

            this.enviaNotificacaoAprovacao(mcdigital, true, "FINANCEIRO");

          }


        }

      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    }else{
      this.toastr.warning('Necessário informar uma justificativa !', 'Atenção!');
    }


  }

  verificaCriaCentrodecusto(mcdigital: MCDigital){

    if(mcdigital.statusFinanceiro == 2 && mcdigital.statusTecnico == 2){
      this.criaCentrodecusto(mcdigital)
    }

  }

  criaCentrodecusto(mcdigital: MCDigital){

    let objCentrocusto: CentroDeCusto = new CentroDeCusto();

    let horas = 0;
    for(let r of mcdigital.recursos){
      for(let s of r.semanas){
        horas = horas + Number(s.horas);
      }
    }

    objCentrocusto.ativo = false;
    objCentrocusto.cliente = mcdigital.cliente;
    objCentrocusto.descricao = mcdigital.descricaoMC;
    objCentrocusto.descricaoMin = mcdigital.siglamc;
    objCentrocusto.horasConsumidas = 0;
    objCentrocusto.horasVendidas = horas;
    objCentrocusto.responsavel = mcdigital.solicitante;
    objCentrocusto.valorCustoPrevisto = mcdigital.custoprojeto;
    objCentrocusto.valorCustoReal = 0;
    objCentrocusto.valorVenda = mcdigital.valorvenda;
    objCentrocusto.vertical = "";

    if (this.movSubSalvar && this.movSubSalvar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed){

      this.movSubSalvar = this.cadastroService.findByGeneric("centrodecusto", "descricaoMin", objCentrocusto.descricaoMin).subscribe(
        (result: any) =>{

          if(result.length > 0){
            this.toastr.error('Esta sigla já esta sendo utilizado por outro centro de custo!', 'Atenção!');

          }else{
           let id =  this.cadastroService.salvar("centrodecusto", objCentrocusto);


           for(let r of mcdigital.recursos){

              let perfilCCusto: PerfilCCusto = new PerfilCCusto();
              perfilCCusto.centrodecusto = id;
              perfilCCusto.perfil = r.id;
              perfilCCusto.tipo = r.tipo;
              perfilCCusto.horas = horas;
              perfilCCusto.custo = horas*r.custo;
              this.cadastroService.salvar("perfilccusto", perfilCCusto);

           }

            mcdigital.statusTecnico = 4;
            mcdigital.statusFinanceiro = 4;
            this.cadastroService.atualizar("mcdigital", mcdigital);

            this.toastr.success('Centro de custo criado com sucesso!', 'Sucesso!');

            this.enviaNotificacaoCadastral(mcdigital);

            this.movSubSalvar.unsubscribe();
          }

       },error=>{
            console.log(error);
       });

    }

  }

  reprovarTecnico(mcdigital: MCDigital){

    if(mcdigital.justificativaTec && mcdigital.justificativaTec != ""){
      this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja reprovar ? (TECNICO)')
      .then((confirmed) =>{

        if(confirmed){

          if(mcdigital.statusTecnico == 1){
            mcdigital.statusTecnico = 3;
            this.cadastroService.atualizar("mcdigital", mcdigital);

            this.toastr.success('MC Digital reprovado (TECNICO) !', 'Sucesso!');
            this.getmcdigital();

            this.enviaNotificacaoAprovacao(mcdigital, false, "TECNICO");
          }


        }

      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    }else{
      this.toastr.warning('Necessário informar uma justificativa !', 'Atenção!');
    }

  }

  reprovarFinanceiro(mcdigital: MCDigital){

    if(mcdigital.justificativaFin && mcdigital.justificativaFin != ""){
      this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja reprovar ? (FINANCEIRO)')
      .then((confirmed) =>{

        if(confirmed){

          if(mcdigital.statusFinanceiro == 1){
            mcdigital.statusFinanceiro = 3;
            this.cadastroService.atualizar("mcdigital", mcdigital);

            this.toastr.success('MC Digital reprovado (FINANCEIRO) !', 'Sucesso!');
            this.getmcdigital();

            this.enviaNotificacaoAprovacao(mcdigital, false, "FINANCEIRO");
          }

        }

      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    }else{
      this.toastr.warning('Necessário informar uma justificativa !', 'Atenção!');
    }

  }

  enviaNotificacaoSolicitacao(mcdigital: MCDigital, tecnico: boolean, financeiro: boolean){

    let notificacao = new Notificacao();
    notificacao.assunto = "Next Capacity - Solicitacao de Aprovacao MC Digital";

    let tecnicos = []
    let financeiros = [];
    let solicitanteNome = "";
    let clienteNome = "";

    for(var c=0; c<this.clientes.length; c++){
      if(mcdigital.cliente == this.clientes[c].id){
        clienteNome = this.clientes[c].nome;
      }
    }

    for(var c=0; c<this.colaboradores.length; c++){

      if(mcdigital.solicitante == this.colaboradores[c].id){
        solicitanteNome = this.colaboradores[c].nome;
      }

      if(this.colaboradores[c].tecnico){
        tecnicos.push(this.colaboradores[c]);
      }

      if(this.colaboradores[c].financeiro){
        financeiros.push(this.colaboradores[c]);
      }
    }

    if(tecnico){
      for(var t=0; t<tecnicos.length; t++){
        notificacao.link = "https://capacity.nextmobile.com.br/";

        let msg = "<h3><b>Solicitante:</b> "+solicitanteNome+"</h3>"+
                  "<h3><b>MC Digital:</b> "+mcdigital.siglamc+"</h3>"+
                  "<h3><b>Cliente:</b> "+clienteNome+"</h3>"+
                  "<h3><b>Valor Venda:</b> R$ "+mcdigital.valorvenda.toFixed(2)+"</h3>";

        notificacao.msg = msg;
        notificacao.status = "P";
        notificacao.titulo = "Next Capacity - Solicitação de Aprovação MC Digital";
        notificacao.emailDestino = tecnicos[t].email;

        this.CrudService.salvar("notificacoes", notificacao);
      }
    }

    if(financeiro){
      for(var t=0; t<financeiros.length; t++){
        notificacao.link = "https://capacity.nextmobile.com.br/";

        let msg = "<h3><b>Solicitante:</b> "+solicitanteNome+"</h3>"+
                  "<h3><b>MC Digital:</b> "+mcdigital.siglamc+"</h3>"+
                  "<h3><b>Cliente:</b> "+clienteNome+"</h3>"+
                  "<h3><b>Valor Venda:</b> R$ "+mcdigital.valorvenda.toFixed(2)+"</h3>";

        notificacao.msg = msg;
        notificacao.status = "P";
        notificacao.titulo = "Next Capacity - Solicitacao de Aprovação MC Digital";
        notificacao.emailDestino = financeiros[t].email;

        this.CrudService.salvar("notificacoes", notificacao);
      }
    }

  }

  enviaNotificacaoAprovacao(mcdigital: MCDigital, aprovado: boolean, tipo: string){

    let notificacao = new Notificacao();

    if(aprovado){
      notificacao.assunto = "Next Capacity - MC Digital ("+tipo+") Aprovada !";
    }else{
      notificacao.assunto = "Next Capacity - MC Digital ("+tipo+") Reprovada !";
    }

    let clienteNome = "";
    for(var c=0; c<this.clientes.length; c++){
      if(mcdigital.cliente == this.clientes[c].id){
        clienteNome = this.clientes[c].nome;
      }
    }


    let emailSolicitante = "";
    let aprovador = "";
    for(var c=0; c<this.colaboradores.length; c++){

      if(mcdigital.solicitante == this.colaboradores[c].id){
        emailSolicitante = this.colaboradores[c].email;
      }

      if(this.usuarioLogado.id == this.colaboradores[c].id){
        aprovador = this.colaboradores[c].nome;
      }
    }

    notificacao.link = "https://capacity.nextmobile.com.br/";

    let msg="";

    if(aprovado){
       msg = "<h3><b>Quem aprovou ("+tipo+") :</b> "+aprovador+"</h3>"+
      "<h3><b>MC Digital:</b> "+mcdigital.siglamc+"</h3>"+
      "<h3><b>Cliente:</b> "+clienteNome+"</h3>"+
      "<h3><b>Valor Venda:</b> R$ "+mcdigital.valorvenda.toFixed(2)+"</h3>";
    }else{
       msg = "<h3><b>Quem reprovou ("+tipo+") : </b> "+aprovador+"</h3>"+
      "<h3><b>MC Digital:</b> "+mcdigital.siglamc+"</h3>"+
      "<h3><b>Cliente:</b> "+clienteNome+"</h3>"+
      "<h3><b>Valor Venda:</b> R$ "+mcdigital.valorvenda.toFixed(2)+"</h3>";
    }

    notificacao.msg = msg;
    notificacao.status = "P";

    if(aprovado){
      notificacao.titulo = "Next Capacity - MC Digital ("+tipo+") <span style='color: green;'>Aprovada</span> !";
    }else{
      notificacao.titulo = "Next Capacity - MC Digital ("+tipo+") <span style='color: red;'>Reprovada</span> !";
    }

    notificacao.emailDestino = emailSolicitante;

    this.CrudService.salvar("notificacoes", notificacao);


  }


  enviaNotificacaoCadastral(mcdigital: MCDigital){

    let clienteNome = "";
    for(var c=0; c<this.clientes.length; c++){
      if(mcdigital.cliente == this.clientes[c].id){
        clienteNome = this.clientes[c].nome;
      }
    }

    let emailSolicitante = "";
    let aprovador = "";
    let cadastradores = [];
    for(var c=0; c<this.colaboradores.length; c++){

      if(this.colaboradores[c].cadastrador){
        cadastradores.push(this.colaboradores[c]);
      }

      if(this.usuarioLogado.id == this.colaboradores[c].id){
        aprovador = this.colaboradores[c].nome;
      }
    }

    for(let c of cadastradores){

      let notificacao = new Notificacao();

      notificacao.assunto = "Next Capacity - MC Digital - Novo Centro de Custo";

      notificacao.link = "https://capacity.nextmobile.com.br/";

      let msg="";

        msg = "<h3><b>Quem criou: </b> "+aprovador+"</h3>"+
      "<h3><b>Sigla Centro de custo:</b> "+mcdigital.siglamc+"</h3>"+
      "<h3><b>Cliente:</b> "+clienteNome+"</h3>"+
      "<h3><b>Valor Venda:</b> R$ "+mcdigital.valorvenda.toFixed(2)+"</h3>";

      notificacao.msg = msg;
      notificacao.status = "P";

      notificacao.titulo = "Next Capacity - MC Digital - Novo Centro de Custo";

      notificacao.emailDestino = c.email;

      this.CrudService.salvar("notificacoes", notificacao);

    }



  }
}
