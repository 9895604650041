import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { MCDigital } from '../../../model/mcdigital';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../../../components/confirmation-dialog/confirmation-dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';
 
@Component({
  selector: 'app-anexos-mcdigital',
  templateUrl: 'mcdigital-anexos.component.html'
})   
export class MCDigitalAnexosComponent implements OnInit{
      
  @ViewChild('modalmcdigitalanexo', {static: false}) public modalmcdigitalanexo: ModalDirective;
   
  sub: boolean = false;
  titulo: string = "Anexos - MC Digital";
  movSubAlterar: Subscription;
  _mcdigital: any;
  
  constructor(
    private modalService: ModalService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    private cadastroService: CrudService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalAnexosMCDigital.subscribe(
        (p: MCDigital) => {

          this._mcdigital = p;
          this.limpaModal();
          this.modalmcdigitalanexo.show();
        }
      );
    } 
 
  }

  excluir(anexo: any){
    
    if(this._mcdigital.statusTecnico > 0 || this._mcdigital.statusFinanceiro > 0){
      this.toastr.error('O status dessa MC Digital não permite excluir anexos!', 'Atenção!');
    }else{
      this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          
          this._mcdigital.anexos = this._mcdigital.anexos.filter(r => r !== anexo);
  
          this.cadastroService.atualizar("mcdigital", this._mcdigital);
  
          this.toastr.success('Anexo excluído com sucesso!', 'Sucesso!');
          
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    }
    

  }

  hideModal(){
    this.modalmcdigitalanexo.hide();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

 
}
