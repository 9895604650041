import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeriadosComponent } from './feriados/feriados.component';
import { ColaboradoresComponent } from './colaboradores/colaboradores.component';
import { ClientesComponent } from './clientes/clientes.component';
import { VerticalComponent } from './vertical/vertical.component';
import { PerfilComponent } from './perfil/perfil.component';
import { CentroDeCustoComponent } from './centrodecusto/centrodecusto.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { RiscosComponent } from './riscos/riscos.component';
import { MCDigitalComponent } from './mcdigital/mcdigital.component';
const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Cadastros'
    },
    children: [
      {
        path: 'colaboradores',
        component: ColaboradoresComponent,
        data: {
          title: 'Colaboradores'
        }
      },
      {
        path: 'perfil',
        component: PerfilComponent,
        data: {
          title: 'perfil'
        }
      },
      {
        path: 'clientes',
        component: ClientesComponent,
        data: {
          title: 'Clientes'
        }
      },
      {
        path: 'vertical',
        component: VerticalComponent,
        data: {
          title: 'vertical'
        }
      },
      {
        path: 'centrodecusto',
        component: CentroDeCustoComponent,
        data: {
          title: 'centrodecusto'
        }
      },
      {
        path: 'feriados',
        component: FeriadosComponent,
        data: {
          title: 'Feriados'
        }
      },
      {
        path: 'empresas',
        component: EmpresasComponent,
        data: {
          title: 'Empresas'
        }
      },
      {
        path: 'riscos',
        component: RiscosComponent,
        data: {
          title: 'Riscos'
        }
      },
      {
        path: 'mcdigital',
        component: MCDigitalComponent,
        data: {
          title: 'MC Digital'
        }
      }
      
      
      
    ]
  }
];
 
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CadastrosRoutingModule {}
