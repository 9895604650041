import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CrudService } from '../../services/crudService';
import { EventoCapacity } from '../../model/eventoCapacity';
import { NgxSpinnerService } from 'ngx-spinner';
 
@Component({
  selector: 'app-ausencias',
  templateUrl: './ausencias.component.html',
  styleUrls: ['./ausencias.component.scss']
})  
export class AusenciasComponent implements OnInit {
      
  movSub: Subscription;
  ausencias: EventoCapacity[];
  colaboradores: any[];
  colaborador: string;

  isCollapsed: boolean = true;
  subColaboradores: Subscription;

  _colaborador: any;

  periodoDe = new Date(new Date().getFullYear(),  new Date().getMonth(), 1, 0, 0, 0, 0);
  periodoAte = new Date(new Date().getFullYear(),  new Date().getMonth()+1, 1, 0, 0, 0, 0);

  usuarioLogado: any;
  movSubAusencia: Subscription;

  constructor(
      private crudService: CrudService,
      private spinner: NgxSpinnerService
  ){
    
  }

  ngOnInit() {
    this._colaborador = "Todos";

    this.spinner.show();
    this.getColaboradores();
   
  }

  async getAusencias(){
 
    this.ausencias = [];

    if (this.movSubAusencia && this.movSubAusencia.closed == false){
      this.movSubAusencia.unsubscribe();
    }

    if (!this.movSubAusencia || this.movSubAusencia.closed){

     let filtro = [];
     
     
     if(this._colaborador && this._colaborador != "Todos"){
      let _col  ={
        "campo" : "colaborador",
        "operacao" : "==",
        "value" : this._colaborador
      }
      filtro.push(_col);
     }

    this.movSubAusencia = this.crudService.listarByFiltro("ausencia", filtro).subscribe(
        (result: any) =>{

          
          this.ausencias = [];

          for(var a = 0; a < result.length; a++){

            let existeAusencia = false;
            let dataDe = new Date( result[a].periodoDe.seconds*1000);
            let dataAte = new Date( result[a].periodoAte.seconds*1000);

            if( dataDe >= this.periodoDe &&  dataAte <= this.periodoAte){
              existeAusencia = true;
            } 

            if(existeAusencia){
              this.ausencias.push(result[a]);
            }
          }

          this.spinner.hide();
          
         
       },error=>{
            console.log(error);
       });

    }
    

  }

  onChangeColaborador(){
    this.spinner.show();
    this.getAusencias();
  }

  onChangeDateDe(De: Date){
    let _De = new Date(De);
    this.periodoDe = new Date(_De.getFullYear(),  _De.getMonth(), _De.getDate()+1, 0, 0, 0, 0);
    this.spinner.show();
    this.getAusencias();
  }

  onChangeDateAte(Ate: Date){
    let _Ate = new Date(Ate);
    this.periodoAte = new Date(_Ate.getFullYear(),  _Ate.getMonth(), _Ate.getDate()+1, 0, 0, 0, 0);
    this.spinner.show();
    this.getAusencias();
  }

  async getColaboradores(){
 
    this.colaboradores = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    } 

    if (!this.movSub || this.movSub.closed){

     
      this.movSub = this.crudService.listar("colaborador", null, null).subscribe(
        (result: any) =>{

          this.colaboradores =  result;
          this.colaboradores.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
          this.checkUsuarioLogado();

        
                  
       },error=>{
            console.log(error);
       });

    }
    

  }

  checkUsuarioLogado(){

    let user = JSON.parse(localStorage.getItem('userNextCapacity'));

    for(var i = 0; i < this.colaboradores.length; i++){
      if(this.colaboradores[i].email == user.email){

        this.usuarioLogado = {
          gestor : this.colaboradores[i].gestor,
          mediador : this.colaboradores[i].mediador,
          cadastrador : this.colaboradores[i].cadastrador,
          colaboradorID : this.colaboradores[i].id,
          email : this.colaboradores[i].email,
          nome: this.colaboradores[i].nome,
          gestorDireto: this.colaboradores[i].gestorDireto,
          financeiro: this.colaboradores[i].financeiro,
          tecnico: this.colaboradores[i].tecnico,
          comercial: this.colaboradores[i].comercial,
          rh: this.colaboradores[i].rh
        }
        
        if(this.usuarioLogado && !this.usuarioLogado.gestor && !this.usuarioLogado.mediador && !this.usuarioLogado.rh){
          this._colaborador = this.usuarioLogado.email;
        }
      }
    }
      
    this.getAusencias();
  }
  
  ngOnDestroy(){
    if (this.movSubAusencia && this.movSubAusencia.closed == false){
      this.movSubAusencia.unsubscribe();
    }

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }


}
