// Angular
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Dropdowns Component
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Components Routing
import { CadastrosRoutingModule } from './cadastros-routing.module';
import { TableModule } from 'primeng/table';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from '../auth/auth-interceptor.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ConfirmationDialogService } from '../components/confirmation-dialog/confirmation-dialog.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import DirectivesModule from '../directives/directives.module';
import { FeriadosComponent } from './feriados/feriados.component';
import { FeriadosDetalhesComponent } from './feriados/feriados-detalhes/feriados-detalhes.component';
import { ColaboradoresComponent } from './colaboradores/colaboradores.component';
import { ColaboradoresDetalhesComponent } from './colaboradores/colaboradores-detalhes/colaboradores-detalhes.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ClientesDetalhesComponent } from './clientes/clientes-detalhes/clientes-detalhes.component';
import { VerticalComponent } from './vertical/vertical.component';
import { VerticalDetalhesComponent } from './vertical/vertical-detalhes/vertical-detalhes.component';
import { PerfilComponent } from './perfil/perfil.component';
import { PerfilDetalhesComponent } from './perfil/perfil-detalhes/perfil-detalhes.component';
import { CentroDeCustoComponent } from './centrodecusto/centrodecusto.component';
import { PerfilCCustoComponent } from './centrodecusto/perfil-ccusto/perfil-ccusto.component';
import { CentroDeCustoDetalhesComponent } from './centrodecusto/centrodecusto-detalhes/centrodecusto-detalhes.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { EmpresasDetalhesComponent } from './empresas/empresas-detalhes/empresas-detalhes.component';
import { RiscosComponent } from './riscos/riscos.component';
import { RiscosDetalhesComponent } from './riscos/riscos-detalhes/riscos-detalhes.component';
import { MCDigitalComponent } from './mcdigital/mcdigital.component';
import { MCDigitalDetalhesComponent } from './mcdigital/mcdigital-detalhes/mcdigital-detalhes.component';
import { FileUploadComponent } from '../components/file-upload/file-upload';
import { MCDigitalAnexosComponent } from './mcdigital/mcdigital-anexos/mcdigital-anexos.component';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { McdigitalDesmembramentoComponent } from './mcdigital/mcdigital-desmembramento/mcdigital-desmembramento.component';

registerLocaleData(localePt);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CadastrosRoutingModule,
    BsDropdownModule.forRoot(),
    TableModule,
    NgxSpinnerModule,
    ModalModule.forRoot(),
    AngularFireAuthModule,
    NgxDropzoneModule,
    NgbModule,
    DirectivesModule,
    ReactiveFormsModule
  ],
  declarations: [
    FeriadosComponent,
    FeriadosDetalhesComponent,
    ColaboradoresComponent,
    ColaboradoresDetalhesComponent,
    ClientesComponent,
    ClientesDetalhesComponent,
    VerticalComponent,
    VerticalDetalhesComponent,
    PerfilComponent,
    PerfilDetalhesComponent,
    CentroDeCustoComponent,
    CentroDeCustoDetalhesComponent,
    PerfilCCustoComponent,
    EmpresasComponent,
    EmpresasDetalhesComponent,
    RiscosComponent,
    RiscosDetalhesComponent,
    MCDigitalComponent,
    MCDigitalDetalhesComponent,
    FileUploadComponent,
    MCDigitalAnexosComponent,
    McdigitalDesmembramentoComponent

  ],
  providers: [
    ConfirmationDialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: LOCALE_ID, useValue: 'pt-BR'
    }
  ]
})
export class CadastrosModule { }
