import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CrudService } from '../../services/crudService';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { EventoCapacity } from '../../model/eventoCapacity';
import { Colaborador } from './../../model/colaborador';


@Component({
  selector: 'app-graficos',
  templateUrl: 'graficos.component.html',
  styleUrls: ['./graficos.component.scss']
})

export class GraficosComponent {
  movSubCentro: Subscription;
  movSubFunc: Subscription;
  movSubCol: Subscription;
  movSubCli: Subscription;
  movSubMcd: Subscription;
  movHoras: Subscription;
  movPerfil: Subscription;
  movCustoProjeto: Subscription;
  movFaturamento: Subscription;
  centrodecustos: any[];
  clientes: any[];
  colaboradores: any[];
  grupoGestor: any[];
  mcdigitalAll: any[];
  faturamento: any[];
  perfis: any[];
  horas: any[];
  custoProjeto: any[]; //*
  idPerf: string;
  _eventoCapacity: EventoCapacity = new EventoCapacity();
  _eventoColaborador: Colaborador = new Colaborador();
  opAtivo: string;
  opGraph: string;
  boolOp: boolean;
  maxY: number;
  label1: string;
  label2: string;
  label3: string;
  largBar: number;
  fator: number;

  ldados: any[] = [];
  dados: any[] = [];
  dados2: any[] = [];
  dados1: any[] = [];
  dados3: any[] = [];
  dadosT: any[] = [];
  custoProj: any[] = [];

  public barChartOptions: any = {};
  public barChartLabels: String[] = [];
  public barChartType = 'bar';
  public barChartLegend = true;
  public titulo = ""
  public legenda = true
  public larguraG = "3600px"; 
  public alturaG = "350px";



  public barChartData: ChartDataSets[] = [
    { data: this.dados1 },
    { data: this.dados2 },
    { data: this.dados3 }
  ];


  constructor(
    private crudService: CrudService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this._eventoCapacity.cliente = "Todos";
    this._eventoColaborador.nome = "Todos";
    this.opAtivo = "Todos";
    this.setGraph();
    this.getColaboradores();
  }

  async setGraph() {
    this.barChartOptions = {
      scaleShowVerticalLines: false,
      responsive: true,
      backgroundColor: "#300",
      maintainAspectRatio: false,
      legend: {
        display: this.legenda,
        labels: {
          align: 'start',
          position: 'left'
        }
      },
      scales: {
        xAxes: [{
          barThickness: this.largBar,
          gridLines: {
            drawOnChartArea: true
          },
          distribution: 'series',
          ticks: {
            autoSkip: false,
            max: 50,
            min: 50,
            stepSize: 50,
          }
        }
        ],
        yAxes: [{
          display: true,
          type: 'linear',
          gridLines: {
            drawOnChartArea: true
          },
          ticks: {
            autoSkip: true,
            max: this.maxY,
            min: 0,
            callback: function (value, index, values) {
              return Number(value.toString());
            }
          }
        }
        ],
      }
    };
  }

  async getCentrodecustos() {
    this.spinner.show();
    this.centrodecustos = [];
    if (this.movSubCentro && this.movSubCentro.closed == false) {
      this.movSubCentro.unsubscribe();
    }
    if (!this.movSubCentro || this.movSubCentro.closed) {
      let filtro = [];

      let relat = {
        "campo": "relatorio",
        "operacao": "==",
        "value": true
      }
      filtro.push(relat);

      if (this.opAtivo && this.opAtivo != "Todos") {
        this.boolOp = JSON.parse(this.opAtivo)
        let ativo = {
          "campo": "ativo",
          "operacao": "==",
          "value": this.boolOp
        }
        filtro.push(ativo);
      }
      if (this._eventoColaborador.nome && this._eventoColaborador.nome != "Todos") {
        let colab = {
          "campo": "responsavel",
          "operacao": "==",
          "value": this._eventoColaborador.nome
        }
        filtro.push(colab);
      }
      if (this._eventoCapacity.cliente && this._eventoCapacity.cliente != "Todos") {
        let cliente = {
          "campo": "cliente",
          "operacao": "==",
          "value": this._eventoCapacity.cliente
        }
        filtro.push(cliente);
      }

      if (!this.movSubCentro || this.movSubCentro.closed) {
        this.movSubCentro = this.crudService.listarByFiltro("centrodecusto", filtro).subscribe(
          (result: any) => {

            this.centrodecustos = result;

            if (this.centrodecustos.length > 0) {

              if (filtro.length == 1) {
                this.fator = 1050/this.centrodecustos.length;
              } else {
                this.fator = 90;
              }
              console.log("this.fator ", this.fator )

              this.centrodecustos.sort((a, b) => (a.descricaoMin > b.descricaoMin) ? 1 : ((b.descricaoMin > a.descricaoMin) ? -1 : 0));
              this.spinner.hide();
              let _centrodecusto: any = [];
              this.barChartLabels = [];
              this.maxY = 0;

              if (this.opGraph && (this.opGraph == "Horas" || this.opGraph == "Horas%")) {
                this.titulo = "Consumo de Horas"
                this.legenda = true
                this.maxY = 0;
                this.largBar = 7;
                this.barChartData[0].label = 'DEV';
                this.barChartData[1].label = 'FUN';
                this.barChartData[2].label = 'GP';
                this.barChartData[0].backgroundColor = "#66FF33";
                this.barChartData[1].backgroundColor = "#FF4500";
                this.barChartData[2].backgroundColor = "#3333FF";
                for (var i = 0; i < this.centrodecustos.length; i++) {
                  this.barChartLabels.push(this.centrodecustos[i].descricaoMin);
                  this.dadosT[i] = 0;
                  this.dados1[i] = 0;
                  this.dados2[i] = 0;
                  this.dados3[i] = 0;
          
                  for (var n = 0; n < this.horas.length; n++) {
                    if (this.centrodecustos[i].descricaoMin.trim() == this.horas[n].Projeto.trim() && this.centrodecustos[i].relatorio == true) {

                      if (this.maxY < this.horas[n].HoraDecimal) { this.maxY = this.horas[n].HoraDecimal }

                      if (this.horas[n].Grupo == 'DESENVOLVEDOR') {
                        this.dados1[i] = this.horas[n].HoraDecimal;
                        this.dadosT[i] += this.horas[n].HoraDecimal;
                      }

                      if (this.horas[n].Grupo == 'FUNCIONAL') {
                        this.dados2[i] = this.horas[n].HoraDecimal;
                        this.dadosT[i] += this.horas[n].HoraDecimal;
                      }
                      if (this.horas[n].Grupo == 'GP') {
                        this.dados3[i] = this.horas[n].HoraDecimal;
                        this.dadosT[i] += this.horas[n].HoraDecimal;
                      }

                      

                    }
                  }

                  if (this.opGraph == "Horas%") {
                    this.maxY = 0;
                    for (var n = 0; n < this.horas.length; n++) {
                      if (this.centrodecustos[i].descricaoMin.trim() == this.horas[n].Projeto.trim()) {

                        let valorD = 0;
                        let valorF = 0;
                        let valorG = 0;
                        if (this.horas[n].Grupo == 'DESENVOLVEDOR') { this.dados1[i] = this.horas[n].HoraDecimal * 100 / this.dadosT[i] };
                        if (this.horas[n].Grupo == 'FUNCIONAL') { this.dados2[i] = this.horas[n].HoraDecimal * 100 / this.dadosT[i] };
                        if (this.horas[n].Grupo == 'GP') { this.dados3[i] = this.horas[n].HoraDecimal * 100 / this.dadosT[i] };
                      }
                    }
                  }
                }
              }

              /*
              if(this.opGraph && this.opGraph == "HorasVT"){
                this.titulo =  "Custo/Projeto em R$";
                this.legenda = false;
                this.maxY = 0;
                this.largBar = 15;
                this.barChartData[1].label = ' ';
                for(var i = 0; i < this.centrodecustos.length; i++){
                  this.barChartLabels.push(this.centrodecustos[i].descricaoMin);
                  this.dados1[i] = 0;
                  this.dados2[i] = 0;
                  this.dados3[i] = 0;
                  let referCod = this.centrodecustos[i].descricaoMin.trim();
                  let valor = 0;
                  for(var n = 0; n < this.custoProjeto.length; n++){
                    if(referCod == this.custoProjeto[n].Codigo.trim()){
                     valor =  this.custoProjeto[n].Custo;
                     break;
                    }
                  }
                  this.dados2[i] =valor;
                  if(this.maxY < this.dados2[i]){this.maxY = this.dados2[i]}
                }
              }
              */

              if (this.opGraph && (this.opGraph == "Faturamento%" || this.opGraph == "Faturamento$")) {
                this.titulo = "% Faturamento";
                this.legenda = true
                this.maxY = 0;
                this.largBar = 10;
                this.barChartData[0].label = 'Faturado';
                this.barChartData[1].label = 'Não Faturado';
                this.barChartData[2].label = ' ';
                this.barChartData[0].backgroundColor = "#3ADF00";
                this.barChartData[1].backgroundColor = "#FF0000";
                this.barChartData[2].backgroundColor = "#FFFFFF";
                for (var i = 0; i < this.centrodecustos.length; i++) {
                  this.barChartLabels.push(this.centrodecustos[i].descricaoMin);
                  this.dados1[i] = 0;
                  this.dados2[i] = 0;
                  this.dados3[i] = 0;
                  //this.dados3[i] = 0;
                  let referCod = this.centrodecustos[i].descricaoMin;
                  let valorF = 0;
                  let valorNF = 0;
                  for (var n = 0; n < this.faturamento.length; n++) {
                    if (referCod == this.faturamento[n].Codigo) {
                      let valorT = parseFloat(this.faturamento[n].Faturado) + parseFloat(this.faturamento[n].NaoFaturado)
                      if (valorT > 0) {
                        valorF = parseFloat(this.faturamento[n].Faturado) / valorT * 100;
                        valorNF = parseFloat(this.faturamento[n].NaoFaturado) / valorT * 100;
                        if (this.opGraph == "Faturamento$") {
                          valorF = parseFloat(this.faturamento[n].Faturado);
                          valorNF = parseFloat(this.faturamento[n].NaoFaturado);
                        }

                      } else {
                        valorF = 0
                        valorNF = 0

                      }
                      this.dados1[i] = valorF.toFixed(2);
                      this.dados2[i] = valorNF.toFixed(2);
                      if (this.maxY < valorF) { this.maxY = valorF }
                      if (this.maxY < valorNF) { this.maxY = valorNF }

                      //console.log("@@@@@", this.maxY," " ,this.dados1[i]," " , this.dados2[i])
                      break;
                    }
                  }
                }
              }


              if (this.opGraph && (this.opGraph == "Custos%" || this.opGraph == "Custos$")) {
                this.legenda = true
                this.maxY = 0;
                this.largBar = 10;
                this.barChartData[0].label = 'Real';
                this.barChartData[1].label = 'Planejado';
                this.barChartData[2].label = '';
                this.barChartData[0].backgroundColor = "#ffcf8c";
                this.barChartData[1].backgroundColor = "#be03fd";
                this.barChartData[2].backgroundColor = "#FFFFFF";

                for (var i = 0; i < this.centrodecustos.length; i++) {
                  this.barChartLabels.push(this.centrodecustos[i].descricaoMin);
                  this.dados1[i] = 0;
                  this.dados2[i] = 0;
                  this.dados3[i] = 0;
                  let referCod = this.centrodecustos[i].descricao;
                  let valor1 = 0;
                  let valor2 = 0;

                  for (var n = 0; n < this.custoProjeto.length; n++) {
                    if (referCod == this.custoProjeto[n].Codigo.trim()) {
                      valor1 = this.custoProjeto[n].Custo;
                      break;
                    }
                  }

                  for (var n = 0; n < this.mcdigitalAll.length; n++) {
                    if (referCod == this.mcdigitalAll[n].siglamc.trim()) {
                      valor2 = this.mcdigitalAll[n].custoprojeto;
                      break;
                    }
                  }

                  if (this.opGraph == "Custos$") {
                    this.titulo = "Custos Real X Planejado (R$)";
                    this.dados1[i] = valor1.toFixed(2);
                    this.dados2[i] = valor2.toFixed(2);
                    if (this.maxY < valor1) { this.maxY = valor1 }
                    if (this.maxY < valor2) { this.maxY = valor2 }
                    console.log("@@@@@", this.maxY," " ,this.dados1[i]," " , this.dados2[i])
                  }

                  if (this.opGraph == "Custos%") {
                    this.titulo = "Custos Real X Planejado (%)";
                    if (valor1 > 0 && valor2 > 0) {
                      let val1 = (valor1 * 100 / valor2);
                      let val2 = 100;
                      this.dados2[i] = val2;
                      this.dados1[i] = val1.toFixed(2);

                      if (this.maxY < val1) { this.maxY = val1 }
                      if (this.maxY < val2) { this.maxY = val2 }
                    }
                  }
                }
              }


              if (this.opGraph && this.opGraph == "CustosFaturamentos") {
                this.titulo = "Custos X Faturamentos (R$)";
                this.legenda = true
                this.maxY = 0;
                this.largBar = 10;
                this.barChartData[0].label = 'Faturamento Realizado';
                this.barChartData[1].label = 'Custo Realizado';
                this.barChartData[2].label = ' ';
                this.barChartData[0].backgroundColor = "#3ADF00";
                this.barChartData[1].backgroundColor = "#FF0000";
                this.barChartData[2].backgroundColor = "#FFFFFF";
                for (var i = 0; i < this.centrodecustos.length; i++) {
                  this.barChartLabels.push(this.centrodecustos[i].descricaoMin);
                  this.dados1[i] = 0;
                  this.dados2[i] = 0;
                  this.dados3[i] = 0;
                  let referCod = this.centrodecustos[i].descricaoMin.trim();
                  let valorF = 0;
                  let valorC = 0;

                  for (var n = 0; n < this.faturamento.length; n++) {
                    if (referCod == this.faturamento[n].Codigo) {
                      valorF = parseFloat(this.faturamento[n].Faturado);
                      this.dados1[i] = valorF.toFixed(2);
                      if (this.maxY < valorF) { this.maxY = valorF }
                      break;
                    }
                  }

                  referCod = this.centrodecustos[i].descricao.trim();
                  for (var n = 0; n < this.custoProjeto.length; n++) {
                    if (referCod == this.custoProjeto[n].Codigo.trim()) {
                      valorC = this.custoProjeto[n].Custo;
                      this.dados2[i] = valorC.toFixed(2);
                      if (this.maxY < valorC) { this.maxY = valorC }
                      break;
                    }
                  }

                }
              }


              let valref = this.centrodecustos.length * this.fator;
              if (valref < 500) { valref = 500 };
              if (valref > 1050) { valref = 1050 };
              this.larguraG = (valref).toString() + 'px';

              console.log("*************")
              console.log("this.larguraG = ", this.larguraG)
              console.log("*************")

              if (this.opGraph != "Faturamento%") {
                this.maxY = parseInt((this.maxY / 100).toString()) * 100 + 100;
              } else {
                this.maxY = 100;
              }

              this.setGraph();

            } else {

              this.resetGraph();
              this.setGraph();
              

            }
          }, error => {
            console.log(error);
          });
      }

    }
    this.spinner.hide();
  }

  async getClientes() {
    this.spinner.show();
    this.clientes = [];
    if (this.movSubCli && this.movSubCli.closed == false) {
      this.movSubCli.unsubscribe();
    }
    if (!this.movSubCli || this.movSubCli.closed) {
      this.movSubCli = this.crudService.listar("cliente", null, null).subscribe(
        (result: any) => {
          this.clientes = result;
          this.clientes.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
          this.spinner.show();
          this.getMcdigital();
          this.getFuncao();
        }, error => {
          console.log(error);
        });
    }
  }

  async getColaboradores() {
    this.spinner.show();
    let usuario = JSON.parse(localStorage.getItem('userNextCapacity'));
    if (usuario.email == 'ebarros@coachit.com.br' || usuario.email == 'mxavier@coachit.com.br') {

      this.getPerfil()
      this.colaboradores = [];
      if (this.movSubCol && this.movSubCol.closed == false) {
        this.movSubCol.unsubscribe();
      }
      if (!this.movSubCol || this.movSubCol.closed) {
        this.movSubCol = this.crudService.listar("colaborador", null, null).subscribe(
          (result: any) => {
            this.colaboradores = result;
            this.colaboradores.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
            this.spinner.show();
            this.getClientes();
          }, error => {
            console.log(error);
          });
      }
    } else {
      this.toastr.error('Gráficos indisponíveis temporariamente!', 'Atenção!');
    }

  }

  /*
  async getFuncao(){
    this.spinner.show();
    let gestores = [];
    let nomeGestores = [];
    if (this.movSubFunc && this.movSubFunc.closed == false){
      this.movSubFunc.unsubscribe();
    }
    if (!this.movSubFunc || this.movSubFunc.closed){
      this.movSubFunc = this.crudService.getGestor("GESTOR DE PROJETOS").subscribe(
        (result: any) =>{
          gestores.push(result[0])
        },error=>{
            console.log(error);
      });
      this.movSubFunc = this.crudService.getGestor("DIRETOR").subscribe(
        (result: any) =>{
          gestores.push(result[0])
          for(let c of this.colaboradores){
            if(c.perfil == gestores[0].id || c.perfil == gestores[1].id){
               nomeGestores.push(c);
            }
          }
          this.grupoGestor = nomeGestores;
          this.spinner.show();
          this.getHoras();
        },error=>{
            console.log(error);
      });
    }
  }
*/

  async getFuncao() {
    let gestores = [];
    let perfis = [];
    let nomeGestores = [];
    if (this.movSubFunc && this.movSubFunc.closed == false) {
      this.movSubFunc.unsubscribe();
    }
    if (!this.movSubFunc || this.movSubFunc.closed) {
      this.movSubCli = this.crudService.listar("perfil", 'grupo', 'GP').subscribe(
        (result: any) => {
          gestores = result;

          for (let g of gestores) {
            perfis.push(g.id);
          }

          for (let c of this.colaboradores) {
            if (perfis.indexOf(c.perfil) > -1) {
              nomeGestores.push({ nome: c.nome, id: c.id });
            }
          }
          this.grupoGestor = nomeGestores;
          //this.spinner.show();
          this.getHoras();
        }, error => {
          console.log(error);
        });
    }
  }


  async getPerfil() {
    this.spinner.show();
    if (this.movPerfil && this.movPerfil.closed == false) {
      this.movPerfil.unsubscribe();
    }
    if (!this.movPerfil || this.movPerfil.closed) {

      this.movPerfil = this.crudService.getFuncao().subscribe(
        (result: any) => {
          //console.log('perfil: ',result)
          this.perfis = result
        }, error => {
          console.log(error);
        });

    }
  }


  async getMcdigital() {
    this.mcdigitalAll = [];
    if (this.movSubMcd && this.movSubMcd.closed == false) {
      this.movSubMcd.unsubscribe();
    }
    if (!this.movSubMcd || this.movSubMcd.closed) {
      let filtro = [];
      let ativo = {
        "campo": "ativo",
        "operacao": "==",
        "value": true
      }
      filtro.push(ativo);
      this.movSubMcd = this.crudService.listarByFiltro("mcdigital", filtro).subscribe(
        (result: any) => {
          this.mcdigitalAll = result;

          console.log("this.mcdigitalAll", this.mcdigitalAll)
          //this.mcdigitalAll.sort((a,b) => (a.siglamc > b.siglamc) ? 1 : ((b.siglamc > a.siglamc) ? -1 : 0));
          this.spinner.show();
          //this.getFuncao();
        }, error => {
          console.log(error);
        });
    }
  }


  async getHoras() {
    this.spinner.show();
    this.horas = [];
    if (this.movHoras && this.movHoras.closed == false) {
      this.movHoras.unsubscribe();
    }
    if (!this.movHoras || this.movHoras.closed) {
      this.movHoras = this.crudService.listar("horasProjetoGrupo", null, null).subscribe(
        (result: any) => {
          this.horas = result;
          this.horas.sort((a, b) => (a.Projeto > b.Projeto) ? 1 : ((b.Projeto > a.Projeto) ? -1 : 0));
          this.spinner.show();
          this.getCustoProjeto();
        }, error => {
          console.log(error);
        });
    }
  }


  async getCustoProjeto() {
    this.spinner.show();
    this.custoProjeto = [];
    if (this.movCustoProjeto && this.movCustoProjeto.closed == false) {
      this.movCustoProjeto.unsubscribe();
    }
    if (!this.movCustoProjeto || this.movCustoProjeto.closed) {
      this.movCustoProjeto = this.crudService.CustoHrProj().subscribe(
        (result: any) => {
          this.custoProjeto = result;
          this.spinner.show();
          this.getFaturamento();
        }, error => {
          console.log(error);
        });
    }
  }


  async getFaturamento() {
    this.spinner.show();
    this.faturamento = [];
    if (this.movFaturamento && this.movFaturamento.closed == false) {
      this.movFaturamento.unsubscribe();
    }
    if (!this.movFaturamento || this.movFaturamento.closed) {
      this.movHoras = this.crudService.listar("multidadosDatas", null, null).subscribe(
        (result: any) => {
          this.faturamento = result;
          this.spinner.show();
          this.getCentrodecustos();
        }, error => {
          console.log(error);
        });
    }
  }

  
  resetGraph() {
  
    this.barChartLabels = [];
 
    this.barChartData[0].backgroundColor = "#FFFAFA";
    this.barChartData[1].backgroundColor = "#FFFAFA";
    this.barChartData[2].backgroundColor = "#FFFAFA";
 
} 

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }
  public chartHovered(e: any): void {
    console.log(e);
  }

  ngOnDestroy() {
    if (this.movSubCentro && this.movSubCentro.closed == false) {
      this.movSubCentro.unsubscribe();
    }
    if (this.movSubCli && this.movSubCli.closed == false) {
      this.movSubCli.unsubscribe();
    }
    if (this.movSubCol && this.movSubCol.closed == false) {
      this.movSubCol.unsubscribe();
    }
    if (this.movSubFunc && this.movSubFunc.closed == false) {
      this.movSubFunc.unsubscribe();
    }
    if (this.movSubMcd && this.movSubMcd.closed == false) {
      this.movSubMcd.unsubscribe();
    }
    if (this.movHoras && this.movHoras.closed == false) {
      this.movHoras.unsubscribe();
    }
    if (this.movPerfil && this.movPerfil.closed == false) {
      this.movPerfil.unsubscribe();
    }
    if (this.movCustoProjeto && this.movCustoProjeto.closed == false) {
      this.movCustoProjeto.unsubscribe();
    }
    if (this.movFaturamento && this.movFaturamento.closed == false) {
      this.movFaturamento.unsubscribe();
    }
  }

}
