import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[appStatusAusencia]'
})
export class StatusAusenciaDirective implements OnInit{
    
  @Input() value: Number;
  
  constructor(private elRef: ElementRef, private render: Renderer2){

  }
 
  ngOnInit(){

      if(this.value == 1){
 
        const text = this.render.createText('Aprovada');
       
        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-success");
  
      }else if(this.value == 2){

        const text = this.render.createText('Reprovada');
        
        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-danger");
  
      }else if(this.value == 3){

        const text = this.render.createText('Parcialmente');
        
        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-info");
  
      }else{

        const text = this.render.createText('Pendente');
        
        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-warning");
  
      }

    }
    

}

