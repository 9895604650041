
export class Empresa {

    public id: string;
    public cnpj: string;
    public razaoSocial: string;
    public imposto: Number;
    public markup: Number;
    public comissao: Number;


    constructor(){

    }
}
