import { NgModule } from '@angular/core';
import { SimNaoDirective } from './sim-nao.directive';
import { FileSelectDirective, FileDropDirective } from 'ng2-file-upload';
import { PerfilDirective } from './perfil.directive';
import { ColaboradorDirective } from './colaborador.directive';
import { ClienteDirective } from './cliente.directive';
import { ColaboradorBadgeDirective } from './colaboradorbadge.directive';
import { CentroCustoDirective } from './centrocusto.directive';
import { StatusDirective } from './status.directive';
import { StatusBolinhaDirective } from './statusBolinha.directive';
import { VerticalDirective } from './vertical.directive';
import { StatusAusenciaDirective } from './status.-ausencia.directive';
import { ResponsavelDirective } from './responsaveldirective';
import { CentroCustoNomeDirective } from './centrocustoNome.directive';
import { CentroCustoNomeCodDirective } from './centrocustoNomeCod.directive';
import { DropZoneDirective } from './drop-zone.directive';
import { StatusMCDirective } from './statusmc.directive';
import { GestorDirective } from './gestor.directive';
import { McDigitalDirective } from './mcdigital.directive';
import { HorasProjetoDirective } from './horasProjeto.directive';
import { DatasProjetoDirective } from './datasProjeto.directive';
import { DespesasDirective } from './despesas.directive';
import { CustoProjetoDirective } from './custoProjeto.directive';
import { FaturamentoResumoDirective } from './faturamentoResumo.directive';



@NgModule({
    declarations: [
        SimNaoDirective,
        FileSelectDirective,
        FileDropDirective,
        PerfilDirective,
        ColaboradorDirective,
        ClienteDirective,
        ColaboradorBadgeDirective,
        CentroCustoDirective,
        StatusDirective,
        StatusBolinhaDirective,
        VerticalDirective,
        StatusAusenciaDirective,
        ResponsavelDirective,
        CentroCustoNomeDirective,
        DropZoneDirective,
        StatusMCDirective,
        CentroCustoNomeCodDirective,
        McDigitalDirective,
        HorasProjetoDirective,
        DatasProjetoDirective,
        DespesasDirective,
        CustoProjetoDirective,
        FaturamentoResumoDirective
    ],

    exports: [
        SimNaoDirective,
        FileSelectDirective,
        FileDropDirective,
        PerfilDirective,
        ColaboradorDirective,
        ClienteDirective,
        ColaboradorBadgeDirective,
        CentroCustoDirective,
        StatusDirective,
        StatusBolinhaDirective,
        VerticalDirective,
        StatusAusenciaDirective,
        ResponsavelDirective,
        CentroCustoNomeDirective,
        DropZoneDirective,
        StatusMCDirective,
        McDigitalDirective,
        HorasProjetoDirective,
        DatasProjetoDirective,
        DespesasDirective,
        CustoProjetoDirective,
        FaturamentoResumoDirective
    ]
})
export default class DirectivesModule{}
/*
import { NgModule } from '@angular/core';
import { SimNaoDirective } from './sim-nao.directive';
import { FileSelectDirective, FileDropDirective } from 'ng2-file-upload';
import { PerfilDirective } from './perfil.directive';
import { ColaboradorDirective } from './colaborador.directive';
import { ClienteDirective } from './cliente.directive';
import { ColaboradorBadgeDirective } from './colaboradorbadge.directive';
import { CentroCustoDirective } from './centrocusto.directive';
import { StatusDirective } from './status.directive';
import { StatusBolinhaDirective } from './statusBolinha.directive';
import { VerticalDirective } from './vertical.directive';
import { StatusAusenciaDirective } from './status.-ausencia.directive';
import { ResponsavelDirective } from './responsaveldirective';
import { CentroCustoNomeDirective } from './centrocustoNome.directive';
import { DropZoneDirective } from './drop-zone.directive';
import { StatusMCDirective } from './statusmc.directive';

@NgModule({
    declarations: [
        SimNaoDirective,
        FileSelectDirective,
        FileDropDirective,
        PerfilDirective,
        ColaboradorDirective,
        ClienteDirective,
        ColaboradorBadgeDirective,
        CentroCustoDirective,
        StatusDirective,
        StatusBolinhaDirective,
        VerticalDirective,
        StatusAusenciaDirective,
        ResponsavelDirective,
        CentroCustoNomeDirective,
        DropZoneDirective,
        StatusMCDirective
    ],

    exports: [
        SimNaoDirective,
        FileSelectDirective,
        FileDropDirective,
        PerfilDirective,
        ColaboradorDirective,
        ClienteDirective,
        ColaboradorBadgeDirective,
        CentroCustoDirective,
        StatusDirective,
        StatusBolinhaDirective,
        VerticalDirective,
        StatusAusenciaDirective,
        ResponsavelDirective,
        CentroCustoNomeDirective,
        DropZoneDirective,
        StatusMCDirective
    ]
})
export default class DirectivesModule{}
*/
