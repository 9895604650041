
export class CapacityDetalhes {

    public colaborador: string;
    public gestor: string;
    public horas: Number;
    
    constructor(){ 
        
    }
}
