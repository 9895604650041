import { Directive, Input , ElementRef, Renderer2, OnInit, RendererStyleFlags2} from '@angular/core';

@Directive({
  selector: '[appHorasProjeto]'
})
export class HorasProjetoDirective implements OnInit{

  @Input() projeto: string;
  @Input() grupo: string;
  @Input() horasTipo: string;
  @Input() horas: any[];

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){
      for(var i = 0; i < this.horas.length; i++){
        if(this.horas[i].Projeto.trim() == this.projeto.trim() && this.horas[i].Grupo.indexOf(this.grupo) !== -1){
          if(this.horasTipo == 'Plan'){
            const text = this.render.createText(parseFloat(this.horas[i].HorasPlanejadas).toFixed(2).toString() );
            this.render.appendChild(this.elRef.nativeElement, text);
          }
          if(this.horasTipo == 'Gastas'){
            const text = this.render.createText(parseFloat(this.horas[i].HoraDecimal).toFixed(2).toString() );
            this.render.appendChild(this.elRef.nativeElement, text);
          }
        }
      }
  }
}

