
export class Cliente {

    public id: string;
    public codigo: string;
    public nome: string;
    public ativo: boolean;
    public socioResponsavel: string;
    public sigla: string;
    constructor(){

    }
}
