import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CrudService } from "../../services/crudService";
import { ModalService } from '../../services/modal.service';
import { Empresa } from '../../model/empresa';
import { ConfirmationDialogService } from '../../components/confirmation-dialog/confirmation-dialog.service';
import { CsvDataService } from '../../services/csvdata.service';


@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.scss']
})
export class EmpresasComponent implements OnInit {

  movSub: Subscription;
  movSubCol: Subscription;
  financeiro: boolean = false;
  empresas: any[];
  colaboradores: any[];

  constructor(
      private spinner: NgxSpinnerService,
      private CrudService: CrudService,
      private modalService: ModalService,
      private cadastroService: CrudService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService,
      private csvDataService: CsvDataService
  ){

  }

  ngOnInit() {
    this.getempresas();
    this.getColaboradores();
  }

  async getempresas(){

    this.empresas = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();

      this.movSub = this.CrudService.listar("empresas", null, null).subscribe(
        (result: any) =>{

          this.empresas =  result;
          console.log(result);

          this.spinner.hide();

       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }


  }

  async getColaboradores(){
    this.colaboradores = [];
    if (this.movSubCol && this.movSubCol.closed == false){
      this.movSubCol.unsubscribe();
    }
    if (!this.movSubCol || this.movSubCol.closed){
      this.movSubCol = this.CrudService.listar("colaborador", null, null).subscribe(
        (result: any) =>{
          this.colaboradores =  result;
          this.colaboradores.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
          let user = JSON.parse(localStorage.getItem('userNextCapacity'));

          for(var i = 0; i < this.colaboradores.length; i++){

            console.log( user.email,this.colaboradores[i].email );

            if(user.email == this.colaboradores[i].email){
              this.financeiro = this.colaboradores[i].financeiro
            }
          }
          console.log("this.financeiro ", this.financeiro)
       },error=>{
            console.log(error);
       });
    }
  }


  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (this.movSubCol && this.movSubCol.closed == false){
      this.movSubCol.unsubscribe();
    }

  }

  novoempresas(){
    this.modalService.modalDetalhesEmpresas.emit(null);
  }

  editar(empresa: Empresa){
    let user = JSON.parse(localStorage.getItem('userNextCapacity'));

    this.modalService.modalDetalhesEmpresas.emit(empresa);
  }

  excluir(empresa: Empresa){

    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{

        if(confirmed){
          this.cadastroService.excluir("empresas", empresa.id);

          this.toastr.success('empresas excluída com sucesso!', 'Sucesso!');
          this.getempresas();

        }

      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });

  }


}
