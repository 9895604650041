import { Colaborador } from './../../model/colaborador';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CrudService } from '../../services/crudService';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventoCapacity } from '../../model/eventoCapacity';
import { CentroDeCusto } from '../../model/centrodecusto';
import { ButtonModule } from 'primeng/button';

import * as xlsx from 'xlsx';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-controle-projetos',
  templateUrl: './controle-projetos.component.html',
  styleUrls: ['./controle-projetos.component.scss']
})
export class ControleProjetosComponent implements OnInit {

  @ViewChild('tabela', { static: false }) tabela;

  centrodecustos: any[]= [];
  ccustos: any[] = [];
  clientes: any[] = [];
  colaboradores: any[] = [];
  grupoGestor: any[] = [];
  scrollableCols: any[] = [];
  frozenCols: any[] = [];
  funcao: any[] = [];
  mcdigitalAll: any[] = [];
  horas: any[] = [];
  datas: any[] = [];
  despesas: any[] = [];
  custoProjeto: any[] = [];
  movSubCentro: Subscription;
  movSubCli: Subscription;
  movSubCol: Subscription;
  movSubFunc: Subscription;
  movSubMcd: Subscription;
  movSubHoras: Subscription;
  movSubDatas: Subscription;
  movSubDespesas: Subscription;
  movCustoProjeto: Subscription;
  _eventoCapacity: EventoCapacity = new EventoCapacity();
  _eventoColaborador: Colaborador = new Colaborador();
  _eventoCentroDeCusto: CentroDeCusto = new CentroDeCusto();
  opAtivo: string;
  boolOp: boolean;
  cont: number = 0;


  constructor(
    private crudService: CrudService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {

    this._eventoCapacity.cliente = "Todos";
    this._eventoColaborador.nome = "Todos";
    this._eventoCentroDeCusto.descricao = "Todos";
    this.opAtivo = "Todos";
    this.getColaboradores();


    this.scrollableCols = [
      { field: '', header: 'Gestor' },
      { field: '', header: 'Cliente' },
      { field: '', header: 'Data Início Plan' },
      { field: '', header: 'Código do Projeto' }
    ];
    this.frozenCols = [
      { field: '', header: 'Nome do Projeto' }
    ];

  }

  async getCentrodecustos(){
    console.log("getCentrodecustos ", this.centrodecustos.length);
    if(this.centrodecustos.length == 0){
      this.spinner.show();
      this.centrodecustos = [];
      if (this.movSubCentro && this.movSubCentro.closed == false){
        this.movSubCentro.unsubscribe();
      }

      if (!this.movSubCentro || this.movSubCentro.closed){
        let filtro = [];
        let relat  ={
          "campo" : "relatorio",
          "operacao" : "==",
          "value" : true
        }
        filtro.push(relat);

        this.movSubCentro = this.crudService.listarByFiltro("centrodecusto", filtro).subscribe(
          (result: any) =>{
            this.centrodecustos =  result;
            if(this.cont == 0){
              this.ccustos =  this.centrodecustos;
            }
            this.centrodecustos.sort((a,b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0));
            this.spinner.hide();

        },error=>{
              console.log(error);
        });
      }
    }

  }

  async getClientes(){
    console.log("getClientes ", this.clientes.length);
    if(this.clientes.length == 0){
      this.clientes = [];
      if (this.movSubCli && this.movSubCli.closed == false){
        this.movSubCli.unsubscribe();
      }
      if (!this.movSubCli || this.movSubCli.closed){
        this.movSubCli = this.crudService.listar("cliente", null, null).subscribe(
          (result: any) =>{
            this.clientes =  result;
            this.clientes.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
            //this.spinner.show();
            this.getMcdigital();
        },error=>{
              console.log(error);
        });
      }
  }
  }

  async getColaboradores(){
    console.log("getColaboradores ", this.colaboradores.length);
    let usuario = JSON.parse(localStorage.getItem('userNextCapacity'));
    if (usuario.email == 'ebarros@coachit.com.br' || usuario.email == 'mxavier@coachit.com.br') {
      if (this.colaboradores.length == 0) {
        this.colaboradores = [];
        if (this.movSubCol && this.movSubCol.closed == false) {
          this.movSubCol.unsubscribe();
        }
        if (!this.movSubCol || this.movSubCol.closed) {
          this.movSubCol = this.crudService.listar("colaborador", null, null).subscribe(
            (result: any) => {
              this.colaboradores = result;
              this.colaboradores.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
              this.spinner.show();
              this.getClientes();
            }, error => {
              console.log(error);
            });
        }
      }
    } else {
        this.toastr.error('Relatório indisponível temporariamente!', 'Atenção!');
    }
  }

/*
  async getFuncao(){
      let gestores = [];
      let nomeGestores = [];
      if (this.movSubFunc && this.movSubFunc.closed == false){
        this.movSubFunc.unsubscribe();
      }
    if (!this.movSubFunc || this.movSubFunc.closed) {
        this.movSubFunc = this.crudService.getGestor("GESTOR DE PROJETOS").subscribe(
          (result: any) =>{
            gestores.push(result[0])
          },error=>{
              console.log(error);
        });

        this.movSubFunc = this.crudService.getGestor("GERENTE DE CONSULTORIA").subscribe(
          (result: any) =>{
            gestores.push(result[0])
          },error=>{
              console.log(error);
        });

        this.movSubFunc = this.crudService.getGestor("DIRETOR").subscribe(
          (result: any) =>{
            gestores.push(result[0])

            for(let c of this.colaboradores){
              if(c.perfil == gestores[0].id || c.perfil == gestores[1].id || c.perfil == gestores[2].id){
                nomeGestores.push(c);
              }
            }
            this.grupoGestor = nomeGestores;
            //this.spinner.show();
            this.getHoras();

          },error=>{
              console.log(error);
        });
      }
  }
*/


  async getFuncao() {
    let gestores = [];
    let perfis = []; 
    let nomeGestores = [];
    if (this.movSubFunc && this.movSubFunc.closed == false) {
      this.movSubFunc.unsubscribe();
    }
    if (!this.movSubFunc || this.movSubFunc.closed) {
        this.movSubCli = this.crudService.listar("perfil", 'grupo', 'GP').subscribe(
          (result: any) => {
            gestores = result;

            for (let g of gestores) {
              perfis.push(g.id);
            }

            for (let c of this.colaboradores) {
              if (perfis.indexOf(c.perfil) > -1) {
                nomeGestores.push({ nome:c.nome, id:c.id });
              }
            }
            this.grupoGestor = nomeGestores;
            //this.spinner.show();
            this.getHoras();
          }, error => {
            console.log(error);
          });
     }
}
  

  async getMcdigital(){
    console.log("getMcdigital", this.mcdigitalAll.length);
    if(this.mcdigitalAll.length == 0){
      this.mcdigitalAll = [];
      if (this.movSubMcd && this.movSubMcd.closed == false){
        this.movSubMcd.unsubscribe();
      }
      if (!this.movSubMcd || this.movSubMcd.closed){
        this.movSubMcd = this.crudService.listar("mcdigital", null, null).subscribe(
          (result: any) =>{
            this.mcdigitalAll =  result;
            //this.mcdigitalAll.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
            console.log("#### this.mcdigitalAll ",this.mcdigitalAll)
            this.spinner.show();
            this.getFuncao();
        },error=>{
              console.log(error);
        });
      }
  }
  }

  async getHoras(){
    console.log("getHoras ", this.horas.length);
    if(this.horas.length == 0){
      this.horas = [];
      if (this.movSubHoras && this.movSubHoras.closed == false){
        this.movSubHoras.unsubscribe();
      }
      if (!this.movSubHoras || this.movSubHoras.closed){
        this.movSubHoras = this.crudService.listar("horasProjetoGrupo",null,null).subscribe(
          (result: any) =>{
            this.horas =  result;
            this.horas.sort((a,b) => (a.Projeto > b.Projeto) ? 1 : ((b.Projeto > a.Projeto) ? -1 : 0));

            //console.log(" this.horas ",  this.horas)

            //this.spinner.show();
            this.getDatas();
        },error=>{
              console.log(error);
        });
      }
  }
  }

  async getDatas(){
    console.log("getDatas ", this.datas.length);
    if(this.datas.length == 0){
    this.datas = [];
    if (this.movSubDatas && this.movSubDatas.closed == false){
      this.movSubDatas.unsubscribe();
    }
    if (!this.movSubDatas || this.movSubDatas.closed){
      this.movSubDatas = this.crudService.listar("multidadosDatas",null,null).subscribe(
        (result: any) =>{
          this.datas =  result;
          this.datas.sort((a,b) => (a.Codigo > b.Codigo) ? 1 : ((b.Codigo > a.Codigo) ? -1 : 0));
          //this.spinner.show();
          this.getDespesas();
      },error=>{
            console.log(error);
      });
    }
  }
  }

  async getDespesas(){
    console.log("getDespesas ", this.despesas.length );
    if(this.despesas.length == 0){
    this.despesas = [];
    if (this.movSubDespesas && this.movSubDespesas.closed == false){
      this.movSubDespesas.unsubscribe();
    }
    if (!this.movSubDespesas || this.movSubDespesas.closed){
      this.movSubDespesas = this.crudService.listar("multidadosDespesasTotais",null,null).subscribe(
        (result: any) =>{
          this.despesas =  result;
          this.despesas.sort((a,b) => (a.Codigo > b.Codigo) ? 1 : ((b.Codigo > a.Codigo) ? -1 : 0));
          console.log("*** DESPESAS ",this.despesas)
          //this.spinner.show();
          this.getCustoProjeto();
      },error=>{
            console.log(error);
      });
    }
  }
  }

  async getCustoProjeto(){
    console.log("getCustoProjeto , this.custoProjeto.length");
    if(this.custoProjeto.length == 0){
      this.custoProjeto = [];
      if (this.movCustoProjeto && this.movCustoProjeto.closed == false){
        this.movCustoProjeto.unsubscribe();
      }
      if (!this.movCustoProjeto || this.movCustoProjeto.closed){
        this.movCustoProjeto = this.crudService.listar("custoHorasProjeto",null,null).subscribe(
          (result: any) =>{
            this.custoProjeto =  result;
            this.custoProjeto.sort((a, b) => (a.Codigo > b.Codigo) ? 1 : ((b.Codigo > a.Codigo) ? -1 : 0));

            console.log("++++ this.custoProjeto ", this.custoProjeto);

            //this.spinner.show();
            this.getCentrodecustos();
        },error=>{
              console.log(error);
        });
      }
  }
  }

  async getCC(){
    let filtro = []
     
    this.spinner.show();
    if (this.movSubCentro && this.movSubCentro.closed == false){
      this.movSubCentro.unsubscribe();
    }
    if (!this.movSubCentro || this.movSubCentro.closed) {

      let relat = {
        "campo": "relatorio",
        "operacao": "==",
        "value": true
      }
      filtro.push(relat);
    

      if(this.opAtivo && this.opAtivo != "Todos"){
        this.boolOp = JSON.parse(this.opAtivo)
        let ativo  ={
          "campo" : "ativo",
          "operacao" : "==",
          "value" : this.boolOp
        }
        filtro.push(ativo);
      }

      if(this._eventoColaborador.nome && this._eventoColaborador.nome != "Todos"){
        let colab  ={
          "campo" : "responsavel",
          "operacao" : "==",
          "value" : this._eventoColaborador.nome
        }
        filtro.push(colab);
      }

      if(this._eventoCapacity.cliente && this._eventoCapacity.cliente != "Todos"){
        let cliente  ={
          "campo" : "cliente",
          "operacao" : "==",
          "value" : this._eventoCapacity.cliente
        }
        filtro.push(cliente);
      }

      if(this._eventoCentroDeCusto.descricao && this._eventoCentroDeCusto.descricao != "Todos"){
        let centoC  ={
          "campo" : "id",
          "operacao" : "==",
          "value" : this._eventoCentroDeCusto.descricao
        }
        this.cont = 1
        filtro.push(centoC);
      }

      console.log("filtro ", filtro)

      if (!this.movSubCentro || this.movSubCentro.closed){
        this.movSubCentro = this.crudService.listarByFiltro("centrodecusto", filtro).subscribe(
          (result: any) =>{
            this.centrodecustos =  result;
            if(this.cont == 0){
              this.ccustos =  this.centrodecustos;
            }
            this.centrodecustos.sort((a,b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0));
            this.spinner.hide();

        },error=>{
              console.log(error);
        });
      }

    }
  }

 retiraAcentos(str) {
  let com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
  let sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
  let novastr="";
  //console.log("#  ",str)
  for(let i=0; i<str.length; i++) {
    let troca=false;
    for (let a=0; a<com_acento.length; a++) {
    if (str.substr(i,1)==com_acento.substr(a,1)) {
      novastr+=sem_acento.substr(a,1);
      troca=true;
      //console.log("1 >>>>>>> ",novastr)
      break;
    }
    }
    if (troca==false) {
    novastr+=str.substr(i,1);

    }
  }
  //console.log("fim>>>>>>>>>>>>>>>>>>> ",novastr)
  return novastr;
}




  ngOnDestroy(){
    if (this.movSubCentro && this.movSubCentro.closed == false){
      this.movSubCentro.unsubscribe();
    }
    if (this.movSubCli && this.movSubCli.closed == false){
      this.movSubCli.unsubscribe();
    }
    if (this.movSubCol && this.movSubCol.closed == false){
      this.movSubCol.unsubscribe();
    }
    if (this.movSubFunc && this.movSubFunc.closed == false){
      this.movSubFunc.unsubscribe();
    }
    if (this.movSubMcd && this.movSubMcd.closed == false){
      this.movSubMcd.unsubscribe();
    }
    if (this.movSubHoras && this.movSubHoras.closed == false){
      this.movSubHoras.unsubscribe();
    }
    if (this.movSubDatas && this.movSubDatas.closed == false){
      this.movSubDatas.unsubscribe();
    }
    if (this.movSubDespesas && this.movSubDespesas.closed == false){
      this.movSubDespesas.unsubscribe();
    }
    if (this.movCustoProjeto && this.movCustoProjeto.closed == false){
      this.movCustoProjeto.unsubscribe();
    }



  }

  exportToExcel() {
    const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.tabela.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Controle Projetos.xlsx');
   }

  obterDataTratada(data: string) {
    if (data != null) {
      var splitDataFim = data.toString().split("-");
      if (splitDataFim[0] != undefined && splitDataFim[1] != undefined && splitDataFim[2] != undefined) {
        return splitDataFim[2] + "/" + splitDataFim[1] + "/" + splitDataFim[0];
      }
    } else {
      return "";
    }
  }



}
