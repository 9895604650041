
export class ColaboradorCSV {

    public _1_sigla: string;
    public _2_nome: string;
    public _3_email: string;
    public _4_perfil: string;
    public _5_valorHora: Number;
    public _6_saldoHora: Number;
    public _7_gestorDireto: string;
    public _8_gestor: string;
    public _9_mediador: string;
    public _10_ativo: string;

    constructor(){
        
    }
}
