import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';
import { Perfil } from '../model/perfil';

@Directive({
  selector: '[appVertical]'
})
export class VerticalDirective implements OnInit{
    
  @Input() id: string;
  @Input() vertical: any[];

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){

    for(var i = 0; i < this.vertical.length; i++){

      if(this.vertical[i].id == this.id){

        const text = this.render.createText(this.vertical[i].descricao);
  
        this.render.appendChild(this.elRef.nativeElement, text);
      }
    }

  }
    

}

