import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[appStatus]'
})
export class StatusDirective implements OnInit{
    
  @Input() value: boolean;
  
  constructor(private elRef: ElementRef, private render: Renderer2){

  }
 
  ngOnInit(){

      if(this.value){
 
        const text = this.render.createText('Aprovado');
       
        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-success");
  
      }else{

        const text = this.render.createText('Pendente');
        
        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-warning");
  
      }

    }
    

}

