import { Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Perfil } from '../../../model/perfil';
import { NgForm } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-detalhes-perfil',
  templateUrl: 'perfil-detalhes.component.html'
})
export class PerfilDetalhesComponent implements OnInit {

  @ViewChild('modalperfil', { static: false }) public modalperfil: ModalDirective;

  sub: boolean = false;
  _perfil: Perfil = new Perfil();
  @Output() loadperfil = new EventEmitter();
  titulo: string = "Novo Perfil";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  tecnologias: any[];
  niveis: any[];
  grupos: any[];

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CrudService) {
  }

  ngOnInit() {

    if (this.sub == false) {

      this.sub = true;

      this.modalService.modalDetalhesPerfil.subscribe(
        (p: any) => {

          if (p.perfil == null) {
            this.titulo = "Novo Perfil"
            this._perfil = new Perfil();
          } else {
            this.titulo = "Editar Perfil"
            this._perfil = p.perfil;
          }

          this.tecnologias = p.tecnologias;
          this.niveis = p.niveis;
          this.grupos = p.grupos;

          this.limpaModal();

          this.modalperfil.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  hideModal() {
    this.modalperfil.hide();
    this.loadperfil.emit();
  }

  limpaModal() {
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if (this._perfil.id) {
      this.atualizar();
    } else {
      this.salvar();
    }

    form.reset();
  }

  salvar() {

    let objPerfil = JSON.parse(JSON.stringify(this._perfil));

    if (this.movSubSalvar && this.movSubSalvar.closed == false) {
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed) {

      this.movSubSalvar = this.cadastroService.findByGeneric("perfil", "codigo", this._perfil.codigo).subscribe(
        (result: any) => {

          if (result.length > 0) {
            this.toastr.error('Este código já esta sendo utilizado por outro perfil!', 'Atenção!');
            this.hideModal();
          } else {
            this.cadastroService.salvar("perfil", objPerfil);

            this.toastr.success('Perfil cadastrado com sucesso!', 'Sucesso!');

            this.hideModal();

            this.movSubSalvar.unsubscribe();
          }

        }, error => {
          console.log(error);
          this.hideModal();
        });

    }



  }

  atualizar() {

    let objPerfil = JSON.parse(JSON.stringify(this._perfil));

    if (this.movSubAlterar && this.movSubAlterar.closed == false) {
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubAlterar || this.movSubAlterar.closed) {

      this.movSubAlterar = this.cadastroService.findByGeneric("perfil", "codigo", this._perfil.codigo).subscribe(
        (result: any) => {

          if (result.length > 0) {
            if (result[0].codigo == objPerfil.codigo) {
              this.cadastroService.atualizar("perfil", objPerfil);

              this.toastr.success('Perfil atualizado com sucesso!', 'Sucesso!');

              this.hideModal();

              this.movSubAlterar.unsubscribe();
            }
          }

        }, error => {
          console.log(error);
          this.hideModal();
        });

    }


  }
}


/*
import { Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Perfil } from '../../../model/perfil';
import { NgForm } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';
 
@Component({
  selector: 'app-detalhes-perfil',
  templateUrl: 'perfil-detalhes.component.html'
})    
export class PerfilDetalhesComponent implements OnInit{
      
  @ViewChild('modalperfil', {static: false}) public modalperfil: ModalDirective;
  
  sub: boolean = false;
  _perfil: Perfil = new Perfil();
  @Output() loadperfil = new EventEmitter();
  titulo: string = "Novo Perfil";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  tecnologias: any[];
  niveis: any[];

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CrudService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesPerfil.subscribe(
        (p: any) => {
         
          if(p.perfil == null){
            this.titulo = "Novo Perfil"
            this._perfil = new Perfil();
          }else{
            this.titulo = "Editar Perfil"
            this._perfil = p.perfil;
          }

          this.tecnologias = p.tecnologias;
          this.niveis = p.niveis;

          this.limpaModal();

          this.modalperfil.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  hideModal(){
    this.modalperfil.hide();
    this.loadperfil.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this._perfil.id){
      this.atualizar();
    }else{
      this.salvar();
    }
      
    form.reset();
  }

  salvar(){
 
    let objPerfil = JSON.parse(JSON.stringify(this._perfil));

    if (this.movSubSalvar && this.movSubSalvar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed){
     
      this.movSubSalvar = this.cadastroService.findByGeneric("perfil", "codigo", this._perfil.codigo).subscribe(
        (result: any) =>{

          if(result.length > 0){
            this.toastr.error('Este código já esta sendo utilizado por outro perfil!', 'Atenção!');
            this.hideModal();
          }else{
            this.cadastroService.salvar("perfil", objPerfil);

            this.toastr.success('Perfil cadastrado com sucesso!', 'Sucesso!');
         
            this.hideModal();

            this.movSubSalvar.unsubscribe();
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }

  
   
  } 

  atualizar(){

    let objPerfil = JSON.parse(JSON.stringify(this._perfil));
    
    if (this.movSubAlterar && this.movSubAlterar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubAlterar || this.movSubAlterar.closed){
     
      this.movSubAlterar = this.cadastroService.findByGeneric("perfil", "codigo", this._perfil.codigo).subscribe(
        (result: any) =>{

          if(result.length > 0){
            if(result[0].codigo == objPerfil.codigo){
              this.cadastroService.atualizar("perfil", objPerfil);

              this.toastr.success('Perfil atualizado com sucesso!', 'Sucesso!');
          
              this.hideModal();
            
              this.movSubAlterar.unsubscribe();
            }
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }

    
  }
}
*/
