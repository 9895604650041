import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[appColaborador]'
})
export class ColaboradorDirective implements OnInit{
    
  @Input() id: string;
  @Input() colaboradores: any[];
  @Input() email: string;

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){

    let achouColaborador = false;

    for(var i = 0; i < this.colaboradores.length; i++){

      if(this.id && this.colaboradores[i].id == this.id){

        const text = this.render.createText(this.colaboradores[i].nome);
  
        this.render.appendChild(this.elRef.nativeElement, text);
        achouColaborador  =true;
      }

      if(this.email && this.colaboradores[i].email == this.email){

        const text = this.render.createText(this.colaboradores[i].nome);
  
        this.render.appendChild(this.elRef.nativeElement, text);
        achouColaborador  =true;
      }
    }

    if(achouColaborador == false){
      if(this.id){
        const text = this.render.createText("Desconhecido");
  
        this.render.appendChild(this.elRef.nativeElement, text);
      }

      if(this.email){
        const text = this.render.createText(this.email);
  
        this.render.appendChild(this.elRef.nativeElement, text);
      }
    }

  }


}

