import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[appStatusBolinha]'
})
export class StatusBolinhaDirective implements OnInit{
    
  @Input() value: boolean;
  
  constructor(private elRef: ElementRef, private render: Renderer2){

  }
 
  ngOnInit(){

      if(this.value){
 
        const text = this.render.createText('A');
       
        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-success");
  
      }else{

        const text = this.render.createText('P');
        
        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-danger");
  
      }

    }
    

}

