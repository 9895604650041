import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[appResponsavel]'
})
export class ResponsavelDirective implements OnInit{
    
  @Input() id: string;
  @Input() colaboradores: any[];
  @Input() centrosdecustos: any[];

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){

   
    let idResp = "";
    let nomeResp = "";
    for(var i = 0; i < this.centrosdecustos.length; i++){
      if(this.centrosdecustos[i].id == this.id){
        idResp = this.centrosdecustos[i].responsavel;
      }      
    }

    for(var i = 0; i < this.colaboradores.length; i++){
      if(this.colaboradores[i].id == idResp){
        nomeResp = this.colaboradores[i].nome;
      }      
    }

    const text = this.render.createText(nomeResp);

    this.render.appendChild(this.elRef.nativeElement, text);
  

  }
    

}

