// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  firebaseConfig: {
    apiKey: "AIzaSyB5SNgt3X5jfRC25C8f2u4EG1wkdaNVxZs",
    authDomain: "nextcapacity.firebaseapp.com",
    databaseURL: "https://nextcapacity.firebaseio.com",
    projectId: "nextcapacity",
    storageBucket: "nextcapacity.appspot.com",
    messagingSenderId: "130069620524",
    appId: "1:130069620524:web:a7d2fb3af3c34172196e89",
    measurementId: "G-6PLYBJQY0X"
  },

  firebaseConfigDEV: {
    apiKey: "AIzaSyCySUPvgrZla7u4Hd5aXtELVZ1ygmDqGhY",
    authDomain: "nextcapacity-dev.firebaseapp.com",
    databaseURL: "https://nextcapacity-dev.firebaseio.com",
    projectId: "nextcapacity-dev",
    storageBucket: "nextcapacity-dev.appspot.com",
    messagingSenderId: "1067468015199",
    appId: "1:1067468015199:web:557fde9410c7a55615bdea",
    measurementId: "G-5Q8ZGKCFV7"
  },

  APIEndpoint: 'http://localhost:8080/nextcapacityapi/v1/'

};

