import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CrudService } from '../../services/crudService';
import { Alocacao } from '../../model/alocacao';
import { EventoCapacity } from '../../model/eventoCapacity';
import { AlocacaoResumo } from '../../model/alocacaoResumo';
import { NgxSpinnerService } from 'ngx-spinner';
 
@Component({
  selector: 'app-alocacao',
  templateUrl: './alocacao.component.html',
  styleUrls: ['./alocacao.component.scss']
})  
export class AlocacaoComponent implements OnInit {
      
  movSub: Subscription;
  alocacoes: EventoCapacity[];

  alocacoesResumo = [];
  colaboradores: any[];
  clientes: any[];
  movSubCli: Subscription;
  movSubAlo: Subscription;
  centrodecustos: any[];
  movSubCentro: Subscription;
  colaborador: string;

  isCollapsed: boolean = true;
  subColaboradores: Subscription;
  subCliente: any;
  subCentrodecusto: Subscription;
  _eventoCapacity: EventoCapacity = new EventoCapacity();
  _colaborador: any;
  centrodecustosAll: any[];
  subCentrodecustoAll: Subscription;
  periodoDe = new Date(new Date().getFullYear(),  new Date().getMonth(), 1, 0, 0, 0, 0);
  periodoAte = new Date(new Date().getFullYear(),  new Date().getMonth()+1, 1, 0, 0, 0, 0);

  totalHoras: number = 0;
  usuarioLogado: any;

  constructor(
      private crudService: CrudService,
      private spinner: NgxSpinnerService
  ){
    
  }

  ngOnInit() {
    this._eventoCapacity.cliente = "Todos";
    this._eventoCapacity.centrodecusto = "Todos";
    this._colaborador = "Todos";

    this.getColaboradores();
   
  }

  async getAlocacoes(){
 
    this.spinner.show();

    this.alocacoes = [];

    if (this.movSubAlo && this.movSubAlo.closed == false){
      this.movSubAlo.unsubscribe();
    }

    if (!this.movSubAlo || this.movSubAlo.closed){

     let filtro = [];

     if(this.periodoDe){
        let dataDe  ={
          "campo" : "data",
          "operacao" : ">=",
          "value" : this.periodoDe
        }
        filtro.push(dataDe);
     }
    
     if(this.periodoAte){
        let dataAte  ={
          "campo" : "data",
          "operacao" : "<=",
          "value" : this.periodoAte
        }
        filtro.push(dataAte);
     }
     
     if(this._eventoCapacity.cliente && this._eventoCapacity.cliente != "Todos"){
      let cliente  ={
        "campo" : "cliente",
        "operacao" : "==",
        "value" : this._eventoCapacity.cliente
      }
      filtro.push(cliente);
     }
   
     if(this._eventoCapacity.centrodecusto && this._eventoCapacity.centrodecusto != "Todos"){
      let centrodecusto  ={
        "campo" : "centrodecusto",
        "operacao" : "==",
        "value" : this._eventoCapacity.centrodecusto
      }
      filtro.push(centrodecusto);
     }
   

    this.movSubAlo = this.crudService.listarByFiltro("capacity", filtro).subscribe(
        (result: any) =>{

          this.alocacoes =  result;

          this.setAlocacoesDetalhes();

  
       },error=>{
            console.log(error);
       });

    }
    

  }

  onChangeColaborador(){
    this.spinner.show();
    this.getAlocacoes();
  }

 onChangeCentro(){
  this.spinner.show();
  this.getAlocacoes();
}

onChangeDateDe(De: Date){
  let _De = new Date(De);
  this.periodoDe = new Date(_De.getFullYear(),  _De.getMonth(), _De.getDate()+1, 0, 0, 0, 0);
  this.spinner.show();
  this.getAlocacoes();
 }

 onChangeDateAte(Ate: Date){
  let _Ate = new Date(Ate);
  this.periodoAte = new Date(_Ate.getFullYear(),  _Ate.getMonth(), _Ate.getDate()+1, 0, 0, 0, 0);
  this.spinner.show();
  this.getAlocacoes();
}

  setAlocacoesDetalhes(){
   
    this.alocacoesResumo = [];
    
    if(this.alocacoes.length > 0){
      for(let a of this.alocacoes){
        for(let d of a.detalhes){

          let alocacao = new AlocacaoResumo();

          alocacao.centrodecusto = a.centrodecusto;
          alocacao.cliente = a.cliente;
          alocacao.colaborador = d.colaborador;
          alocacao.horas = d.horas;

          for(let c of this.colaboradores){
            if(c.id == d.colaborador){
              alocacao.nomeColaborador = c.nome;
            }
          }

          let existe = false;
          for(let s of this.alocacoesResumo){
            if(s.centrodecusto == alocacao.centrodecusto && s.cliente == alocacao.cliente && s.colaborador == alocacao.colaborador){

              if(this._colaborador != "Todos"){
                if(this._colaborador == s.colaborador){
                  s.horas = s.horas + d.horas;
                  existe = true;
    
                  let detalhe = new Alocacao();
    
                  detalhe.centrodecusto = a.centrodecusto;
                  detalhe.cliente = a.cliente;
                  detalhe.colaborador = d.colaborador;
                  detalhe.horas = d.horas;
                  detalhe.data = a.data;
                  
                  s.alocacoesDetalhes.push(detalhe);
                }
              }else{
                s.horas = s.horas + d.horas;
                existe = true;
  
                let detalhe = new Alocacao();
  
                detalhe.centrodecusto = a.centrodecusto;
                detalhe.cliente = a.cliente;
                detalhe.colaborador = d.colaborador;
                detalhe.horas = d.horas;
                detalhe.data = a.data;
                
                s.alocacoesDetalhes.push(detalhe);
              }
              
            }
          }

         
          if(existe == false){

            if(this._colaborador != "Todos"){
              if(this._colaborador == d.colaborador){
                let detalhe = new Alocacao();

                detalhe.centrodecusto = a.centrodecusto;
                detalhe.cliente = a.cliente;
                detalhe.colaborador = d.colaborador;
                detalhe.horas = d.horas;
                detalhe.data = a.data;
                alocacao.alocacoesDetalhes = [];
                alocacao.alocacoesDetalhes.push(detalhe);
                alocacao.collapsed = true;
    
                this.alocacoesResumo.push(alocacao);
              }
            }else{
              let detalhe = new Alocacao();

              detalhe.centrodecusto = a.centrodecusto;
              detalhe.cliente = a.cliente;
              detalhe.colaborador = d.colaborador;
              detalhe.horas = d.horas;
              detalhe.data = a.data;
              alocacao.alocacoesDetalhes = [];
              alocacao.alocacoesDetalhes.push(detalhe);
              alocacao.collapsed = true;
  
              this.alocacoesResumo.push(alocacao);
            }

           
          }
        
        }
      }

      this.alocacoesResumo.sort((a,b) => (a.nomeColaborador > b.nomeColaborador) ? 1 : ((b.nomeColaborador > a.nomeColaborador) ? -1 : 0));

      for(let a of this.alocacoesResumo){
        a.alocacoesDetalhes.sort((a,b) => (a.data.seconds*1000 > b.data.seconds*1000) ? 1 : ((b.data.seconds*1000 > a.data.seconds*1000) ? -1 : 0));
      }
    }

    this.totalHoras = 0;
    for(let r of this.alocacoesResumo){
        this.totalHoras = this.totalHoras + r.horas;
    }

    this.spinner.hide();
  }

  mostrarDetalhes(){
    for(let a of this.alocacoesResumo){
      a.collapsed = false;
    }

    this.isCollapsed = !this.isCollapsed;
  }

  ocultarDetalhes(){
    for(let a of this.alocacoesResumo){
      a.collapsed = true;
    }

    this.isCollapsed = !this.isCollapsed;
  }

  collapsed(event: any): void {
    // console.log(event);
  }

  expanded(event: any): void {
    // console.log(event);
  }

  async getColaboradores(){
 
    this.colaboradores = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    } 

    if (!this.movSub || this.movSub.closed){

     
      this.movSub = this.crudService.listar("colaborador", null, null).subscribe(
        (result: any) =>{

          this.colaboradores =  result;
          this.colaboradores.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));

          this.checkUsuarioLogado();
          this.getClientes();
         
       },error=>{
            console.log(error);
       });

    }
    

  }

  async getClientes(){
 
    this.clientes = [];

    if (this.movSubCli && this.movSubCli.closed == false){
      this.movSubCli.unsubscribe();
    }

    if (!this.movSubCli || this.movSubCli.closed){
     
      this.movSubCli = this.crudService.listar("cliente", null, null).subscribe(
        (result: any) =>{

          this.clientes =  result;

          this.getCentrodecustosAll();
       },error=>{
            console.log(error);
       });

    }
    

  }

  checkUsuarioLogado(){

    let user = JSON.parse(localStorage.getItem('userNextCapacity'));

    for(var i = 0; i < this.colaboradores.length; i++){
      if(this.colaboradores[i].email == user.email){

        this.usuarioLogado = {
          gestor : this.colaboradores[i].gestor,
          mediador : this.colaboradores[i].mediador,
          cadastrador : this.colaboradores[i].cadastrador,
          colaboradorID : this.colaboradores[i].id,
          email : this.colaboradores[i].email,
          nome: this.colaboradores[i].nome,
          gestorDireto: this.colaboradores[i].gestorDireto,
          financeiro: this.colaboradores[i].financeiro,
          tecnico: this.colaboradores[i].tecnico,
          comercial: this.colaboradores[i].comercial,
          rh: this.colaboradores[i].rh
        }
        
        if(this.usuarioLogado && !this.usuarioLogado.gestor && !this.usuarioLogado.mediador && !this.usuarioLogado.rh){
          this._colaborador = this.usuarioLogado.colaboradorID;
        }
      }
    }
      
  }
  
  async getCentrodecustos(){
 
    this.centrodecustos = [];

    if (this.movSubCentro && this.movSubCentro.closed == false){
      this.movSubCentro.unsubscribe();
    }

    if (!this.movSubCentro || this.movSubCentro.closed){
     
      this.movSubCentro = this.crudService.listar("centrodecusto", null, null).subscribe(
        (result: any) =>{

          this.setCentroCustoByCliente(result);
   
          this.getAlocacoes();
       },error=>{
            console.log(error);
       });

    }
    

  }

  async getCentrodecustosAll(){
 
    this.centrodecustosAll = [];

    if (this.subCentrodecustoAll && this.subCentrodecustoAll.closed == false){
      this.subCentrodecustoAll.unsubscribe();
    }

    if (!this.subCentrodecustoAll || this.subCentrodecustoAll.closed){
     
      this.subCentrodecustoAll = this.crudService.listar("centrodecusto", "ativo", true).subscribe(
        (result: any) =>{
          this.centrodecustosAll = result;
          
          this.getCentrodecustos();
          
       },error=>{
            console.log(error);
       });

    }
    
  }
  
  setCentroCustoByCliente(centrodecustos: any){

    let _centrodecusto: any = [];
    for(var i = 0; i < centrodecustos.length; i++){

      if(centrodecustos[i].cliente == this._eventoCapacity.cliente){
        _centrodecusto.push(centrodecustos[i]);
      }
    }

    _centrodecusto.sort((a,b) => (a.descricaoMin > b.descricaoMin) ? 1 : ((b.descricaoMin > a.descricaoMin) ? -1 : 0));

    if(_centrodecusto && _centrodecusto.length > 0){
      this.centrodecustos = _centrodecusto;
    }else{
      this.centrodecustos = this.centrodecustosAll;
    }
    
  }
  
  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (this.movSubAlo && this.movSubAlo.closed == false){
      this.movSubAlo.unsubscribe();
    }

    if (this.movSubCli && this.movSubCli.closed == false){
      this.movSubCli.unsubscribe();
    }

    if (this.movSubCentro && this.movSubCentro.closed == false){
      this.movSubCentro.unsubscribe();
    }
  }


}
