import { Directive, Input , ElementRef, Renderer2, OnInit, RendererStyleFlags2} from '@angular/core';

@Directive({
  selector: '[appDespesas]'
})
export class DespesasDirective implements OnInit{

  @Input() codigo: string;
  @Input() grupo: string;
  @Input() despesas: any[];

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){
      var tot = 0;
      var textOld = ' ';
    for (var i = 0; i < this.despesas.length; i++){

      console.log(this.despesas[i].Codigo.trim(), ' # ', this.codigo.trim())
        
        if(this.despesas[i].Codigo.trim() == this.codigo.trim()){

          if(this.despesas[i].Grupo == 'GP' && this.grupo == 'GP'){
            const text = this.render.createText(parseFloat(this.despesas[i].Valor).toFixed(2).toString() );
            this.render.appendChild(this.elRef.nativeElement, text);
          }

          if(this.despesas[i].Grupo == 'DESENVOLVEDOR' && this.grupo == 'DEV'){
            const text = this.render.createText(parseFloat(this.despesas[i].Valor).toFixed(2).toString() );
            this.render.appendChild(this.elRef.nativeElement, text);
          }

          if(this.despesas[i].Grupo == 'FUNCIONAL' && this.grupo == 'FUN'){
            const text = this.render.createText(parseFloat(this.despesas[i].Valor).toFixed(2).toString() );
            this.render.appendChild(this.elRef.nativeElement, text);
          }

          if(this.grupo == 'TOT'){
            this.render.removeChild(this.elRef.nativeElement, tot.toString());
            if(i>0){
              if(this.despesas[i].Codigo == this.despesas[i-1].Codigo) {
                tot = tot + parseFloat(this.despesas[i].Valor);
              }
              else{
                tot = parseFloat(this.despesas[i].Valor);
              }
            }
            else{
              tot = parseFloat(this.despesas[i].Valor);
            }

            this.render.removeChild(this.elRef.nativeElement, textOld);
            //const text = this.render.createText(tot.toString());
            const text = this.render.createText(tot.toFixed(2).toString() );

            this.render.appendChild(this.elRef.nativeElement, text);
            textOld = text;

          }



        }
      }
  }
}

