import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Vertical } from '../../../model/vertical';
import { NgForm } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';
 
@Component({
  selector: 'app-detalhes-vertical',
  templateUrl: 'vertical-detalhes.component.html'
})   
export class VerticalDetalhesComponent implements OnInit{
      
  @ViewChild('modalvertical', {static: false}) public modalvertical: ModalDirective;
 
  sub: boolean = false;
  _vertical: Vertical = new Vertical();
  @Output() loadvertical = new EventEmitter();
  titulo: string = "Nova vertical";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CrudService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesVertical.subscribe(
        (p: Vertical) => {
         
          if(p == null){
            this.titulo = "Nova Vertical"
            this._vertical = new Vertical();
          }else{
            this.titulo = "Editar Vertical"
            this._vertical = p;
          }

          this.limpaModal();

          this.modalvertical.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  hideModal(){
    this.modalvertical.hide();
    this.loadvertical.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this._vertical.id){
      this.atualizar();
    }else{
      this.salvar();
    }
      
    form.reset();
  }

  salvar(){

    let objVertical = JSON.parse(JSON.stringify(this._vertical));

    if (this.movSubSalvar && this.movSubSalvar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed){
     
      this.movSubSalvar = this.cadastroService.findByGeneric("vertical", "codigo", this._vertical.codigo).subscribe(
        (result: any) =>{

          if(result.length > 0){
            this.toastr.error('Este código já esta sendo utilizado por outra vertical!', 'Atenção!');
            this.hideModal();
          }else{
            this.cadastroService.salvar("vertical", objVertical);

            this.toastr.success('Vertical cadastrada com sucesso!', 'Sucesso!');
         
            this.hideModal();

            this.movSubSalvar.unsubscribe();
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }
 
 
   
  } 

  atualizar(){

    let objVertical = JSON.parse(JSON.stringify(this._vertical));
    
    if (this.movSubAlterar && this.movSubAlterar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubAlterar || this.movSubAlterar.closed){
     
      this.movSubAlterar = this.cadastroService.findByGeneric("vertical", "codigo", this._vertical.codigo).subscribe(
        (result: any) =>{

          if(result.length > 0){
            if(result[0].codigo == objVertical.codigo){
              this.cadastroService.atualizar("vertical", objVertical);

              this.toastr.success('Vertical atualizada com sucesso!', 'Sucesso!');
           
              this.hideModal();
            
              this.movSubAlterar.unsubscribe();
            }
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }
    
  }
}
