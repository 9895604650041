import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CrudService } from "../../services/crudService";
import { ModalService } from '../../services/modal.service';
import { Risco } from '../../model/risco';
import { ConfirmationDialogService } from '../../components/confirmation-dialog/confirmation-dialog.service';
 
@Component({
  selector: 'app-riscos',
  templateUrl: './riscos.component.html',
  styleUrls: ['./riscos.component.scss']
})  
export class RiscosComponent implements OnInit {
      
  movSub: Subscription;
  riscos: any[];

  constructor(
      private spinner: NgxSpinnerService,
      private CrudService: CrudService,
      private modalService: ModalService,
      private cadastroService: CrudService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService
  ){
    
  }

  ngOnInit() {
    this.getriscos();
  }

  async getriscos(){
 
    this.riscos = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();
     
      this.movSub = this.CrudService.listar("riscos", null, null).subscribe(
        (result: any) =>{

          this.riscos =  result;
          console.log(result);

          this.spinner.hide();
  
       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }
    

  }


  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

  novoriscos(){
    this.modalService.modalDetalhesRiscos.emit(null);
  }

  editar(risco: Risco){
    this.modalService.modalDetalhesRiscos.emit(risco);
  }
 
  excluir(risco: Risco){
    
    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          this.cadastroService.excluir("riscos", risco.id);
            
          this.toastr.success('Risco excluído com sucesso!', 'Sucesso!');
          this.getriscos();               
           
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    
  }


}
