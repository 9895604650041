

import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { MCDigital } from '../../../model/mcdigital';
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';
import { Semana } from '../../../model/semana';
import { RecursoMC } from '../../../model/recursomc';
import { ConfirmationDialogService } from '../../../components/confirmation-dialog/confirmation-dialog.service';
import { Notificacao } from '../../../model/notificacao';
import { NgxSpinnerService } from 'ngx-spinner';





@Component({
  selector: 'app-mcdigital-desmembramento',
  templateUrl: './mcdigital-desmembramento.component.html',
  styleUrls: ['./mcdigital-desmembramento.component.scss']
})
export class McdigitalDesmembramentoComponent implements OnInit {

  @ViewChild('modalmcdigitaldesmemb', {static: false}) public modalmcdigitaldesmemb: ModalDirective;
  sub: boolean = false;
  user: any;
  titulo: string ;
  _mcdigital: MCDigital = new MCDigital();
  custo : number = 0;
  venda :  number = 0;
  imposto :  number = 0;
  vendaLiquida :  number = 0;
  overhead :  number = 0;
  vendaLiquidaSemOver :  number = 0;
  lucroLiquido :  number = 0;
  comissao :  number = 0;
  vendaLiquidaSemOverSemComis:  number = 0;
  percLucro:  string;


  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CrudService,
    private confirmationDialogService: ConfirmationDialogService) {
      this.user = JSON.parse(localStorage.getItem('userNextCapacity'));
  }

  ngOnInit() {

    this.user = JSON.parse(localStorage.getItem('userNextCapacity'));

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDesmembramento.subscribe(
        (p: MCDigital) => {

          if(p != null){
            this.titulo = "Detalhes do Preço de Venda"
            this._mcdigital = p;

            //this.changePeriodo();

            console.log(" this._mcdigital ###############", this._mcdigital)
            this.calculos();

          }else{

          }
          this.modalmcdigitaldesmemb.show();

        }, error => {
          console.log(error.message);
        }
      );


    }

  }

  calculos(){
    this.custo =  this._mcdigital.custoprojeto;
    this.venda = this._mcdigital.valorvenda;
    this.imposto = this._mcdigital.impostoEmpresa *  this.venda / 100;
    this.vendaLiquida = this.venda - this.imposto - this._mcdigital.incrementoFinanceiro //this.venda - this.imposto;
    //this.overhead = this.vendaLiquida * this._mcdigital.overhead / 100;
    this.overhead = (this._mcdigital.valorvenda + this._mcdigital.desconto - this._mcdigital.incrementoFinanceiro) * this._mcdigital.overhead / 100;
    this.vendaLiquidaSemOver = this.vendaLiquida -  this.overhead;
    this.comissao = this.vendaLiquida  *  this._mcdigital.comissao/100  // Math.round()
    this.vendaLiquidaSemOverSemComis =  this.vendaLiquidaSemOver - this.comissao
    this.lucroLiquido = this.vendaLiquidaSemOverSemComis -  this.custo;
    //this.percLucro = (this.lucroLiquido / this.vendaLiquidaSemOverSemComis * 100).toFixed(2) +'%'
    this.percLucro = (this.lucroLiquido / this.venda * 100).toFixed(2) +'%'


    //Informação Marcelo 07/01/21
    //Venda líquida = venda bruta - impostos - desconto - incremento financeiro
    //Comissão = venda líquida * percentual comissão (parâmetro)

  }

  hideModal(){
    this.modalmcdigitaldesmemb.hide();
    //this.loadmcdigital.emit();
  }

}

